import React, { useState } from 'react'
import AuthService from '../../../../services/auth'
import cutileiApi from '../../../../services/cutileiApi'
import AlertDialog from '../../../AlertDialog'
import Logo from '../../../../icons/logo-cutilei-simple.png'
import NumberInput from '../../../Inputs/NumberInput'
import MoneyInput from '../../../Inputs/MoneyInput'
import Select from '../../../Inputs/Select'
import billServiceValidator from '../../../../validators/billServiceValidator'
import { ReactComponent as ButtonLoading } from '../../../../icons/loading2.svg'
import ReactTooltip from 'react-tooltip'
import { Formik } from 'formik'
import * as FA from 'react-icons/fa'
import { TbFreeRights } from 'react-icons/tb'

import {
  Container,
  FormField,
  Image,
  Row,
  Input,
  Label,
  InfoText,
  MoneyTextMask,
  Button
} from './styles'

function BillService ({
  bill,
  data: service,
  professionals,
  isFree = false,
  showFreeItemsValue = false,
  enableEditting = true,
  onEdit: handleEdit,
  onDelete: handleDelete
}) {
  const [editting, setEditting] = useState (false)
  const [showAlertDialog, setShowAlertDialog] = useState (false)
  const [errorMessage, setErrorMessage] = useState (null)
  const [deleting, setDeleting] = useState (false)
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const deleteService = async () => {
    setDeleting (true)
    try {
      await cutileiApi.delete (`/business_bills/${bill.id}/services/${service.id}`, requestConfig)
      setDeleting (false)
      handleDelete (service)
    } catch (error) {
      setDeleting (false)
      console.log (error)
    }
  }

  const toggleEditting = () => setEditting (!editting)
  const toggleAlertDialog = () => setShowAlertDialog (!showAlertDialog)

  return editting ? (
    <Formik
      validationSchema={billServiceValidator}
      initialValues={{
        'service': service,
        'professional': service.professional ? {
          label: service.professional.nickname || service.professional.name.split (' ')[0],
          value: service.professional.id
        } : null,
        'amount': service.amount,
        'custom_price': service.custom_price,
        'custom_comission_percentage': service.custom_comission_percentage * 100
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const serviceData = {
          professional_id: values.professional?.value || null,
          amount: values.amount,
          custom_price: values.custom_price,
          custom_comission_percentage: values.custom_comission_percentage / 100
        }

        try {
          const { data: editedService } = await cutileiApi.put (
            `/business_bills/${bill.id}/services/${service.id}`, serviceData, requestConfig
          )

          setSubmitting (false)
          setEditting (false)
          handleEdit (editedService)
        } catch (error) {
          if (error.response.data) setErrorMessage (error.response.data.message)
          setSubmitting (false)
          console.log (error)
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        handleBlur, 
        handleSubmit
      }) => (
        <Container editting={editting} enableEditting={enableEditting}>
          <FormField>
            <Input
              name='service'
              placeholder='Serviço...'
              value={values.service.name}
              style={{width: '100%'}}
              disabled
            />
          </FormField>
          <FormField>
            <Select
              name='professional'
              placeholder='Profissional...'
              value={values.professional}
              options={professionals.map (professional => ({
                label: professional.nickname || professional.name.split (' ')[0],
                value: professional.id
              }))}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              error={errors.professional}
              touched={touched.professional}
              containerStyles={{width: '100%'}}
            />
          </FormField>
          <FormField>
            <NumberInput
              id='amount'
              name='amount'
              value={values.amount}
              minValue={1}
              maxValue={99}
              onChange={setFieldValue}
              style={{width: '100%'}}
            />
          </FormField>
          <FormField>
            <MoneyInput
              name='custom_price'
              placeholder='Preço'
              value={values.custom_price}
              onValueChange={setFieldValue}
              onBlur={handleBlur ('custom_price')}
              style={{width: '100%'}}
            />
          </FormField>
          <FormField>
            <NumberInput
              id='custom_comission_percentage'
              name='custom_comission_percentage'
              value={values.custom_comission_percentage}
              minValue={0}
              maxValue={100}
              onChange={setFieldValue}
              style={{width: '100%'}}
            />
          </FormField>
          <Row>
            <Button type='button' onClick={handleSubmit} disabled={isSubmitting}>
              {isSubmitting ? (
                <ButtonLoading style={{margin: 0}}/>
              ) : (
                <FA.FaSave color='#35A7FF' size={16}/>
              )}
            </Button>
            <Button type='button' onClick={toggleEditting}>
              <FA.FaTimes color='#FF3939' size={18}/>
            </Button>
          </Row>
        </Container>
      )}
    </Formik>
  ) : (
    <Container editting={editting} enableEditting={enableEditting}>
      <Row>
        {service.cutilei_service && <Image src={Logo} alt='Service Cutilei'/>}
        <InfoText>
          {service.nickname || service.name}
        </InfoText>
      </Row>
      <InfoText>
        {service.professional
          ? service.professional.nickname || service.professional.name.split (' ')[0]
          : '-'
        }
      </InfoText>
      <InfoText>
        {service.amount}
      </InfoText>
      <Row data-for={isFree ? service.id : null} data-tip>
        <MoneyTextMask
          value={(
            isFree ? showFreeItemsValue ? service.discount_price : 0 : service.custom_price
          ) ?? 0}
          style={{justifySelf: 'center'}}
        />
        {isFree && <TbFreeRights size={24} color='#FFC100'/>}
        <ReactTooltip
          id={service.id}
          effect='float'
          place='top'
          backgroundColor='#252525'
          tooltipRadius='10'
        >
          <Label style={{color: '#FFFFFF'}}>
            O cliente possui este serviço em pacotes adquiridos, portante ele não será cobrado
          </Label>
        </ReactTooltip>
      </Row>
      <InfoText>
        {service.custom_comission_percentage * 100}%
      </InfoText>
      {!service.cutilei_service && enableEditting && (
        <Row>
          <Button onClick={toggleEditting}>
            <FA.FaEdit color='#35A7FF' size={18}/>
          </Button>
          <Button onClick={toggleAlertDialog}>
            {deleting ? (
              <ButtonLoading style={{margin: 0}}/>
            ) : (
              <FA.FaTrash color='#FF3939' size={16}/>
            )}
          </Button>
        </Row>
      )}
      <AlertDialog
        visible={showAlertDialog}
        title='Atenção!'
        message='Deseja realmente remover este serviço da comanda?'
        confirmText='Sim'
        cancelText='Não'
        onConfirm={deleteService}
        onClose={toggleAlertDialog}
        dangerous
      />
    </Container>
  )
}

export default BillService
