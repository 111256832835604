class Phone {
  static formatPhone (phone) {
    const DDD = phone.length > 10 ? phone.slice (0, 2) : ''
    const firstSequence = phone.length === 11 ? phone.slice (2, 7) : phone.slice (2, 6)
    const lastSequence = phone.slice (-4)

    return `(${DDD}) ${firstSequence}-${lastSequence}`
  }
}

export default Phone