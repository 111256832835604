import React, { useState } from 'react'
import cutileiApi from '../../../services/cutileiApi'
import { Formik } from 'formik'
import verificationCodeValidator from '../../../validators/verificationCodeValidator'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'

import {
  Form,
  FormField,
  InfoText,
  MaskedInput,
  Button,
  WarningText,
  ErrorContainer
} from '../styles'

function ValidateCodeForm ({
  onSubmit: handleSubmitForm
}) {
  const [errorMessage, setErrorMessage] = useState (null)

  return (
    <Formik
      validationSchema={verificationCodeValidator}
      initialValues={{
        'verification_code': ''
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const { verification_code } = values

        try {
          setSubmitting (true)

          const { data } = await cutileiApi.get (
            `/verify_account/businesses?code=${verification_code}`
          )

          setSubmitting (false)
          handleSubmitForm (data)
        } catch (error) {
          setSubmitting (false)
          setErrorMessage (error.response?.data.message)
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <Form onSubmit={handleSubmit}>
          <InfoText>
            Insira o código de 6 dígitos enviado para seu e-mail para prosseguir
          </InfoText>
          <FormField>
            <MaskedInput
              name='verification_code'
              placeholder='Código de 6 dígitos'
              options={{blocks: [6], numericOnly: true}}
              value={values.verification_code}
              onChange={handleChange ('verification_code')}
              onBlur={handleBlur ('verification_code')}
            />
            {touched.verification_code && errors.verification_code &&
              <WarningText>
                {errors.verification_code}
              </WarningText>
            }
          </FormField>
          {errorMessage && (
            <ErrorContainer>
              <p>{errorMessage}</p>
            </ErrorContainer>
          )}
          <Button type='submit' disabled={isSubmitting}>
            {isSubmitting ? <ButtonLoading/> : 'Validar código'}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default ValidateCodeForm
