import React from 'react'
import { useDroppable } from '@dnd-kit/core'
import { Container, InfoText } from './styles'

export default function TimeBlock ({
  id,
  professional,
  numColumns,
  positionx,
  positiony,
  label,
  onClick: handleClick
}) {
  const {isOver, setNodeRef} = useDroppable ({
    id,
    data: {
      time: label,
      professional
    }
  })

  return (
    <Container
      ref={setNodeRef}
      isDraggingOver={isOver}
      numColumns={numColumns}
      positionx={positionx}
      positiony={positiony}
      onClick={handleClick}
    >
      <InfoText>
        {label}
      </InfoText>
    </Container>
  )
}