import React, { useState } from 'react'
import Professional from './Professional'

import {
  Container
} from './styles'

function ActiveProfessionals (props) {
  const [professionals, setProfessionals] = useState (props.professionals)

  const deleteProfessional = professional => {
    setProfessionals (professionals.filter (p => p.id !== professional.id))
  }

  const reactivateProfessional = professional => {
    setProfessionals ([
      ...professionals.filter (p => p.id !== professional.id),
      professional
    ])
  }

  return (
    <Container>
      {professionals.map (professional =>
        <Professional
          key={professional.id}
          data={professional}
          onDelete={deleteProfessional}
          onReactivate={reactivateProfessional}
          onRestore={deleteProfessional}
        />
      )}
    </Container>
  )
}

export default ActiveProfessionals
