import * as Yup from 'yup'

const emailValidator = Yup.object ({
  email: Yup
    .string ()
    .required ('Digite um email')
    .email ('Insira um email válido!')
})

export default emailValidator
