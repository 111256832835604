import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import cutileiApi from '../../../services/cutileiApi'
import AuthService from '../../../services/auth'
import NotifyAbsenceModal from '../../../pages/Modals/NotifyAbsenceModal'
import RemoveProfessionalDialog from '../../../pages/Modals/RemoveProfessionalModal'
import { DateTime } from 'luxon'

import {
  Container,
  Option,
  Image,
  SmallText
} from './styles'

function Professional ({
  data: professional,
  history,
  onRemove: handleRemove
}) {
  const [loading, setLoading] = useState (false)
  const [showNotifyAbsenceDialog, setShowNotifyAbsenceDialog] = useState (false)
  const [showRemoveDialog, setShowRemoveDialog] = useState (false)
  const user = AuthService.getUser ()
  const token = AuthService.getToken ()
  const isBusiness = AuthService.isBusiness ()
  const firstName = professional.name.split(' ')[0]

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const goToEditPage = () => history.push (
    isBusiness
      ? `/business/professionals/${professional.id}/edit`
      : professional.id === user.id
        ? `/business/professionals/${user.id}/edit`
        : '#'
  )

  const notifyAbsence = async (
    startDate = DateTime.now ().toISODate (),
    endDate = DateTime.now ().toISODate (),
    startTime = '00:00',
    endTime = '23:59'
  ) => {
    setLoading (true)
    const filters = `?start_date=${startDate}&end_date=${endDate}`
                  + `&start_time=${startTime}&end_time=${endTime}`

    try {
      await cutileiApi.get (
        `/professionals/${professional.id}/notify_absence${filters}`,
        requestConfig
      )
      setLoading (false)
      toggleNotifyAbsenceDialog ()
    } catch (error) {
      setLoading (false)
      console.log (error)
    }
  }

  const toggleNotifyAbsenceDialog = () => setShowNotifyAbsenceDialog (!showNotifyAbsenceDialog)
  const toggleRemoveDialog = () => setShowRemoveDialog (!showRemoveDialog)

  const professionalOptions = [
    {name: 'Editar profissional', onClick: goToEditPage},
    {name: 'Notificar ausência', onClick: toggleNotifyAbsenceDialog},
    {name: 'Remover da agenda', onClick: toggleRemoveDialog}
  ]

  return (
    <Container>
      <Image src={professional.profile_picture}/>
      <SmallText>
        { professional.nickname ? professional.nickname : firstName }
      </SmallText>
      <div>
        {professionalOptions.map ((option, index) => (
          <Option key={index} onClick={option.onClick}>
            {option.name}
          </Option>
        ))}
      </div>
      <NotifyAbsenceModal
        visible={showNotifyAbsenceDialog}
        title={`Notificar a ausência de ${firstName}?`}
        professional={professional}
        isSubmitting={loading}
        onConfirm={data => {
          const { startDate, endDate, startTime, endTime } = data
          notifyAbsence (startDate, endDate, startTime, endTime)
        }}
        onClose={toggleNotifyAbsenceDialog}
      />
      <RemoveProfessionalDialog
        visible={showRemoveDialog}
        title={`Deseja remover ${firstName} da agenda?`}
        professional={professional}
        onConfirm={handleRemove}
        onClose={toggleRemoveDialog}
        notifyAbsence={notifyAbsence}
      />
    </Container>
  )
}

export default withRouter (Professional)
