import React, { useState } from 'react'
import cutileiApi from '../../../../services/cutileiApi'
import AuthService from '../../../../services/auth'
import download from 'downloadjs'
import { DateTime } from 'luxon'
import AlertDialog from '../../../AlertDialog'
import * as FA from 'react-icons/fa'
import { ReactComponent as ButtonLoading } from '../../../../icons/loading2.svg'

import {
  Container,
  InfoText,
  Row,
  Button
} from './styles'

function Attachment ({
  data: attachment,
  onDelete: handleDelete
}) {
  const [showAlertDialog, setShowAlertDialog] = useState (false)
  const [downloading, setDownloading] = useState (false)
  const [deleting, setDeleting] = useState (false)
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const handleDownload = async () => {
    setDownloading (true)

    try {
      const { data } = await cutileiApi.get (
        `/attachments/${attachment.id}`,
        {responseType: 'blob', ...requestConfig}
      )
  
      setDownloading (false)
      download (data, attachment.file_name)
    } catch (error) {
      setDownloading (false)
      console.log (error)
    }
  }

  const deleteAttachment = async () => {
    setDeleting (true)
    try {
      await cutileiApi.delete (`/attachments/${attachment.id}`, requestConfig)
      setDeleting (false)
      handleDelete (attachment)
    } catch (error) {
      setDeleting (false)
      console.log (error)
    }
  }

  const toggleAlertDialog = () => setShowAlertDialog (!showAlertDialog)

  return (
    <Container>
      <InfoText style={{justifySelf: 'flex-start'}}>
        {DateTime.fromISO (attachment.created_at).toFormat ('dd/MM/yyyy - HH:mm:ss')}
      </InfoText>
      <InfoText>
        {attachment.file_name}
      </InfoText>
      <Row style={{justifySelf: 'flex-end'}}>
        <Button onClick={handleDownload}>
          {downloading ? (
            <ButtonLoading style={{ margin: 0 }}/>
          ) : (
            <FA.FaDownload color='#35A7FF' size={16}/>
          )}
        </Button>
        <Button onClick={toggleAlertDialog}>
          {deleting ? (
            <ButtonLoading style={{margin: 0}}/>
          ) : (
            <FA.FaTrash color='#FF3939' size={16}/>
          )}
        </Button>
      </Row>
      <AlertDialog
        visible={showAlertDialog}
        title='Atenção!'
        message='Deseja realmente excluir este anexo?'
        confirmText='Sim'
        cancelText='Não'
        onConfirm={deleteAttachment}
        onClose={toggleAlertDialog}
        dangerous
      />
    </Container>
  )
}

export default Attachment
