import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import PaymentForm from '../../../components/Forms/PaymentForm'
import CheckBox from '../../../components/CheckBox'
import AlertDialog from '../../../components/AlertDialog'
import BillScheduleList from '../../../components/Lists/BillScheduleList'
import BillServiceList from '../../../components/Lists/BillServiceList'
import BillProductList from '../../../components/Lists/BillProductList'
import BillBundleList from '../../../components/Lists/BillBundleList'
import BillTipList from '../../../components/Lists/BillTipList'
import BillServiceForm from '../../../components/Forms/BillServiceForm'
import BillProductForm from '../../../components/Forms/BillProductForm'
import BillBundleForm from '../../../components/Forms/BillBundleForm'
import BillTipForm from '../../../components/Forms/BillTipForm'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import paymentValidator from '../../../validators/paymentValidator'
import { TbFreeRights } from 'react-icons/tb'
import * as FA from 'react-icons/fa'
import { Formik } from 'formik'
import { DateTime } from 'luxon'

import {
  modalStyle,
  Form,
  FormField,
  Button,
  AltButton,
  SmallButton,
  DangerButton,
  CloseButton,
  TabButton,
  InfoContainer,
  Info,
  Row,
  Label,
  SubTitle,
  InfoText,
  MoneyTextMask,
  ErrorContainer
} from './styles'

function EditBillModal ({
  visible,
  data: bill,
  showFreeItemsValue = false,
  onDeleteSchedule: handleDeleteSchedule = () => {},
  onEditScheduleStatus: handleEditScheduleStatus = () => {},
  onConfirmEdit: handleConfirmEdit = () => {},
  onCloseBill: handleCloseBill = () => {},
  onClose: handleClose
}) {
  const [option, setOption] = useState ('items')
  const [loading, setLoading] = useState (true)
  const [schedules, setSchedules] = useState ([])
  const [activeSchedules, setActiveSchedules] = useState ([])
  const [payments, setPayments] = useState ([])
  const [paymentMethods, setPaymentMethods] = useState ([])
  const [bankAccounts, setBankAccounts] = useState ([])
  const [cardFlags, setCardFlags] = useState ([])
  const [services, setServices] = useState ([])
  const [products, setProducts] = useState ([])
  const [bundles, setBundles] = useState ([])
  const [professionals, setProfessionals] = useState ([])
  const [customerServices, setCustomerServices] = useState ([])
  const [totalBalance, setTotalBalance] = useState (0)
  const [billServices, setBillServices] = useState ([])
  const [billProducts, setBillProducts] = useState ([])
  const [billBundles, setBillBundles] = useState ([])
  const [billTips, setBillTips] = useState ([])
  const [billTotal, setBillTotal] = useState (0)
  const [totalWithDiscount, setTotalWithDiscount] = useState (0)
  const [useCredits, setUseCredits] = useState (false)
  const [showServiceForm, setShowServiceForm] = useState (false)
  const [showProductForm, setShowProductForm] = useState (false)
  const [showBundleForm, setShowBundleForm] = useState (false)
  const [showTipForm, setShowTipForm] = useState (false)
  const [showAlertDialog, setShowAlertDialog] = useState (false)
  const [errorMessage, setErrorMessage] = useState (null)
  const businessId = AuthService.getBusinessId ()
  const token = AuthService.getToken ()
  const user = AuthService.getUser ()

  const TAB_LIST = [
    {label: 'Itens', value: 'items'},
    {label: 'Pagamento', value: 'payment'}
  ]

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    setOption ('items')
  }, [visible])

  useEffect (() => {
    getData ()
  }, [bill])

  useEffect (() => {
    calculateBillValue ()
  }, [activeSchedules, billServices, billProducts, billBundles, billTips, customerServices])

  const getData = async () => {
    setLoading (true)
    try {
      const { data: schedules } = await cutileiApi.get (
        `/schedules?business_id=${businessId}&bill_id=${bill.id}&page=1&per_page=999&cutilei_schedules=false`, requestConfig
      )
      const { data: billServices } = await cutileiApi.get (`/business_bills/${bill.id}/services`, requestConfig)
      const { data: billProducts } = await cutileiApi.get (`/business_bills/${bill.id}/products`, requestConfig)
      const { data: billBundles } = await cutileiApi.get (`/business_bills/${bill.id}/bundles`, requestConfig)
      const { data: billTips } = await cutileiApi.get (`/tips?bill_id=${bill.id}`, requestConfig)
      const { data: payments } = await cutileiApi.get (`/payments?bill_id=${bill.id}`, requestConfig)
      const { data: credits } = await cutileiApi.get (`/credits?bill_id=${bill.id}`, requestConfig)
      const { data: debts } = await cutileiApi.get (`/debts?bill_id=${bill.id}`, requestConfig)
      const { data: paymentMethods } = await cutileiApi.get ('/payment_methods', requestConfig)
      const { data: bankAccounts } = await cutileiApi.get (`/businesses/${businessId}/bank_accounts`, requestConfig)
      const { data: cardFlags } = await cutileiApi.get ('/card_flags', requestConfig)
      const { data: services } = await cutileiApi.get (`/businesses/${businessId}/services?cutilei_services=false`, requestConfig)
      const { data: products } = await cutileiApi.get (`/businesses/${businessId}/products`, requestConfig)
      const { data: bundles } = await cutileiApi.get (`/businesses/${businessId}/bundles`, requestConfig)
      const { data: professionals } = await cutileiApi.get (
        `/businesses/${businessId}/professionals?status=active&access_level=service`, requestConfig
      )
      const { data: customerServices } = await cutileiApi.get (
        `/customers/${bill.customer.id}/services?business_id=${businessId}&available=true`, requestConfig
      )

      const creditTotal = credits.reduce ((total, credit) => total + credit.value, 0)
      const debtTotal = debts.reduce ((total, debt) => total + debt.value, 0)
      const totalBalance = bill.customer.balance - creditTotal + debtTotal
      
      setSchedules (schedules.data)
      setActiveSchedules (schedules.data.filter (s => s.bill_active))
      setBillServices (billServices)
      setBillProducts (billProducts)
      setBillBundles (billBundles)
      setBillTips (billTips)
      setPayments (payments)
      setPaymentMethods (paymentMethods)
      setBankAccounts (bankAccounts)
      setCardFlags (cardFlags)
      setServices (services)
      setProducts (products)
      setBundles (bundles)
      setProfessionals (professionals)
      setCustomerServices (customerServices)    
      setTotalBalance (totalBalance)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  const handleAddPayment = (payments, setFieldValue) => {
    setFieldValue ('payments', [
      ...payments,
      {
        value: '',
        paymentMethod: null,
        cardFlag: null,
        installments: null,
        installmentValue: null
      }
    ])
  }

  const handleDeletePayment = (payments, index, setFieldValue) => {
    setFieldValue (
      'payments',
      payments.map ((p, i) => ({...p, idx: i})).filter (p => p.idx !== index)
    )
  }

  const handleUseCredits = value => {
    setUseCredits (value)
    setTotalWithDiscount (value === true ? (bill.value - totalBalance) : bill.value)
  }

  const hasItems = (
    activeSchedules.length > 0 || billServices.length > 0
    || billProducts.length > 0 || billBundles.length > 0 || billTips.length > 0
  )

  const requirePayment = totalWithDiscount !== 0 || !hasItems

  const hasFreeServices = () => {
    const customerServiceIDs = customerServices.map (s => s.id)
    const bundlesServiceIDs = billBundles.reduce ((acc, bundle) => {
      return acc.concat (bundle.services.map (s => s.id))
    }, [])

    return (
      activeSchedules.some (schedule => customerServiceIDs.includes (schedule.service.id))
      || billServices.some (service => (
        customerServiceIDs.includes (service.id) || bundlesServiceIDs.includes (service.id)
      ))
    )
  }
  
  const updateBillValue = async newValue => {
    if (loading) {
      setBillTotal (bill.value)
      setTotalWithDiscount (useCredits ? bill.value - totalBalance : bill.value)
    } else {
      try {
        const { data: editedBill } = await cutileiApi.put (`/business_bills/${bill.id}`, {
          value: newValue
        }, requestConfig)
        
        setBillTotal (newValue)
        setTotalWithDiscount (useCredits ? newValue - totalBalance : newValue)
        handleConfirmEdit (editedBill)
      } catch (error) {
        console.log (error)
        if (error.response?.data) setErrorMessage (error.response.data.message)
      }
    }
  }
  
  const calculateBillValue = () => {
    const sumValues = items => (
      items.length > 0
        ? items.map (i => i.custom_price ?? i.price ?? i.value).reduce ((tot, val) => tot + val)
        : 0
    )

    const filterFreeServices = items => {
      const customerServiceIDs = customerServices.map (s => s.id)
      const bundlesServiceIDs = billBundles.reduce ((acc, bundle) => {
        return acc.concat (bundle.services.map (s => s.id))
      }, [])

      return items.filter (i => {
        const serviceID = i.service?.id ?? i.id
        return (
          !customerServiceIDs.includes (serviceID) && !bundlesServiceIDs.includes (serviceID)
        )
      })
    }

    const scheduleTotal = sumValues (filterFreeServices (activeSchedules))
    const serviceTotal = sumValues (filterFreeServices (billServices))
    const productTotal = sumValues (billProducts)
    const bundleTotal = sumValues (billBundles)
    const tipTotal = sumValues (billTips)
    const billTotal = scheduleTotal + serviceTotal + productTotal + bundleTotal + tipTotal
    updateBillValue (billTotal)
  }

  const toggleAlertDialog = () => setShowAlertDialog (!showAlertDialog)
  const toggleServiceForm = () => setShowServiceForm (!showServiceForm)
  const toggleProductForm = () => setShowProductForm (!showProductForm)
  const toggleBundleForm = () => setShowBundleForm (!showBundleForm)
  const toggleTipForm = () => setShowTipForm (!showTipForm)

  const ITEM_OPTIONS = [
    {label: 'Serviço', value: 'service', onClick: toggleServiceForm, selected: showServiceForm},
    {label: 'Produto', value: 'product', onClick: toggleProductForm, selected: showProductForm},
    {label: 'Pacote', value: 'bundle', onClick: toggleBundleForm, selected: showBundleForm},
    {label: 'Caixinha', value: 'tip', onClick: toggleTipForm, selected: showTipForm}
  ]

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      <Row style={{margin: 0}}>
        <SubTitle>
          Comanda Nº{bill.number} - {bill.customer.name} -
          Aberta em {DateTime.fromISO (bill.date).toFormat ('dd/MM/yyyy')}
        </SubTitle>
        <CloseButton onClick={handleClose}>
          <FA.FaTimes color='#FF3939' size={18}/>
        </CloseButton>
      </Row>
      <Row>
        {TAB_LIST.map ((o, index) =>
          <TabButton
            key={index}
            selected={option === o.value}
            color={option === o.value ? '#FFC100' : '#FFFFFF'}
            onClick={() => {setOption (o.value)}}
          >
            {o.label}
          </TabButton>
        )}
      </Row>
      {loading ? <Loading/> : (
        <Formik
          validationSchema={requirePayment ? paymentValidator : null}
          initialValues={{
            'payments': payments.length > 0 ? payments.map (payment => ({
              id: payment.id,
              value: payment.value,
              description: payment.description,
              paymentMethod: {
                label: payment.payment_method.name,
                value: payment.payment_method
              },
              bankAccount: payment.bank_account ? {
                label: `(${payment.bank_account.bank.code} ${payment.bank_account.bank.name})`,
                value: payment.bank_account 
              } : null,
              cardFlag: {
                label: payment.card_flag?.name,
                value: payment.card_flag?.id
              },
              installments: payment.installments,
              installmentValue: payment.installmentValue
            })) : [
              {
                'value': 0,
                'description': '',
                'paymentMethod': null,
                'bankAccount': null,
                'cardFlag': null,
                'installments': null,
                'installmentValue': null
              }
            ]
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const { payments } = values

            try {
              const { data: closedBill } = await cutileiApi.post (`/business_bills/${bill.id}/close`, {
                payments, totalWithDiscount
              }, requestConfig)

              setSubmitting (false)
              handleCloseBill (closedBill)
              handleEditScheduleStatus (schedules.map (s => ({
                ...s, status: 'paid', validated: true
              })))
              handleClose ()
            } catch (error) {
              console.log (error)
              if (error.response.data) setErrorMessage (error.response.data.message)
              setSubmitting (false)
            }
          }}
        >
          {function BillForm (formikProps) {
            useEffect (() => {
              if (!requirePayment && !loading)
                formikProps.setFieldValue ('payments', [{
                  'value': 0,
                  'description': '',
                  'paymentMethod': null,
                  'bankAccount': null,
                  'cardFlag': null,
                  'installments': null,
                  'installmentValue': null
                }])
            }, [totalWithDiscount])

            const paymentTotal = formikProps.values.payments.map (p => p.value).reduce ((tot, val) => tot + val)
            const finalValue = paymentTotal - totalWithDiscount
            return (
              <Form>
                {option === 'items' && (
                  <Info>
                    {activeSchedules.length > 0 || billProducts.length > 0
                    || billServices.length > 0 || billBundles.length > 0 ? (
                      <>
                        {activeSchedules.length > 0 && (
                          <>
                            <SubTitle>Agendamentos</SubTitle>
                            <BillScheduleList
                              schedules={activeSchedules}
                              professionals={professionals}
                              services={services}
                              customerServices={customerServices}
                              showFreeItemsValue={showFreeItemsValue}
                              enableEditting={bill.cash_register.open}
                              onEdit={schedule =>
                                setActiveSchedules (activeSchedules.map (s => s.id === schedule.id ? {...schedule} : s))
                              }
                              onDelete={schedule => {
                                setActiveSchedules (activeSchedules.filter (s => s.id !== schedule.id))
                                handleDeleteSchedule (schedule)
                              }}
                              onRemoveFromBill={
                                schedule => setActiveSchedules (activeSchedules.filter (s => s.id !== schedule.id))
                              }
                            />
                          </>
                        )}
                        {billServices.length > 0 && (
                          <>
                            <SubTitle>Serviços adicionais</SubTitle>
                            <BillServiceList
                              bill={bill}
                              billServices={billServices}
                              customerServices={customerServices}
                              showFreeItemsValue={showFreeItemsValue}
                              professionals={professionals}
                              enableEditting={bill.cash_register.open}
                              onEdit={service => {
                                setBillServices (billServices.map (s => s.id === service.id ? {...service} : s))
                              }}
                              onDelete={service => {
                                setBillServices (billServices.filter (s => s.id !== service.id))
                              }}
                            />
                          </>
                        )}
                        {billProducts.length > 0 && (
                          <>
                            <SubTitle>Produtos</SubTitle>
                            <BillProductList
                              bill={bill}
                              billProducts={billProducts}
                              professionals={professionals}
                              enableEditting={bill.cash_register.open}
                              onEdit={product => {
                                setBillProducts (billProducts.map (p => p.id === product.id ? {...product} : p))
                              }}
                              onDelete={product => {
                                setBillProducts (billProducts.filter (p => p.id !== product.id))
                              }}
                            />
                          </>
                        )}
                        {billBundles.length > 0 && (
                          <>
                            <SubTitle>Pacotes</SubTitle>
                            <BillBundleList
                              bill={bill}
                              billBundles={billBundles}
                              professionals={professionals}
                              enableEditting={bill.cash_register.open}
                              onEdit={bundle => {
                                setBillBundles (billBundles.map (b => b.id === bundle.id ? {...bundle} : b))
                                setCustomerServices ([
                                  ...customerServices.filter (s => s.bundle_id !== bundle.id), bundle.services
                                ])
                              }}
                              onDelete={bundle => {
                                setBillBundles (billBundles.filter (b => b.id !== bundle.id))
                                setCustomerServices (customerServices.filter (s => s.bundle_id !== bundle.id))
                              }}
                            />
                          </>
                        )}
                        {billTips.length > 0 && (
                          <>
                            <SubTitle>Caixinhas</SubTitle>
                            <BillTipList
                              bill={bill}
                              billTips={billTips}
                              professionals={professionals}
                              enableEditting={bill.cash_register.open}
                              onEdit={tip => setBillTips (billTips.map (t => t.id === tip.id ? {...tip} : t))}
                              onDelete={tip => setBillTips (billTips.filter (t => t.id !== tip.id))}
                            />
                          </>
                        )}
                      </>
                    ) : (
                      <InfoText style={{color: '#000000', marginBottom: 20}}>
                        Esta comanda ainda não possui itens
                      </InfoText>
                    )}
                    {showServiceForm && (
                      <BillServiceForm
                        bill={bill}
                        services={services}
                        professionals={professionals}
                        customerServices={customerServices}
                        onConfirm={services => setBillServices ([...billServices, ...services])}
                        onClose={toggleServiceForm}
                      />
                    )}
                    {showProductForm && (
                      <BillProductForm
                        bill={bill}
                        professionals={professionals}
                        products={products}
                        onConfirm={product => setBillProducts ([...billProducts, product])}
                        onClose={toggleProductForm}
                      />
                    )}
                    {showBundleForm && (
                      <BillBundleForm
                        bill={bill}
                        professionals={professionals}
                        bundles={bundles}
                        onConfirm={bundle => {
                          setBillBundles ([...billBundles, bundle])
                          setCustomerServices ([
                            ...customerServices,
                            ...bundle.services.map (s => ({...s, bundle_id: bundle.id}))
                          ])
                        }}
                        onClose={toggleBundleForm}
                      />
                    )}
                    {showTipForm && (
                      <BillTipForm
                        bill={bill}
                        professionals={professionals}
                        onConfirm={tip => setBillTips ([...billTips, tip])}
                        onClose={toggleTipForm}
                      />
                    )}
                    {bill.cash_register.open && (
                      <Row style={{marginBottom: 0}}>
                        {ITEM_OPTIONS.map (option => (
                          <AltButton key={option.value} onClick={option.onClick} selected={option.selected}>
                            {option.selected ? (
                              <FA.FaTimes size={14} style={{marginTop: 1, marginRight: 7}}/>
                            ) : (
                              <FA.FaPlus size={12} style={{marginRight: 7}}/>
                            )}
                            {option.label}
                          </AltButton>
                        ))}
                      </Row>
                    )}
                  </Info>
                )}
                {option === 'payment' && (
                  <Info>
                    {hasFreeServices () && (
                      <Row style={{gap: 7}}>
                        <TbFreeRights size={24} color='#FFC100'/>
                        <SubTitle style={{marginBottom: 0}}>
                          Este cliente possui serviços de pacotes adiquiridos que não serão cobrados
                        </SubTitle>
                      </Row>
                    )}
                    <InfoContainer>
                      <FormField>
                        <Label>Caixa responsável</Label>
                        <SubTitle style={{marginBottom: 7}}>
                          {bill.cash_register.opened_by?.name || user.name}
                          <br/>
                          ({DateTime.fromISO (bill.cash_register.created_at).toFormat ('dd/MM/yyyy H:mm')})
                        </SubTitle>
                      </FormField>
                      <FormField>
                        <Label>Créditos / dívidas</Label>
                        <MoneyTextMask value={totalBalance}/>
                        {bill.cash_register.open && totalBalance !== 0 && (
                          <Row style={{alignItems: 'center'}}>
                            <CheckBox
                              id='useCredits'
                              style={{marginRight: -3}}
                              value={useCredits}
                              onValueChange={handleUseCredits}
                            />
                            <label htmlFor='useCredits' style={{cursor: 'pointer'}}>
                              Somar {totalBalance > 0 ? 'crédito' : 'dívida'} à comanda
                            </label>
                          </Row>
                        )}
                      </FormField>
                      <FormField>
                        <Label>Total da comanda</Label>
                        <MoneyTextMask value={billTotal ?? 0}/>
                      </FormField>
                      <FormField>
                        <Label>Total a cobrar</Label>
                        <MoneyTextMask
                          value={totalWithDiscount ?? 0}  
                          style={{color: formikProps.values.payments.some (p => (
                            p.paymentMethod?.value.codename === 'debt'
                          )) ? '#FF3939' : '#44CF6C'}}
                        />
                      </FormField>
                      <FormField>
                        <Label>Troco</Label>
                        <MoneyTextMask value={(finalValue) ?? 0}/>
                      </FormField>
                    </InfoContainer>
                    {requirePayment && (
                      <>
                        <SubTitle>Pagamentos</SubTitle>
                        {formikProps.values.payments.map ((_, index) => (
                          <Row key={index}>
                            <PaymentForm
                              index={index}
                              formikProps={formikProps}
                              paymentMethods={paymentMethods}
                              bankAccounts={bankAccounts}
                              cardFlags={cardFlags}
                              billValue={totalWithDiscount}
                              disabled={!bill.cash_register.open}
                            />
                            {formikProps.values.payments.length > 1 && bill.cash_register.open && (
                              <DangerButton onClick={() => {
                                handleDeletePayment (formikProps.values.payments, index, formikProps.setFieldValue)
                              }}>
                                <FA.FaTrash color='#FFFFFF' size={12}/>
                              </DangerButton>
                            )}
                          </Row>
                        ))}
                        {bill.cash_register.open && (
                          <SmallButton
                            style={{width: 210}}
                            onClick={() => {
                              handleAddPayment (formikProps.values.payments, formikProps.setFieldValue)
                            }}
                          >
                            <FA.FaPlus style={{marginRight: 7}}/>
                            Método de pagamento
                          </SmallButton>
                        )}
                      </>
                    )}
                    {errorMessage && (
                      <ErrorContainer>
                        <InfoText>{errorMessage}</InfoText>
                      </ErrorContainer>
                    )}
                    {bill.cash_register.open && (
                      <Button
                        disabled={formikProps.isSubmitting}
                        onClick={async () => {
                          let errors = null
                          await Promise.all (formikProps.values.payments.map (async (_, index) => {
                            await formikProps.setFieldTouched (`payments[${index}].cardFlag`)
                            errors = await formikProps.setFieldTouched (`payments[${index}].paymentMethod`)
                          }))
                          if (!errors?.payments?.length > 0) toggleAlertDialog ()
                        }}
                      >
                        {formikProps.isSubmitting ? <ButtonLoading/> : 'Salvar e finalizar'}
                      </Button>
                    )}
                    <AlertDialog
                      visible={showAlertDialog}
                      title='Atenção!'
                      message={
                        <InfoText style={{color: '#000000'}}>
                          {'Ao salvar esta comanda, ela será finalizada'
                          + (finalValue !== 0 ? (
                            ` e irá gerar ${finalValue > 0 ? 'um crédito' : 'uma dívida'} `
                            + `no valor de R$ ${Math.abs (finalValue)} para o cliente. `
                          ) : '. ')
                          + 'Deseja realmente prosseguir?'}
                        </InfoText>
                      }
                      confirmText='Sim'
                      cancelText='Não'
                      onConfirm={formikProps.handleSubmit}
                      onClose={toggleAlertDialog}
                    />
                  </Info>
                )}
              </Form>
            )
          }}
        </Formik>
      )}
    </Modal>
  )
}

export default EditBillModal
