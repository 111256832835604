import React, { useState, useEffect } from 'react'
import { FaCheck } from 'react-icons/fa'

import {
  CheckToggle
} from './styles'

function CheckBox ({
  id,
  value,
  onValueChange,
  style
}) {
  const [checked, setChecked] = useState (value)

  useEffect (() => {
    setChecked (value)
  }, [value])

  const handleValueChanged = () => {
    setChecked (!checked)
    onValueChange (!checked)
  }

  return (
    <CheckToggle
      id={id}
      onClick={handleValueChanged}
      style={{backgroundColor: checked ? '#FFC100' : '#FFFFFF', ...style}}
    >
      {checked && <FaCheck color='#FFFFFF' size={10} style={{marginBottom: 13}}/>}
    </CheckToggle>
  )
}

export default CheckBox
