import styled from 'styled-components'
import { DateTime } from 'luxon'

// const cellHeight = 30

const getContainerColor = ({
  isInProgress, isCompleted, isValidated, isReservation, cutileiSchedule, status
}) => {
  let statusColor = ''

  switch (status) {
    case 'scheduled': statusColor = '#373737'; break
    case 'confirmed': statusColor = '#89C6C2'; break
    case 'waiting': statusColor = '#FFD860'; break
    case 'in_progress': statusColor = '#ACD685'; break
    case 'finished': statusColor = '#D39CC6'; break
    case 'paid': statusColor = '#52A067'; break
    case 'missed': statusColor = '#DF6969'; break
    case 'canceled': statusColor = '#FF3939'; break
    default: statusColor = '#373737'
  }

  if (cutileiSchedule) {
    if (isValidated && isInProgress)
      return '#FFD860'
    else if (isCompleted)
      return isValidated ? '#52A067' : '#DF6969'
    else return '#373737'
  } else {
    return isReservation ? '#A5A5A5' : statusColor
  }
}

const getNumConflictingSchedules = (schedules, schedule, starttime, endtime, timeInterval) => {
  let conflictingSchedules = []
  const parseTime = timeString => DateTime.fromFormat (timeString, 'H:mm')
  const isReservation = schedule => schedule.validated === undefined
  const currentStart = parseTime (starttime)
  const currentEnd = parseTime (endtime)

  const isDoneAndNotValidated = schedule => {
    const scheduleDateTime = DateTime.fromFormat (`${schedule.date} ${schedule.time}`, 'yyyy-MM-dd H:mm')
    const isCompleted = DateTime.now () >= scheduleDateTime?.plus ({minutes: schedule.max_delay + 1})

    return isCompleted && !schedule.validated
  }

  const sortedSchedules = schedules.filter (s => s.id !== schedule.id).sort ((a, b) =>
    parseTime (a.time || a.start_time).toMillis () - parseTime (b.time || b.start_time).toMillis ()
  )
  
  sortedSchedules.forEach (nextSchedule => {
    const nextStart = isReservation (nextSchedule)
      ? parseTime (nextSchedule.start_time)
      : parseTime (nextSchedule.time)

    const nextEnd = isReservation (nextSchedule)
      ? parseTime (nextSchedule.end_time)
      : parseTime (nextSchedule.time).plus ({
        minutes: isDoneAndNotValidated && nextSchedule.duration > timeInterval
          ? timeInterval : nextSchedule.duration
      })

    if (currentStart < nextEnd && currentEnd > nextStart)
      conflictingSchedules.push (nextSchedule)
  })
  
  return conflictingSchedules.length
}

export const Container = styled.div`
  z-index: ${({ isDragging }) => isDragging ? 10 : 1};
  height: calc(100% - 1px);
  text-decoration: none;
  grid-row-start: ${({ timewindows, starttime }) => timewindows.indexOf (starttime) + 1};
  grid-row-end: ${({ timewindows, endtime, isCompleted, isValidated, cutileiSchedule }) => {
    const stretchNumber = cutileiSchedule && isCompleted && !isValidated ? 0 : 1
    return timewindows.indexOf (endtime) + stretchNumber
  }};
  grid-column-end: ${({ schedules, schedule, starttime, endtime, timeInterval }) => {
    const numConflictingSchedules = getNumConflictingSchedules (
      schedules, schedule, starttime, endtime, timeInterval
    )
    return numConflictingSchedules > 0 ? 'unset' : `span 2`
  }};
  border: 1px solid #FFFFFF;
  margin: 1px 0px 1px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  overflow: hidden;
  opacity: ${({ isDragging }) => isDragging ? 0.6 : 1};
  cursor: ${({ isDragging }) => isDragging ? 'grabbing' : 'pointer'};
  background-color: ${props => getContainerColor (props)};

  p {
    color: #FFFFFF;
    line-height: 12px;
  }
`

  /* &:after {
    content: '';
    background-color: #80B2D955;
    position: absolute;
    border-radius: 8px;
    margin-left: calc((100vw - 510px) / ${({ numColumns }) => numColumns} / 2);
    width: ${({ isReservation, schedules, schedule, numColumns }) => {
      const index = schedules.map (s => s.id).indexOf (schedule.id)
      return isReservation && getNumConflictingSchedules (schedules, schedule).length > 0 && index === 0
        ? `calc((100vw - 540px) / ${numColumns})` : '0px'
    }};
    height: ${({ timewindows, starttime, endtime }) => 
      (timewindows.indexOf (endtime) - timewindows.indexOf (starttime)) * cellHeight + 'px'
    };
  } */

export const Details = styled.div`
  background: #252525;
  height: auto;
  width: 250px;
  margin-bottom: ${({cutileiSchedule}) => cutileiSchedule ? '0px' : '-5px'};

  p {
    font-size: 14px;
    text-align: left;
  } 
`

export const DetailColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: auto;

  &:last-child {
    margin-left: 5px;
  }
`

export const BlockInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin-right: 3px;
    flex-shrink: 0;
  }

  p {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }
`

export const Row = styled.div`
  width: 100%;
  padding: 0px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
`

export const Image = styled.img`  
  width: 16px;
  height: 16px;
`

export const Picture = styled.img`
  width: 35px;
  height: 35px;
  border-radius: 18px;
  align-self: flex-end;
`

export const InfoText = styled.p`
  font-size: 11px;
  line-height: 15px;
  text-align: center;
  color: #FFFFFF;
  margin: 0px;
`

export const SubTitle = styled(InfoText)`
  font-weight: bold;
  word-break: break-all;
`

export const Light = styled.span`
  height: 16px;
  width: 16px;
  display: ${({ isCompleted, isReservation }) => isCompleted || isReservation ? 'flex' : 'none'};
  border-radius: 20px;
  background-color: ${props => getContainerColor (props)};
`