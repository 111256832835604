import React from 'react'
import Modal from 'react-modal'
import { Formik } from 'formik'
import deleteBillValidator from '../../validators/deleteBillValidator'
import * as FA from 'react-icons/fa'

import {
  modalStyle,
  Container,
  Form,
  FormField,
  Row,
  Title,
  Input,
  InfoText,
  WarningText,
  Button,
  DangerButton,
  CloseButton,
  ButtonText
} from './styles'

function DeleteBillDialog ({
  visible,
  title = 'Excluir comanda',
  onConfirm: handleConfirm,
  onClose: handleClose
}) {
  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      <Container>
        <Formik
          validationSchema={deleteBillValidator}
          initialValues={{'delete_reason': ''}}
          onSubmit={values => {
            handleConfirm (values.delete_reason)
            handleClose ()
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit
          }) => (
            <Form onSubmit={handleSubmit}>
              <Title>{title}</Title>
              <CloseButton onClick={handleClose}>
                <FA.FaTimes color='#FF3939' size={18}/>
              </CloseButton>
              <InfoText style={{paddingInline: 20, margin: 5}}>
                Se deseja excluir esta comanda, insira o motivo da exclusão no campo abaixo.
              </InfoText>
              <Row>
                <FormField>
                  <Input
                    type='textarea'
                    placeholder='Motivo'
                    value={values.delete_reason}
                    onChange={handleChange ('delete_reason')} 
                    onBlur={handleBlur ('delete_reason')}
                  />
                  {touched.delete_reason && errors.delete_reason && (
                    <WarningText>
                      {errors.delete_reason}
                    </WarningText>
                  )}
                </FormField>
              </Row>
              <Row>
                <DangerButton type='submit'>
                  <ButtonText>Confirmar</ButtonText>
                </DangerButton>
                <Button onClick={handleClose}>
                  <ButtonText>Cancelar</ButtonText>
                </Button>
              </Row>
            </Form>
          )}
        </Formik>
      </Container>
    </Modal>
  )
}

export default DeleteBillDialog
