import React from 'react'
import CurrencyInput from '@rschpdr/react-money-input'
import { Input } from './styles'

export default function MoneyInput ({
  name,
  maxValue,
  onValueChange: handleValueChange,
  ...props
}) {
  const handleChange = event => {
    const { value } = event.target
    handleValueChange (name, value ?? 0)
  }

  return (
    <CurrencyInput
      name={name}
      variant='outlined'
      customInput={Input}
      currencyConfig={{
        locale: 'pt-BR',
        currencyCode: 'BRL',
        currencyDisplay: 'symbol'
      }}
      max={maxValue ? maxValue * 100 : undefined}
      onChange={handleChange}
      {...props}
    />
  )
}
