import React, { useState, useEffect } from 'react'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import Select from '../../../components/Inputs/Select'
import ClearableInput from '../../../components/Inputs/ClearableInput'
import BusinessProductList from '../../../components/Lists/BusinessProductList'
import BusinessProductsModal from '../../Modals/BusinessProductsModal'
import AddProductModal from '../../Modals/AddProductModal'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import { DateTime } from 'luxon'

import {
  Container,
  Row,
  Button
} from '../styles'

function EditProducts () {
  const [loadingData, setLoadingData] = useState (true)
  const [loadingProducts, setLoadingProducts] = useState (true)
  const [products, setProducts] = useState ([])
  const [categories, setCategories] = useState ([])
  const [category, setCategory] = useState (null)
  const [name, setName] = useState ('')
  const [showProductsModal, setShowProductsModal] = useState (false)
  const [showAddProductModal, setShowAddProductModal] = useState (false)
  const token = AuthService.getToken ()
  const businessId = AuthService.getBusinessId ()

  useEffect (() => {
    getData ()
  }, [])

  useEffect (() => {
    getProducts ()
  }, [category, name])

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const getData = async () => {
    try {
      setLoadingData (true)

      const { data: categories } = await cutileiApi.get ('/product_categories', requestConfig)

      setCategories (categories.map (category => ({
        label: category.name,
        value: category.id
      })))
    } catch (error) {
      console.log (error.response)
    } finally {
      setLoadingData (false)
    }
  }

  const getProducts = async () => {
    let filters = ''
    if (name) filters += `&name=${name}`
    if (category) filters += `&category_id=${category.value}`
    
    try {
      setLoadingProducts (true)

      const { data: products } = await cutileiApi.get (
        `/businesses/${businessId}/products?status=active`, requestConfig
      )

      setProducts (products)
    } catch (error) {
      console.log (error.response)
    } finally {
      setLoadingProducts (false)
    }
  }

  const compareProducts = (a, b) => (
    DateTime.fromISO (a.created_at).toMillis () - DateTime.fromISO (b.created_at).toMillis ()
  )

  const toggleProductsModal = () => setShowProductsModal (!showProductsModal)
  const toggleAddProductModal = () => setShowAddProductModal (!showAddProductModal)

  return (
    <Container>
      <Row style={{gap: 10, width: 600}}>
        <Select
          name='category'
          placeholder='Categoria...'
          value={category}
          options={categories}
          isClearable={true}
          onChange={(_, option) => setCategory (option)}
          containerStyles={{boxShadow: '0px 1px 10px -7px', borderRadius: 15, height: 38}}
        />
        <ClearableInput
          placeholder='Pesquisar por nome'
          value={name}
          onChange={e => setName (e.target.value)}
          onClear={() => setName ('')}
          inputStyle={{boxShadow: '0px 1px 10px -6px', paddingInline: 12}}
        />
      </Row>
      <Row style={{gap: 10, marginBottom: 20}}>
        <Button onClick={toggleProductsModal}>
          Adicionar produtos
        </Button>
        <Button onClick={toggleAddProductModal}>
          Cadastrar produto
        </Button>
      </Row>
      {loadingData || loadingProducts ? <Loading/> : (
        <>
          <BusinessProductList
            products={products}
            onEdit={product => setProducts (products.map (p => p.id === product.id ? {...product} : p))}
            onDelete={deletedProduct => setProducts (products.filter (p => p.id !== deletedProduct.id))}
          />
          <BusinessProductsModal
            visible={showProductsModal}
            onConfirm={addedProducts => setProducts (
              [...products, ...addedProducts].sort (compareProducts)
            )}
            onClose={toggleProductsModal}
          />
          <AddProductModal
            visible={showAddProductModal}
            productCategories={categories}
            onConfirm={product => setProducts ([product, ...products])}
            onClose={toggleAddProductModal}
          />
        </>
      )}
    </Container>
  )
}

export default EditProducts
