import React, { useState } from 'react'
import Bundle from './Bundle'
import EditBundleModal from '../../../pages/Modals/EditBundleModal'

import {
  Container,
  SubTitle,
  InfoText,
  LabelRow
} from './styles'

function BundleList ({
  bundles,
  onEdit: handleEdit,
  onDelete: handleDelete
}) {
  const [bundleData, setBundleData] = useState (null)
  const [showEditBundleModal, setShowEditBundleModal] = useState (false)
  
  const editBundle = bundle => {
    setBundleData (bundle)
    toggleEditBundleModal ()
  }

  const toggleEditBundleModal = () => setShowEditBundleModal (!showEditBundleModal) 

  return (
    <Container>
      {bundles.length > 0 ? (
        <>
          <LabelRow>
            <SubTitle>Nome</SubTitle>
            <SubTitle>Preço</SubTitle>
            <SubTitle>Validade</SubTitle>
            <SubTitle>Ações</SubTitle>
          </LabelRow>
          {bundles.map (bundle =>
            <Bundle
              key={bundle.id}
              data={bundle}
              onEdit={editBundle}
              onDelete={handleDelete}
            />
          )}
          {bundleData && (
            <EditBundleModal
              visible={showEditBundleModal}
              data={bundleData}
              onClose={toggleEditBundleModal}
              onConfirm={bundle => handleEdit (bundle)}
            />
          )}
        </>
      ) : (
        <InfoText>Nenhum pacote encontrado</InfoText>
      )}
    </Container>
  )
}

export default BundleList
