import * as Yup from 'yup'

const businessProductValidator = Yup.object ({
  products: Yup.array ().of (Yup.object ().shape ({
    id: Yup.string (),
    selected: Yup.boolean (),
    nickname: Yup.string (),
    stock: Yup.number (),
    comission_percentage: Yup.number (),
    price: Yup.number ().nullable ().when ('selected', {
      is: true,
      then: schema => schema.positive ('Insira o preço')
    }),
  }))
})

export default businessProductValidator
