import React from 'react'
import * as FA from 'react-icons/fa'

import {
  Container,
  Input,
  ClearButton
} from './styles'

function ClearableInput ({
  placeholder,
  value,
  onChange: handleChange,
  onBlur: handleBlur = () => null,
  onClear: handleClear,
  containerStyle = {},
  inputStyle = {},
  ...props
}) {
  return (
    <Container style={containerStyle}>
      <Input
        type='text'
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        style={inputStyle}
        {...props}
      />
      {value !== '' && (
        <ClearButton onClick={handleClear}>
          <FA.FaTimes color='#252525' size={15}/>
        </ClearButton>
      )}
    </Container>
  )
}

export default ClearableInput
