import styled from 'styled-components'

export const modalStyle = {
  overlay: {
    zIndex: 5
  },
  content: {
    padding: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    zIndex: 5,
    overflow: 'visible',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 15,
    boxShadow: '0px 2px 15px -8px #252525'
  }
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
`

export const Row = styled(Container)`
  width: 100%;
  padding: 0px;
  gap: 10px;
  margin-bottom: 15px;
  flex-direction: row;
  align-items: flex-start;
`

export const CalendarContainer = styled(Container)`
  padding: 0px;
  margin: 0px 30px 0px 0px;
`

export const FormField = styled(Container)`
  flex: 1;
  justify-content: flex-end;
  padding: 0px;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  max-width: 620px;
  box-shadow: 0px 2px 10px -6px #252525;
  background-color: #F7F7F7;
  padding: 10px;
  border-radius: 15px;
`

export const Button = styled.button`
  width: 200px;
  align-self: center;
  background-color: #252525;
  color: #FFFFFF;
  padding: 8px;
  border: 0px;
  border-radius: 15px;
  text-align: center;
  text-decoration: none;

  &:hover {
    background-color: #121212;
    color: #FFFFFF;
  }
`

export const CloseButton = styled.button`
  position: fixed;
  top: 2px;
  right: 8px;
  width: 30px;
  height: 38px;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`

export const Input = styled.input`
  width: 100%;
  height: 38px;
  margin: 0px;
  border: 0px;
  box-shadow: 0px 2px 10px -8px #252525;
  border-radius: 15px;
  text-align: center;
  cursor: ${props => props.disabled ? 'not-allowed' : 'text'};

  &::placeholder {
    color: #BBBBBB
  }

  &:disabled {
    background-color: #FFFFFF;
  }
  
  &:focus {
    border: 1px solid #CCCCCC !important;
    outline: none;
  }
`

export const Label = styled.label`
  text-align: center;
  color: #000000;
  margin-bottom: 4px;

  &:hover {
    cursor: pointer;
  }
`

export const Title = styled.h2`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
  align-self: center;
`;

export const InfoText = styled.p`
  color: #FFFFFF;
  text-align: center;
  align-self: center;
  margin-bottom: 0px;
`

export const WarningText = styled(InfoText)`
  color: #FF3939;
`

export const ErrorContainer = styled.div`
  padding: 9px;
  margin: 15px 0px;
  width: 100%;
  border-radius: 15px;
  align-items: center;
  background-color: #FF3939;
`
