import React, { useState } from 'react'
import Reservation from './CutileiReservation'
import EditCutileiReservationModal from '../../../pages/Modals/EditCutileiReservationModal'

import {
  Container,
  SubTitle,
  InfoText,
  LabelRow
} from './styles'

function CutileiReservationList ({
  reservations,
  professionals,
  onEdit: handleEdit,
  onDelete: handleDelete
}) {
  const [reservationData, setReservationData] = useState (null)
  const [showEditReservationModal, setShowEditReservationModal] = useState (false)

  const editReservation = reservation => {
    setReservationData (reservation)
    toggleEditReservationModal ()
  }

  const toggleEditReservationModal = () => setShowEditReservationModal (!showEditReservationModal) 

  return (
    <Container>
      {reservations.length > 0 ? (
        <>
          <LabelRow>
            <SubTitle>Descrição</SubTitle>
            <SubTitle>Data inicial</SubTitle>
            <SubTitle>Data final</SubTitle>
            <SubTitle>Período</SubTitle>
            <SubTitle>Ações</SubTitle>
          </LabelRow>
          {reservations.map (reservation =>
            <Reservation
              key={reservation.id}
              data={reservation}
              onEdit={editReservation}
              onDelete={handleDelete}
            />
          )}
          {reservationData && (
            <EditCutileiReservationModal
              visible={showEditReservationModal}
              data={reservationData}
              onClose={toggleEditReservationModal}
              onConfirm={handleEdit}
            />
          )}
        </>
      ) : (
        <InfoText>Nenhuma reserva de agenda encontrada</InfoText>
      )}
    </Container>
  )
}

export default CutileiReservationList
