import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  margin: auto;
  max-width: 100%;
  border-radius: 15px;
  justify-content: center;
  align-items: center !important;
`

export const Header = styled.div`
  display: flex;
  position: fixed !important;
  top: 0px;
  width: 100%;
  height: 70px;
  background-color: #F7F7F7;
  flex: 1;
  align-items: center;
  padding: 10px 2vw 10px;
  z-index: 999;
`

export const CenterPadding = styled.div`
  display: flex;
  flex: 1;
  align-self: center;
  padding: 10px 2vw 10px;
`

export const Footer = styled.div`
  display: flex;
  background-color: #DDDDDD;
  height: 120px;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 10px 0px;
`

export const ImgContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const BlackContainer = styled.div`
  background-color: #121212;
  padding: 15vw;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  @media (max-width: 800px) {
    padding: 20px;
  }
`

export const WhiteContainer = styled(BlackContainer)`
  background-color: #F7F7F7;
`

export const H1 = styled.h1`
  font-size: 24px;
  margin: 0px 15px 2px 5px;
  text-align: center;
  font-weight: 400;
`

export const Slogan = styled.h2`
  font-size: 8vh;
  position: absolute;
  font-weight: 500;
  text-align: center;
  color: #F7F7F7;
  text-shadow: 2px 2px 8px #121212;
  margin: 0px 20px;

  @media (max-width: 800px) {
    font-size: 40px;
  }
`

export const SecondSlogan = styled.p`
  font-size: 4vh;
  font-weight: 500;
  text-align: center;
  color: #FFF8AD;
  text-shadow: 2px 2px 8px #121212;
  margin: 10px 20px;

  @media (max-width: 800px) {
    font-size: 25px;
  }
`

export const DivRow = styled.div`
  display: flex;
  flex: 0;
  justify-content: center;
  align-items: center;
  padding: 15px;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`

export const DivColumn = styled(DivRow)`
  flex: 1;
  flex-direction: column;
`

export const DivPlans = styled(DivColumn)`
  width: 350px;
  min-width: 30vw;
  min-height: 250px;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  justify-content: space-between;
  border-radius: 15px;
  margin: 10px;
  padding: 15px;

  @media (max-width: 800px) {
    width: 80vw;
  }
`

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  opacity: 0.8;
  filter: blur(2px);
  filter: grayscale(100%);
`

export const LogoImg = styled.img`
  height: 50px;
`

export const Title = styled.h2`
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
`

export const LinkButton = styled(Link)`
  color: #000000;
  font-size: 18px;
  padding: 8px 20px;
  border-radius: 15px;
  border-style: solid;
  border-width: 1px;
  border-color: #121212;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;

  &:hover {
    background-color: #121212;
    color: #FFFFFF;
  }

  @media (max-width: 500px) {
    display: none;
  }
`

export const LinkButtonWhite = styled(Link)`
  color: #F7F7F7;
  font-size: 16px;
  padding: 8px 20px;
  border-radius: 15px;
  border-style: solid;
  border-width: 1px;
  border-color: #F7F7F7;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;

  &:hover {
    background-color: #F7F7F7;
    color: #121212;
  }

  @media (max-width: 500px) {
    display: none;
  }
`

export const LinkShortcut = styled.a`
  color: #000000;
  font-size: 18px;
  padding: 8px 20px;
  border-radius: 15px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;

  &:hover {
    font-weight: 500;
    color: #000000;
  }

  @media (max-width: 500px) {
    display: none;
  }
`

export const Label = styled.p`
  font-size: 20px;
  margin-bottom: 5px;
  text-align: center;
`

export const SmallTitle = styled.p`
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: center;

  @media (max-width: 500px) {
    font-size: 26px;
  }
`

export const SmallTitleWhite = styled(SmallTitle)`
  color: #F7F7F7;
  margin-bottom: 20px;
`

export const LabelWhite = styled.p`
  font-size: 16px;
  color: #F7F7F7;
  margin-bottom: 5px;
  text-align: center;
`

export const MoneyText = styled.p`
  font-size: 30px;
  color: #000000;
  font-weight: 500;
  margin-bottom: 0px;
  text-align: center;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: flex-end;
`