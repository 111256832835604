import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import RequestCodeForm from './RequestCodeForm'
import ValidateCodeForm from './ValidateCodeForm'
import ResetPasswordForm from './ResetPasswordForm'
import * as FA from 'react-icons/fa'

import {
  Container,
  Title,
  StepRow,
  StepContainer,
  StepCircle,
  StepLabel,
  InfoText
} from './styles'

function ForgotPassword ({ history }) {
  const steps = [
    {number: 1, label: 'Solicitar código',value: 'request_code'},
    {number: 2, label: 'Validar código', value: 'verify_code'},
    {number: 3, label: 'Redefinir senha', value: 'reset_password'}
  ]
  const [currentStep, setCurrentStep] = useState (steps[0])
  const [userData, setUserData] = useState (null)

  const handleCodeRequested = () => setCurrentStep (steps[1])
  const handleCodeValidated = userData => {
    setUserData (userData)
    setCurrentStep (steps[2])
  }
  const handlePasswordReset = () => history.replace ('/login')

  return (
    <Container>
      <Title>Restaurar senha</Title>
      <StepRow>
        {steps.map ((step, index) => (
          <StepContainer key={index}>
            <StepCircle step={step} currentStep={currentStep}>
              {currentStep.number > step.number ? (
                <FA.FaCheck color='#FFFFFF' size={18}/>
              ) : (
                <InfoText>{step.number}</InfoText>
              )}
            </StepCircle>
            <StepLabel>{step.label}</StepLabel>
          </StepContainer>
        ))}
      </StepRow>
      {currentStep.value === 'request_code' && (
        <RequestCodeForm onSubmit={handleCodeRequested}/>
      )}
      {currentStep.value === 'verify_code' && (
        <ValidateCodeForm onSubmit={handleCodeValidated}/>
      )}
      {currentStep.value === 'reset_password' && (
        <ResetPasswordForm userData={userData} onSubmit={handlePasswordReset}/>
      )}
    </Container>
  )
}

export default withRouter (ForgotPassword)
