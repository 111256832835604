export default function styles (state, containerStyles) {
  const styles = {
    container: styles => ({
      ...styles,
      ...containerStyles,
      width: containerStyles?.width ?? '100%'
    }),

    control: (styles, { isFocused }) => ({ 
      ...styles,
      minHeight: '38px',
      height: '38px',
      margin: '0px',
      flex: '0',
      color: '#000000',
      backgroundColor: '#FFFFFF',
      borderRadius: '15px',
      paddingLeft: '1.2rem',
      textAlign: 'center',
      cursor: 'pointer',
      boxShadow: '0px 2px 10px -8px #252525',
      border: isFocused
      ? '1px solid #CCCCCC' 
      : state === 'error' ? '0 0 0 1px #FF3939' : 'none',
      ':hover': {
        ...styles[':hover'],
        border: isFocused ? '1px solid #CCCCCC' : 'none' ,
      },
    }),

    singleValue: styles => ({
      ...styles, 
      color: '#000000',
    }),
  
    valueContainer: styles => ({ 
      ...styles,
      paddingBottom: '3px',
      height: 'inherit',
    }),
  
    placeholder: styles => ({ 
      ...styles,
      color: '#BBBBBB',
    }),
  
    dropdownIndicator: styles => ({
      ...styles,
      color: '#111111',
      ':hover': {
        ...styles[':hover'],
        color: '#000000',
        cursor: 'pointer',
      }
    }),

    clearIndicator: styles => ({
      ...styles,
      color: '#111111',
      marginRight: -12,
      ':hover': {
        ...styles[':hover'],
        color: '#000000',
        cursor: 'pointer',
      }
    }),
  
    indicatorSeparator: styles => ({
      ...styles,
      backgroundColor: 'transparent',
    }),
  
    menu: styles => ({
      ...styles,
      width: '100%',
      backgroundColor: '#FFFFFF',
      borderRadius: '3px',
    }),
  
    menuList: styles => ({
      ...styles,
      borderRadius: '3px',
    }),
  
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        textAlign: 'center',
        backgroundColor: isSelected 
          ? '#E9E9E9' 
          : isFocused ? '#F0F0F0' : '#FFFFFF',
        color: isSelected || isFocused ? '#000000' : '#222222',
        fontWeight: isSelected || isFocused ? '500' : '300',
        ':active': {
          ...styles[':active'],
          backgroundColor: isSelected ? '#FFFFFF' : '#F0F0F0',
        },
      };
    },
  
    noOptionsMessage: styles => ({
      ...styles,
      color: '#000000',
    }),
  
    input: styles => ({
      ...styles,
      color: '#000000',
    }),
  }

  return styles
}
