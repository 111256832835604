import React, { useRef } from 'react'

import { InputButton } from './styles'

const FileInput = ({
  label = 'Selecionar arquivo',
  accept,
  onFileSelected: handleFileSelected
}) => {
  const hiddenFileInput = useRef (null)
  
  const handleClick = event => {
    hiddenFileInput.current.click ()
  }

  const handleChange = event => {
    const fileUploaded = event.target.files[0]
    handleFileSelected (fileUploaded)
  }

  return (
    <>
      <InputButton onClick={handleClick}>
        {label}
      </InputButton>
      <input
        type='file'
        accept={accept}
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{display: 'none'}}
      />
    </>
  )
}

export default FileInput
