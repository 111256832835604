import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import Comission from '../../../../services/Comission'
import AuthService from '../../../../services/auth'
import cutileiApi from '../../../../services/cutileiApi'
import Dashboard from '../../../../services/Dashboard'
import PieChart from '../../../../components/Charts/PieChart'
import ColumnChart from '../../../../components/Charts/ColumnChart'
import Select from '../../../../components/Inputs/Select'
import { ReactComponent as Loading } from '../../../../icons/loading2.svg'
import * as FA from 'react-icons/fa'
import { DateTime } from 'luxon'

import {
  modalStyle,
  InfoContainer,
  ModalContainer,
  FilterContainer,
  LoadingContainer,
  CloseButton,
  SubTitle,
  InfoText,
  LinkText,
  MoneyText,
  Row
} from '../../styles'

function EarningsByType ({
  data: earnings,
  selectedMonth = DateTime.now ().month,
  selectedYear = DateTime.now ().year
}) {
  const now = DateTime.now ()
  const token = AuthService.getToken ()
  const businessId = AuthService.getBusinessId ()
  const periodFilterOptions = Dashboard.TIME_FILTERS
  const [loading, setLoading] = useState (true)
  const [isMounted, setMounted] = useState (false)
  const [periodFilter, setPeriodFilter] = useState (null)
  const [prevPeriodFilter, setPrevPeriodFilter] = useState (null)
  const [showDetails, setShowDetails] = useState (false)
  const [filteredEarnings, setFilteredEarnings] = useState ([])
  const [earningsByMonth, setEarningsByMonth] = useState ([])

  const earningsByType = [
    ['Tipo de rendimento', 'Valor (R$)'],
    ['Cutilei', Comission.getTotalEarnings (earnings, {value: 'cutilei'})],
    ['Salão', Comission.getTotalEarnings (earnings, {value: 'bill'})]
  ]

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }
  
  useEffect (() => {
    if (isMounted) setPeriodFilter (periodFilterOptions[0])
  }, [isMounted])

  useEffect (() => {
    getEarningsByMonth (filteredEarnings)
  }, [filteredEarnings])

  useEffect (() => {
    if (periodFilter?.value > prevPeriodFilter?.value || !prevPeriodFilter) getData ()
    else filterData ()
  }, [periodFilter])

  const getData = async () => {
    if (showDetails) try {
      setLoading (true)

      const startDate = now.minus ({months: periodFilter.value}).startOf ('month').toISODate ()
      const endDate = prevPeriodFilter
        ? now.minus ({months: prevPeriodFilter.value + 1}).endOf ('month').toISODate ()
        : now.toISODate ()

      const filters = `?business_id=${businessId}&order_by=created_at&order=asc&cutilei_schedules=true`
                    + '&detailed=true&open=false&status=validated&deleted=false&page=1&per_page=999999'
                    + `&start_date=${startDate}&end_date=${endDate}`
        
      const { data: schedules } = await cutileiApi.get (`/schedules${filters}`, requestConfig)
      const { data: bills } = await cutileiApi.get (`/business_bills${filters}`, requestConfig)

      const earnings = [
        ...schedules.data.map (s => ({...s, type: 'cutilei'})),
        ...bills.map (b => ({...b, type: 'bill'}))
      ]

      setFilteredEarnings ([...earnings, ...filteredEarnings])
    } catch (error) {
      console.log (error.response?.data)
    } finally {
      setLoading (false)
    }
  }

  const filterData = () => {
    setLoading (true)
    const startDate = now.minus ({months: periodFilter.value}).startOf ('month')

    setFilteredEarnings (filteredEarnings.filter (earning => (
      DateTime.fromISO (earning.date) >= startDate
    )))

    setTimeout (() => {
      setLoading (false)
    }, 800)
  }

  const getEarningsByMonth = earnings => {
    const earningsByMonth = Dashboard.groupEarningsByMonth (earnings)
      
    setEarningsByMonth ([
      ['Origem', 'Cutilei', 'Salão'],
      ...Object.keys (earningsByMonth).map (month => ([
        month,
        Comission.getTotalEarnings (earningsByMonth[month], {value: 'cutilei'}),
        Comission.getTotalEarnings (earningsByMonth[month], {value: 'bill'})
      ]))
    ])
  }

  const handlePeriodFilterSelected = option => {
    setPrevPeriodFilter (periodFilter)
    setPeriodFilter (option)
  }

  const toggleDetails = () => {
    if (!isMounted) setMounted (true)
    setShowDetails (!showDetails)
  }

  return (
    <InfoContainer>
      <Row>
        <SubTitle>Faturamento do mês:</SubTitle>
        <MoneyText value={Comission.getTotalEarnings (earnings, {value: 'all_earnings'})}/>
      </Row>
      <PieChart data={earningsByType}/>
      <LinkText onClick={toggleDetails}>
        Ver histórico
      </LinkText>
      <Modal
        isOpen={showDetails}
        onRequestClose={toggleDetails}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        style={modalStyle}
      >
        <ModalContainer>
          <SubTitle>Faturamento do mês</SubTitle>
          <CloseButton onClick={toggleDetails}>
            <FA.FaTimes color='#FF3939' size={18}/>
          </CloseButton>
          <FilterContainer>
            <Select
              name='periodFilter'
              placeholder='Período...'
              value={periodFilter}
              options={periodFilterOptions}
              onChange={(_, option) => handlePeriodFilterSelected (option)}
              containerStyles={{boxShadow: '0px 1px 10px -7px', borderRadius: 15, width: 250}}
            />
          </FilterContainer>
          {loading ? (
            <LoadingContainer>
              <Loading/>
              <InfoText>Gerando gráfico...</InfoText>
            </LoadingContainer>
          ) : (
            <ColumnChart data={earningsByMonth} options={{chartArea: {}}}/>
          )}
        </ModalContainer>
      </Modal>
    </InfoContainer>
  )
}

export default EarningsByType
