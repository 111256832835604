import React from 'react'
import Modal from 'react-modal'
import * as FA from 'react-icons/fa'

import {
  modalStyle,
  Container,
  Header,
  Row,
  Title,
  Message,
  Button,
  DangerButton,
  CloseButton,
  ButtonText
} from './styles'

function OptionDialog ({
  visible,
  title = 'Selecionar opção',
  message,
  onClose: handleClose,
  containerStyle = {},
  buttonStyle = {},
  options = [
    {label: 'Opção 1', onClick: () => null, dangerous: false},
    {label: 'Opção 2', onClick: () => null, dangerous: false}
  ]
}) {
  const handleOptionSelected = option => {
    option.onClick ()
    handleClose ()
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      <Container style={containerStyle}>
        <Header>
          <Title>
            {title}
          </Title>
          <CloseButton onClick={handleClose}>
            <FA.FaTimes color='#FF3939' size={18}/>
          </CloseButton>
        </Header>
        <Message>
          {message}
        </Message>
        <Row>
          {options.map ((option, index) => (
            option.dangerous ? (
              <DangerButton
                key={index}
                onClick={() => handleOptionSelected (option)}
                style={buttonStyle}
              >
                <ButtonText>
                  {option.label}
                </ButtonText>
              </DangerButton>
            ) : (
              <Button
                key={index}
                onClick={() => handleOptionSelected (option)}
                style={{
                  ...index === 0 && {backgroundColor: '#FFC100'},
                  ...buttonStyle
                }}
              >
                <ButtonText>
                  {option.label}
                </ButtonText>
              </Button>
            )
          ))}
        </Row>
      </Container>
    </Modal>
  )
}

export default OptionDialog
