import React, { useState, useEffect } from 'react'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import EarningsByType from './EarningsByType'
import EarningsByCategory from './EarningsByCategory'
import EarningsByProfessional from './EarningsByProfessional'
import EarningsByPaymentMethod from './EarningsByPaymentMethod'
import EarningsByBillItem from './EarningsByBillItem'
import EarningsAndExpenses from './EarningsAndExpenses'
import BonusesAndDiscounts from './BonusesAndDiscounts'
import CreditsAndDebts from './CreditsAndDebts'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import { DashboardContainer, LoadingContainer, InfoText } from '../styles'
import { DateTime } from 'luxon'

function Earnings () {
  const now = DateTime.now ()
  const [loading, setLoading] = useState (true)
  const [professionals, setProfessionals] = useState ([])
  const [categories, setCategories] = useState ([])
  const [paymentMethods, setPaymentMethods] = useState ([])
  const [businessEarnings, setBusinessEarnings] = useState ([])
  const [earnings, setEarnings] = useState ([])
  const [expenses, setExpenses] = useState ([])
  const [credits, setCredits] = useState ([])
  const [debts, setDebts] = useState ([])
  const [bonuses, setBonuses] = useState ([])
  const [discounts, setDiscounts] = useState ([])
  const token = AuthService.getToken ()
  const businessId = AuthService.getBusinessId ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getData ()
  }, [])

  const getData = async () => {
    try {
      setLoading (true)

      const filters = `?business_id=${businessId}&order_by=created_at&order=desc&cutilei_schedules=true`
                    + '&detailed=true&open=false&status=validated&deleted=false&page=1&per_page=999'
                    + `&month=${now.month}&year=${now.year}`
        
      const { data: schedules } = await cutileiApi.get (`/schedules${filters}`, requestConfig)
      const { data: bills } = await cutileiApi.get (`/business_bills${filters}`, requestConfig)
      const { data: categories } = await cutileiApi.get ('/service_categories', requestConfig)
      const { data: paymentMethods } = await cutileiApi.get ('/payment_methods', requestConfig)
      const { data: earnings } = await cutileiApi.get (`/earnings${filters}`, requestConfig)
      const { data: expenses } = await cutileiApi.get (`/expenses${filters}`, requestConfig)
      const { data: credits } = await cutileiApi.get (`/credits${filters}`, requestConfig)
      const { data: debts } = await cutileiApi.get (`/debts${filters}?customer_id=all_customers`, requestConfig)
      const { data: bonuses } = await cutileiApi.get (`/credits${filters}?professional_id=all_professionals`, requestConfig)
      const { data: discounts } = await cutileiApi.get (`/debts${filters}?professional_id=all_professionals`, requestConfig)
      const { data: professionals } = await cutileiApi.get (
        `/businesses/${businessId}/professionals?access_level=service`,
        requestConfig
      )

      const businessEarnings = [
        ...schedules.data.map (s => ({...s, type: 'cutilei'})),
        ...bills.map (b => ({...b, type: 'bill'}))
      ]
      
      setBusinessEarnings (businessEarnings)
      setProfessionals (professionals)
      setCategories (categories)
      setPaymentMethods (paymentMethods)
      setEarnings (earnings)
      setExpenses (expenses)
      setCredits (credits)
      setDebts (debts)
      setBonuses (bonuses)
      setDiscounts (discounts)
    } catch (error) {
      console.log (error.response?.data)
    } finally {
      setLoading (false)
    }
  }

  return loading ? (
    <LoadingContainer>
      <Loading/>
      <InfoText>Gerando gráficos...</InfoText>
    </LoadingContainer>
  ) : (
    <DashboardContainer>
      {businessEarnings.length > 0 && (
        <>
          <EarningsByType data={businessEarnings}/>
          <EarningsByProfessional data={businessEarnings} professionals={professionals}/>
          <EarningsByCategory data={businessEarnings} serviceCategories={categories}/>
          <EarningsByPaymentMethod data={businessEarnings} paymentMethods={paymentMethods}/>
          <EarningsByBillItem data={businessEarnings}/>
        </>
      )}
      {(earnings.length > 0 || expenses.length > 0) && (
        <EarningsAndExpenses earnings={earnings} exponses={expenses}/>
      )}
      {(bonuses.length > 0 || discounts.length > 0) && (
        <BonusesAndDiscounts bonuses={bonuses} discounts={discounts}/>
      )}
      {(credits.length > 0 || debts.length > 0) && (
        <CreditsAndDebts credits={credits} debts={debts}/>
      )}
    </DashboardContainer>
  )
}

export default Earnings
