import download from 'downloadjs'

export default class Export {
  static async downloadWorkSheet (workbook, fileName) {
    const buffer = await workbook.xlsx.writeBuffer ()
    const blob = new Blob ([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })
    
    download (blob, `${fileName}.xlsx`)
  }
}
