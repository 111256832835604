export default class Payment {
  static groupBy (payments, key) {
    return payments.reduce ((payment, index) => {
      (payment[index[key]] = payment[index[key]] || []).push (index)
      return payment
    }, {})
  }

  static getTotalValue (payments) {
    return payments.reduce ((total, payment) => total + payment.value, 0)
  }

  static getTotalValueByBankAccount (payments, bankAccount) {
    return payments.reduce ((total, payment) => (
      total + (payment.bank_account?.id === bankAccount.id ? payment.value : 0)
    ), 0)
  }

  static getTotalValueByCardFlag (payments, cardFlag) {
    return payments.reduce ((total, payment) => (
      total + (payment.card_flag?.codename === cardFlag ? payment.value : 0)
    ), 0)
  }

  static getTotalValueByPaymentMethod (payments, paymentMethod, cardFlag = null, bankAccount = null) {
    return payments.reduce ((total, payment) => (
      total + (
        payment.payment_method?.codename === paymentMethod.codename
        && (cardFlag ? payment.card_flag?.codename === cardFlag.codename : true)
        && (bankAccount ? payment.bank_account?.id === bankAccount.id : true)
          ? payment.value
          : 0
      )
    ), 0)
  }
}

