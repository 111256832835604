import styled from 'styled-components'

export const CheckToggle = styled.button.attrs ({
  type: 'button'
})`
  width: 16px;
  height: 16px;
  padding: 0px;
  border: 2px solid #FFC100;
  background-color: transparent;
  text-decoration: none;
  border-radius: 20px;
  margin-right: 5px;
  justify-content: center;
  align-items: center;
`
