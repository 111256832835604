import * as Yup from 'yup'

const professionalCreditsValidator = Yup.object ({
  professional: Yup
    .object ()
    .nullable ()
    .required ('Selecione um profissional'),

  description: Yup
    .string ()
    .required ('Insira a descrição')
    .max (256, 'A descrição deve conter no máximo 256 caracteres'),
      
  value: Yup
    .number ()
    .positive ('Insira o valor'),

  date: Yup
    .string ()
    .required ('Selecione uma data')
})

export default professionalCreditsValidator
