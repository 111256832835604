import { DateTime } from 'luxon'
import React from 'react'
import { withRouter } from 'react-router-dom'
import Phone from '../../../services/Phone'
import AdminNavbar from '../../../components/AdminNavbar'
import Address from '../../../services/Address'

import {
  Container,
  Info,
  Image,
  Row,
  LinkShortcut,
  Title,
  SubTitle,
  InfoText,
  Block,
  MoneyText,
} from './styles'

function Member ({ history }) {
  const { member } = history.location.state

  return (
    <Container>
      <AdminNavbar/>
      <Info>
        <Title>{member.name}</Title>
        <Image src={member.profile_picture}/>
        {member.sponsored &&
          <MoneyText style={{fontSize: 18, marginBottom: 5}}>
            Embaixador(a)
          </MoneyText>
        }
        <Block>
          <Row>
            <InfoText>E-mail: &nbsp;</InfoText>
            <SubTitle>{member.email}</SubTitle>
          </Row>
          <Row>
            <InfoText>Contato: &nbsp;</InfoText>
            <SubTitle>{Phone.formatPhone (member.phone)}</SubTitle>
          </Row>
          <Row>
            <InfoText>CPF: &nbsp;</InfoText>
            <SubTitle>{member.cpf}</SubTitle>
          </Row>
          <Row>
            <InfoText>Gênero: &nbsp;</InfoText>
            <SubTitle>{member.gender}</SubTitle>
          </Row>
          <Row>
            <InfoText>Nascimento: &nbsp;</InfoText>
            <SubTitle>{DateTime.fromISO (member.birth).toFormat ('dd/MM/yyyy')}</SubTitle>
          </Row>
        </Block>
        <Block>
          <Row>
            <InfoText>Endereço:</InfoText>
          </Row>
          <Row>
            <SubTitle>{Address.formatAddress (member.addresses[0])}</SubTitle>
          </Row>
        </Block>
        <Block>
          <Row>
            <InfoText>Cadastro: &nbsp;</InfoText>
            <SubTitle>{DateTime.fromISO (member.created_at).toFormat ('dd/MM/yyyy - H:mm:ss')}</SubTitle>
          </Row>
          <Row>
            <InfoText>Última alteração: &nbsp;</InfoText>
            <SubTitle>{DateTime.fromISO (member.updated_at).toFormat ('dd/MM/yyyy - H:mm:ss')}</SubTitle>
          </Row>
        </Block>
      </Info>
    </Container>
  )
}

export default withRouter (Member)
