import * as FA from 'react-icons/fa'

const NavbarData = [
  {
    title: 'Financeiro',
    path: '/admin',
    icon: <FA.FaDollarSign/>,
  },
  {
    title: 'Membros',
    path: '/admin/members',
    icon: <FA.FaUser/>,
  },
  {
    title: 'Salões',
    path: '/admin/businesses',
    icon: <FA.FaStoreAlt/>,
  },
  {
    title: 'Consumo',
    path: '/admin/usage',
    icon: <FA.FaChartBar/>,
  },
  {
    title: 'Serviços',
    path: '/admin/config',
    icon: <FA.FaCog/>,
  },
]

export default NavbarData
