import React, { useState } from 'react'
import Customer from './Customer'
import CustomerModal from '../../../pages/Modals/CustomerModal'
import EditCustomerModal from '../../../pages/Modals/EditCustomerModal'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'

import {
  Container,
  LabelRow,
  SubTitle
} from './styles'

function CustomerList ({
  customers,
  hasMore,
  totalCustomers,
  onScroll: handleScroll,
  onEdit: handleEdit,
  onDelete: handleDelete
}) {
  const [customerData, setCustomerData] = useState (null)
  const [showDetailsModal, setShowDetailsModal] = useState (false)
  const [showEditCustomerModal, setShowEditCustomerModal] = useState (false)

  const showDetails = data => {
    setCustomerData (data)
    toggleDetailsModal ()
  }

  const editCustomer = data => {
    setCustomerData (data)
    toggleEditCustomerModal ()
  }

  const deleteCustomer = customer => {
    setCustomerData (null)
    setShowDetailsModal (false)
    handleDelete (customer)
  }

  const toggleDetailsModal = () => setShowDetailsModal (!showDetailsModal)
  const toggleEditCustomerModal = () => setShowEditCustomerModal (!showEditCustomerModal)
  
  return (
    <Container>
      <SubTitle>
        {totalCustomers + ' ' + (totalCustomers === 1 ? 'Resultado' : 'Resultados')}
      </SubTitle>
      {customers.length > 0 && (
        <>
          <LabelRow>
            <SubTitle>Nome</SubTitle>
            <SubTitle>E-mail</SubTitle>
            <SubTitle>Contato</SubTitle>
            <SubTitle>Saldo</SubTitle>
            <SubTitle>Ações</SubTitle>
          </LabelRow>
          <InfiniteScroll
            dataLength={customers.length}
            next={handleScroll}
            hasMore={hasMore}
            loader={<Loading/>}
            endMessage={<div style={{height: 50}}></div>}
            style={{overflow: 'visible'}}
          >
            {customers.map ((customer, index) =>
              <Customer
                key={index}
                data={customer}
                onClick={showDetails}
                onEdit={editCustomer}
                onDelete={deleteCustomer}
              />
            )}
          </InfiniteScroll>
          {customerData && (
            <>
              <CustomerModal
                visible={showDetailsModal}
                data={customerData}
                onClose={toggleDetailsModal}
              />
              <EditCustomerModal
                visible={showEditCustomerModal}
                data={customerData}
                onClose={toggleEditCustomerModal}
                onConfirm={customer => handleEdit (customer)}
              />
            </>
          )}
        </>
      )}
    </Container>
  )
}

export default CustomerList
