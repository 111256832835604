import * as Yup from 'yup'

const cutileiReservationValidator = Yup.object ({
  description: Yup
    .object ()
    .nullable ()
    .required ('Selecione uma descrição'),

  startDate: Yup
    .string ()
    .required ('Selecione uma data de início'),

  endDate: Yup
    .string ()
    .required ('Selecione uma data final'),
  
  period: Yup
    .object ()
    .nullable ()
    .required ('Selecione um período'),
})

export default cutileiReservationValidator
