import * as Yup from 'yup'

const deleteBillValidator = Yup.object ({
  delete_reason: Yup
    .string ()
    .required ('Insira o motivo da exclusão')
    .max (64, 'O motivo deve conter até 64 caracteres'),
})

export default deleteBillValidator
