class AdminFinancial {
  static getAdminEarnings (bills) {
    if (bills.length === 0) return 0

    return bills
      .filter (bill => bill.status === 'paid')
      .map (bill => parseFloat (bill.amount))
      .reduce ((total, value) => total + value)
  }

  static getTotalBillEarnings (bills) {
    if (bills.length === 0) return 0

    return bills
      .map (bill => parseFloat (bill.amount))
      .reduce ((total, value) => total + value)
  }
}

export default AdminFinancial
