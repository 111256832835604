import React, { useState, useEffect } from 'react'
import AuthService from '../../../../services/auth'
import cutileiApi from '../../../../services/cutileiApi'
import ScheduleList from '../../../../components/Lists/ScheduleList'
import Select from '../../../../components/Inputs/Select'
import { ReactComponent as Loading } from '../../../../icons/loading2.svg'

import {
  Container,
  FilterContainer
} from '../styles'

function CustomerSchedules ({ customer }) {
  const [loading, setLoading] = useState (true)
  const [page, setPage] = useState (1)
  const [lastPage, setLastPage] = useState (0)
  const [total, setTotal] = useState (0)
  const [schedules, setSchedules] = useState ([])
  const [scheduleType, setScheduleType] = useState ({
    label: 'Todos os agendamentos', value: 'all_schedules'
  })
  const token = AuthService.getToken ()
  const businessId = AuthService.getBusinessId ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getSchedules (1)
  }, [scheduleType])

  const getSchedules = async (pageNumber = page) => {
    if (lastPage && pageNumber > lastPage) return
    let filters = `&business_id=${businessId}&page=${pageNumber}&per_page=25&order=desc&canceled=all`

    if (scheduleType.value === 'cutilei') filters += '&cutilei_schedules=true'
    else if (scheduleType.value === 'business') filters += '&cutilei_schedules=false'

    try {
      setLoading (true)
      
      const { data: schedulesResponse } = await cutileiApi.get (
        `/schedules?customer_id=${customer.id}${filters}`, requestConfig
      )

      const { data, meta } = schedulesResponse

      pageNumber > 1 ? setSchedules ([...schedules, ...data]) : setSchedules (data)
      setPage (pageNumber + 1)
      setLastPage (meta.last_page)
      setTotal (meta.total)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  return (
    <Container style={{paddingInline: 10, paddingTop: 10}}>
      <FilterContainer>
        <Select
          name='scheduleType'
          placeholder='Tipo de agendamento...'
          value={scheduleType}
          options={[
            {label: 'Todos os agendamentos', value: 'all_schedules'},
            {label: 'Agendamentos Cutilei', value: 'cutilei'},
            {label: 'Agendamentos do salão', value: 'business'}
          ]}
          onChange={(_, option) => setScheduleType (option)}
          menuPlacement='bottom'
          containerStyles={{
            width: 260,
            boxShadow: '0px 1px 10px -7px',
            marginBottom: 15,
            borderRadius: 15
          }}
        />
      </FilterContainer>
      {loading ? <Loading style={{marginBottom: 20}}/> : (
        <ScheduleList
          schedules={schedules}
          totalSchedules={total}
          hasMore={!(lastPage && page > lastPage)}
          onScroll={() => getSchedules ()}
          detailed
        />
      )}
    </Container>
  )
}

export default CustomerSchedules
