import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
`

export const FilterContainer = styled(Container)`
  display: grid;
  max-width: 1080px;
  grid-template-columns: 4fr 180px 3fr 140px 4fr;
  height: auto;
  padding: 0px;
  margin-bottom: 20px;
  gap: 10px;
`

export const Row = styled(Container)`
  width: 100%;
  flex-direction: row;
  margin-bottom: 10px;
  padding: 0px;
  gap: 10px;
`

export const Button = styled.button`
  color: #FFFFFF;
  background-color: #606060;
  font-size: 16px;
  height: 40px;
  margin: 0px 0px 8px;
  text-decoration: none;
  border: none;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;

  &:hover {
    background-color: #404040;
  }
`
