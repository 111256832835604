import styled from 'styled-components'

export const Container = styled.div`
  height: 38px;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
`

export const Input = styled.input`
  width: 100%;
  height: 38px;
  border: 0px;
  box-shadow: 0px 2px 10px -8px #252525;
  border-radius: 15px;
  text-align: center;

  &::placeholder {
    color: #BBBBBB
  }

  &:disabled {
    background-color: #FFFFFF;
  }
  
  &:focus {
    border: 1px solid #CCCCCC !important;
    outline: none;
  }
`

export const ArrowContainer = styled.div`
  position: absolute;
  right: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ArrowButton = styled.button.attrs ({
  type: 'button'
})`
  width: 16px;
  height: 16px;
  padding: 0px;
  border: 0px;
  border-radius: 5px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #EFEFEF;
  }
`
