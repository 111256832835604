import * as Yup from 'yup'

const paymentValidator = Yup.object ({
  payments: Yup.array ().of (Yup.object ().shape ({
    value: Yup.number (),
  
    description: Yup
      .string ()
      .max (128, 'A descrição deve ter até 128 caracteres'),
  
    paymentMethod: Yup
      .object ()
      .nullable ()
      .required ('Selecione o método de pagamento'),
  
    bankAccount: Yup
      .object ()
      .nullable (),
  
    cardFlag: Yup
      .object ()
      .nullable ()
      .when ('paymentMethod', {
        is: paymentMethod => (
          ['credit_card', 'debit_card'].includes (paymentMethod?.value.codename)
        ),
        then: schema => schema.required ('Selecione a bandeira')
      }),
  
    installments: Yup.number ().nullable (),
  
    installmentValue: Yup
      .number ()
      .nullable ()
  }))
})

export default paymentValidator
