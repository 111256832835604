import styled from 'styled-components'
import Cleave from 'cleave.js/react'

export const modalStyle = {
  overlay: {
    zIndex: 5
  },
  content: {
    padding: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    overflow: 'visible',
    transform: 'translate(-50%, -50%)',
    borderRadius: 15,
    boxShadow: '0px 2px 15px -8px #252525'
  }
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  width: 580px;
  background-color: #F7F7F7;
  padding: 10px;
  border-radius: 15px;
`

export const FormField = styled(Container)`
  flex: ${props => props.stretch ? props.stretch : 1};
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  margin: 0px;
`

export const Row = styled(Container)`
  width: 100%;
  padding: 0px;
  margin-bottom: 10px;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
`

export const InfoText = styled.p`
  color: #000000;
  font-size: 16px;
  text-align: center;
`

export const Title = styled(InfoText)`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`

export const SubTitle = styled(Title)`
  font-size: 16px;
  margin-bottom: 10px;
`

export const Label = styled.label`
  text-align: center;
  color: #000000;
  margin-bottom: 4px;

  &:hover {
    cursor: pointer;
  }
`

export const ButtonText = styled(SubTitle)`
  color: #FFFFFF;
  margin: 0px;
  font-weight: 200;
`

export const WarningText = styled.p`
  margin: 0px;
  text-align: center;
  font-size: 14px;
  font-weight: 200;
  color: #FF3939;
`

export const Input = styled.input`
  width: 100%;
  height: 38px;
  border: 0px;
  box-shadow: 0px 2px 10px -8px #252525;
  border-radius: 15px;
  text-align: center;
  padding-right: ${({type}) => type === 'date' ? '9px' : '0px'};
  cursor: ${props => props.disabled ? 'not-allowed' : 'text'};

  &::placeholder {
    color: #BBBBBB
  }

  &:disabled {
    background-color: #FFFFFF;
  }
  
  &:focus {
    border: 1px solid #CCCCCC !important;
    outline: none;
  }
`

export const MaskedInput = styled(Cleave)`
  width: 100%;
  height: 38px;
  margin: 0px 5px;
  border: 0px;
  box-shadow: 0px 2px 10px -8px #252525;
  border-radius: 15px;
  text-align: center;
  cursor: ${props => props.disabled ? 'not-allowed' : 'text'};

  &::placeholder {
    color: #BBBBBB
  }

  &:disabled {
    background-color: #FFFFFF;
  }
  
  &:focus {
    border: 1px solid #CCCCCC !important;
    outline: none;
  }
`

export const Button = styled.button`
  width: 200px;
  align-self: center;
  background-color: #252525;
  color: #FFFFFF;
  padding: 8px 20px;
  border: 0px;
  border-radius: 15px;
  text-align: center;
  text-decoration: none;

  &:hover {
    background-color: #121212;
    color: #FFFFFF;
  }
`

export const SmallButton = styled(Button)`
  background-color: #606060;
  width: 60px;
  margin: 2px 0px 10px;

  &:hover {
    background-color: #404040;
  } 
`

export const DangerButton = styled(SmallButton)`
  background-color: #FF3939;
  margin: 0px;

  &:hover {
    background-color: #FF2020;
    color: #FFFFFF;
  }
`

export const CloseButton = styled.button`
  position: fixed;
  top: 5px;
  right: 10px;
  width: 30px;
  height: 38px;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`
