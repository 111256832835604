import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import AuthService from '../../../../services/auth'
import cutileiApi from '../../../../services/cutileiApi'
import AdminNavbar from '../../../../components/AdminNavbar'
import AlertDialog from '../../../../components/AlertDialog'
import { Formik } from 'formik'
import categoryValidator from '../../../../validators/Admin/categoryValidator'
import { ReactComponent as Loading } from '../../../../icons/loading2.svg'
import { ReactComponent as ButtonLoading } from '../../../../icons/loading.svg'
import '../../../../styles/calendar.css'

import {
  Container,
  Form,
  FormField,
  Title,
  Input,
  Button,
  DangerButton,
  Row,
  InfoText,
  WarningText,
  ErrorContainer
} from './styles'

function EditServiceCategory ({ history, match }) {
  const [loading, setLoading] = useState (true)
  const [category, setCategory] = useState (null)
  const [errorMessage, setErrorMessage] = useState (null)
  const [showDeleteDialog, setShowDeleteDialog] = useState (false)
  const [showRestoreDialog, setShowRestoreDialog] = useState (false)
  const [showOkDialog, setShowOkDialog] = useState (false)
  const { id: category_id } = match.params
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getData ()
  }, [])

  const getData = async () => {
    try {
      const { data: category } = await cutileiApi.get (
        `/service_categories/${category_id}`,
        requestConfig
      )

      setCategory (category)
    } catch (error) {
      console.log (error.response)
    } finally {
      setLoading (false)
    }
  }

  const deleteCategory = async () => {
    try {
      await cutileiApi.delete (`/service_categories/${category_id}`, requestConfig)
      history.replace ('/admin/config')
    } catch (error) {
      console.log (error)
    }
  }

  const restoreCategory = async () => {
    try {
      await cutileiApi.post (`/service_categories/${category_id}/reactivate`, {}, requestConfig)
      history.replace ('/admin/config')
    } catch (error) {
      console.log (error)
    }
  }

  const toggleDeleteDialog = () => setShowDeleteDialog (!showDeleteDialog)
  const toggleRestoreDialog = () => setShowRestoreDialog (!showRestoreDialog)
  const toggleOkDialog = () => setShowOkDialog (!showOkDialog)

  return (
    <Container>
      <AdminNavbar/>
      {loading ? <Loading/> : (
        <Formik
          validationSchema={categoryValidator}
          initialValues={{
            'name': category.name,
            'description': category.description ?? ''
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              await cutileiApi.put (`/service_categories/${category_id}`, values, requestConfig)

              setSubmitting (false)
              toggleOkDialog ()
            } catch (error) {
              setSubmitting (false)
              console.log (error)
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Title>Editar categoria de serviço</Title>
              <Row>
                <FormField>
                  <Input 
                    placeholder='Nome'
                    value={values.name}
                    onChange={handleChange ('name')} 
                    onBlur={handleBlur ('name')}
                  />
                  {touched.name && errors.name && (
                    <WarningText>
                      {errors.name}
                    </WarningText>
                  )}
                </FormField>
              </Row>
              <Row>
                <FormField>
                  <Input 
                    placeholder='Descrição'
                    value={values.description}
                    onChange={handleChange ('description')} 
                    onBlur={handleBlur ('description')}
                  />
                  {touched.description && errors.description && (
                    <WarningText>
                      {errors.description}
                    </WarningText>
                  )}
                </FormField>
              </Row>

              {errorMessage && (
                <ErrorContainer>
                  <InfoText>{errorMessage}</InfoText>
                </ErrorContainer>
              )}

              <Button type='submit' disabled={isSubmitting}>
                {isSubmitting ? <ButtonLoading/> : 'Salvar alterações'}
              </Button>

              {category.deleted ? (
                <DangerButton type='button' onClick={toggleRestoreDialog} style={{backgroundColor: '#FFC100'}}>
                  Reativar categoria
                </DangerButton>
              ) : (
                <DangerButton type='button' onClick={toggleDeleteDialog}>
                  Excluir categoria
                </DangerButton>
              )}
            </Form>
          )}
        </Formik>
      )}
      <AlertDialog
        visible={showDeleteDialog}
        title='Atenção!'
        message='Deseja realmente excluir esta categoria?'
        confirmText='Sim'
        cancelText='Não'
        onConfirm={deleteCategory}
        onClose={toggleDeleteDialog}
        dangerous
      />
      <AlertDialog
        visible={showRestoreDialog}
        title='Atenção!'
        message='Deseja realmente reativar esta categoria?'
        confirmText='Sim'
        cancelText='Não'
        onConfirm={restoreCategory}
        onClose={toggleRestoreDialog}
      />
      <AlertDialog
        visible={showOkDialog}
        title='Sucesso'
        message='Alterações salvas!'
        confirmText='Ok'
        onConfirm={() => history.replace ('/admin/config')}
        onClose={() => history.replace ('/admin/config')}
      />
    </Container>
  )
}

export default withRouter (EditServiceCategory)
