import styled from 'styled-components'
import { NumericFormat } from 'react-number-format'

export const modalStyle = {
  overlay: {
    zIndex: 5
  },
  content: {
    paddingTop: 0,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    top: 75,
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    zIndex: 5,
    marginRight: '-50%',
    maxWidth: 1100,
    maxHeight: 'calc(100vh - 150px)',
    transform: 'translate(-50%, 0%)',
    borderRadius: 15,
    boxShadow: '0px 2px 15px -8px #252525',
    backgroundColor: '#F7F7F7'
  }
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
`

export const TabContainer = styled(Container)`
  position: sticky;
  top: 0px;
  background-color: #F7F7F7;
  width: 100%;
  padding: 10px 0px 0px;
  z-index: 5;
`

export const FilterContainer = styled(TabContainer)`
  top: 100px;
  padding: 0px;
`

export const Row = styled(Container)`
  width: 100%;
  padding: 0px;
  gap: 10px;
  flex-direction: row;
  align-items: flex-start;
`

export const Info = styled(Container)`
  padding: 0px;
`

export const FormField = styled(Container)`
  flex: 1;
  justify-content: flex-end;
  padding: 0px;
`

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 10px -6px #252525;
  padding-top: 10px;
  margin-bottom: 10px;
  border-radius: 15px;
  justify-content: center;
  align-items: flex-start;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  width: 720px;
  background-color: transparent;
  border-radius: 15px;
`

export const Button = styled.button`
  color: #FFFFFF;
  background-color: #606060;
  font-size: 14px;
  min-width: 120px;
  height: 38px;
  margin: 0px 0px 8px;
  text-decoration: none;
  border: none;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;

  &:hover {
    background-color: #404040;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  top: 2px;
  right: 0px;
  width: 30px;
  height: 38px;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`

export const TabButton = styled.button`
  color: ${({ selected }) => selected ? '#FFFFFF' : "#000000"};
  background-color: ${({ selected, color }) => selected ? color : '#FFFFFF'};
  font-size: 14px;
  font-weight: bold;
  width: 130px;
  height: 38px;
  margin: 0px 0px 10px;
  border: 0px;
  box-shadow: 0px 2px 10px -6px #252525;
  text-decoration: none;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${props => props.color};
  }
`

export const Input = styled.input`
  width: 100%;
  height: 38px;
  margin: 0px 5px;
  border: 0px;
  box-shadow: 0px 2px 10px -8px #252525;
  border-radius: 15px;
  text-align: center;
  padding-right: ${({type}) => type === 'date' ? '9px' : '0px'};
  cursor: ${props => props.disabled ? 'not-allowed' : 'text'};

  &::placeholder {
    color: #BBBBBB
  }

  &:disabled {
    background-color: #FFFFFF;
  }
  
  &:focus {
    border: 1px solid #CCCCCC !important;
    outline: none;
  }
`

export const MoneyTextMask = styled(NumericFormat).attrs (props => {
  return {
    value: props.value.toFixed (2).replace ('.', ','),
    thousandSeparator: '.',
    decimalSeparator: ',',
    displayType: 'text',
    prefix: 'R$ '
  }
})`
  color: ${({value}) => parseFloat (value.replace (',', '.')) >= 0 ? '#44CF6C' : '#FF3939'};
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0px;
  padding: 0px;
`

export const InfoText = styled.p`
  font-size: 14px;
  text-align: center;
  margin: 0px;
`

export const Title = styled(InfoText)`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
`

export const SubTitle = styled(Title)`
  font-size: 14px;
  margin-bottom: 0px;
`

export const Label = styled(InfoText)`
  margin-bottom: 5px;
  color: #000000;
`

export const WarningText = styled(InfoText)`
  color: #FF3939;
`

export const ErrorContainer = styled.div`
  padding: 9px;
  margin: 15px 0px;
  width: 100%;
  border-radius: 15px;
  align-items: center;
  background-color: #FF3939;
`
