import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  background-color: #FFFFFF;
  width: 100%;
  height: 70px;
  margin: 0px 0px 10px;
  box-shadow: 0px 2px 10px -6px #252525;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

export const NumberCircle = styled.div`
  background-color: #FFC100;
  width: 27px;
  height: 27px;
  border-radius: 99px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    color: #FFFFFF;
    text-align: center;
  }
`

export const Image = styled.img`
  width: 50px;
  height: 50px;
  box-shadow: 0px 2px 10px -8px #252525;
  border-radius: 25px;
`

export const InfoText = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  margin: 0px;
`

export const SubTitle = styled(InfoText)`
  font-weight: bold;
  text-align: left;
`

export const LinkContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`

export const LinkButton = styled(Link)`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  text-decoration: none;
  color: #000000;

  &:hover {
    background-color: #F7F7F7;
    color: #35A7FF;
  }
`

export const Button = styled.button`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  text-decoration: none;
  border: 0px;
  padding: 0px 15px;
  color: #000000;
  background-color: transparent;

  &:hover {
    background-color: #F7F7F7;
    p {color: #35A7FF;}
  }
`

export const DangerButton = styled(Button)`
  width: 40px;
  height: 40px;
  color: #FF3939;

  &:hover {
    background-color: #FF3939;
    color: #FFFFFF;
  }
`
