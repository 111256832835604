import * as Yup from 'yup'

const editServiceValidator = Yup.object ({
  id: Yup.string (),
  nickname: Yup.string (),
  duration: Yup.number (),
  max_delay: Yup.number (),
  price: Yup.number ().positive ('Insira o preço'),
  comission_percentage: Yup.number (),
  professional_comission: Yup.number (),
  business_comission: Yup.number (),
  cutilei_service: Yup.boolean ()
})

export default editServiceValidator
