import React from 'react'
import ProfessionalCredit from './ProfessionalCredit'

import {
  Container,
  SubTitle,
  LabelRow,
  InfoText
} from './styles'

function ProfessionalCreditList ({
  credits,
  type,
  styles,
  onEdit: handleEdit,
  onDelete: handleDelete
}) {
  return (
    <Container style={styles}>
      {credits.length > 0 ? (
        <>
          <LabelRow>
            <SubTitle>Data</SubTitle>
            <SubTitle>Descrição</SubTitle>
            <SubTitle>Valor</SubTitle>
            <SubTitle>Ações</SubTitle>
          </LabelRow>
          {credits.map (credit =>
            <ProfessionalCredit
              key={credit.id}
              data={credit}
              type={type}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          )}
        </>
      ) : (
        <InfoText>
          Nenhum {type === 'bonus' ? 'bônus' : 'desconto'} encontrado
        </InfoText>
      )}
    </Container>
  )
}

export default ProfessionalCreditList
