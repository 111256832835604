import * as Yup from 'yup'

const tipValidator = Yup.object ({
  professional: Yup
    .object ()
    .nullable ()
    .required ('Selecione um profissional'),

  value: Yup
    .number ()
    .positive ('Insira o valor'),

  description: Yup
    .string ()
    .nullable ()
    .max (64, 'A descrição deve conter no máximo 64 caracteres')
})

export default tipValidator
