import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import AuthService from '../../services/auth'

function AdminRoute ({ component: Component, ...rest }) {
  const signed = AuthService.isSigned ()
  const admin = AuthService.isAdmin ()
  
  return (
    <Route
      {...rest}
      render={({ location }) =>
        signed && admin ? (
          <Component/>
        ) : (
          <Redirect 
            to={{
              pathname: '/admin/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}

export default AdminRoute
