import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import cutileiApi from '../../../../services/cutileiApi'
import AuthService from '../../../../services/auth'
import AdminNavbar from '../../../../components/AdminNavbar'
import ProductList from '../../../../components/Lists/ProductList'
import { ReactComponent as Loading } from '../../../../icons/loading2.svg'
import * as FA from 'react-icons/fa'

import {
  ListContainer,
  Row,
  Button
} from '../styles'

function Products ({ history }) {
  const [loading, setLoading] = useState (true)
  const [products, setProducts] = useState ([])
  const token = AuthService.getToken ()
  //const [category, setCategory] = useState (localStorage.getItem ('service_category'))

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getData ()
  }, [])

  const getData = async () => {
    try {
      setLoading (true)

      const { data: products } = await cutileiApi.get ('/products', requestConfig)

      setProducts (products)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  return (
    <ListContainer>
      <AdminNavbar/>
      <Row>
        <Button onClick={() => history.push ('/admin/products/new')}>
          <FA.FaPlus style={{marginRight: 10}}/>
          Novo produto
        </Button>
      </Row>
      {loading ? <Loading style={{marginTop: 30}}/> :
        <ProductList products={products}/>
      }
    </ListContainer>
  )
}

export default withRouter (Products)
