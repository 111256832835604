import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import cutileiApi from '../../../services/cutileiApi'
import AdminNavbar from '../../../components/AdminNavbar'
import BusinessList from '../../../components/Lists/BusinessList'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'

import { Container } from './styles'

function Businesses () {
  const [loading, setLoading] = useState (true)
  const [businesses, setBusinesses] = useState ([])

  useEffect (() => {
    getBusinesses ()
  }, [])

  const getBusinesses = async () => {
    try {
      setLoading (true)
      const { data: businessResponse } = await cutileiApi.get ('/businesses')
      setBusinesses (businessResponse.data)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  return (
    <Container>
      <AdminNavbar/>
      {loading ? <Loading/> :
        <BusinessList businesses={businesses}/>
      }
    </Container>
  )
}

export default withRouter (Businesses)
