import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
`

export const Row = styled(Container)`
  flex-direction: row;
  padding: 0px;
  gap: 10px;
`

export const ListContainer = styled(Container)`
  padding: 15px 0px 30px;
`

export const TabButton = styled.button`
  color: ${({ selected }) => selected ? '#FFFFFF' : "#000000"};
  background-color: ${({ selected, color }) => selected ? color : '#FFFFFF'};
  font-size: 14px;
  font-weight: bold;
  width: 200px;
  height: 38px;
  margin: 0px 0px 10px;
  border: 0px;
  box-shadow: 0px 2px 10px -6px #252525;
  text-decoration: none;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${props => props.color};
  }
`

export const Button = styled.button`
  color: #FFFFFF;
  background-color: #606060;
  font-size: 14px;
  height: 38px;
  margin: 0px 0px 8px;
  text-decoration: none;
  border: none;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;

  &:hover {
    background-color: #404040;
  }
`
