import React from 'react'

import {
  Container,
  SubTitle,
  InfoText,
  MoneyTextMask
} from './styles'

function Product ({
  data: product,
}) {
  return (
    <Container to={{
      pathname: `/admin/products/${product.id}/edit`,
      state: {product}
    }}>
      <SubTitle style={{textAlign: 'left'}}>
        {product.name}
      </SubTitle>
      <InfoText>
        {product.category.name}
      </InfoText>
      <SubTitle style={{color: product.deleted ? '#FF3939' : '#44CF6C'}}>
        {product.deleted ? 'Inativo' : 'Ativo'}
      </SubTitle>
    </Container>
  )
}

export default Product
