import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { NumericFormat } from 'react-number-format'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
`

export const Header = styled.div`
  padding: 12px 20px;
  background-color: #FFFFFF;
  flex-direction: row;
  justify-content: center;
`

export const InfoContainer = styled.div`
  display: flex;
  min-width: 480px;
  width: 640px;
  box-shadow: 0px 2px 10px -6px #252525;
  border-radius: 15px;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 15px !important;
  margin-bottom: 10px;
`

export const LinkContainer = styled(Link)`
  display: flex;
  min-width: 480px;
  width: 640px;
  box-shadow: 0px 2px 10px -6px #252525;
  border-radius: 15px;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 15px !important;
  margin-bottom: 10px;
  text-decoration: none;

  &:hover {
    background-color: #F7F7F7;
  }
`

export const Info = styled(InfoContainer)`
  margin: 0px;
  padding: 0px !important;
  border: 0px;
  box-shadow: none;
  width: 100%;
`

export const FilterContainer = styled(Info)`
  min-width: 480px;
  width: 640px;
  height: auto;
  margin-bottom: 10px;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`

export const InfoText = styled.p`
  color: #000000;
  font-size: 14px;
  text-align: center;
  margin-bottom: 0px;
`

export const Title = styled(InfoText)`
  font-size: 16px;
  font-weight: bold;
  padding: 0px;
`

export const SubTitle = styled(Title)`
  font-size: 14px;
`

export const MoneyTextMask = styled(NumericFormat).attrs (props => {
  return {
    value: props.value.toFixed (2).replace ('.', ','),
    thousandSeparator: '.',
    decimalSeparator: ',',
    displayType: 'text',
    prefix: 'R$ '
  }
})`
  color: #44CF6C;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px;
  padding: 0px;
`

export const LinkButton = styled(Link)`
  color: #35A7FF;
  font-weight: bold;
  text-decoration: none;
  font-size: 14px;

  &:hover {
    color: #35A7FF88;
  }
`
