import styled from 'styled-components'

export const Container = styled.div`
  min-width: 640px;
  width: 100%;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px 10px 30px;
  gap: 15px;
`

export const InfoText = styled.p`
  color: #000000;
  font-size: 14px;
  text-align: center;
`

export const Title = styled(InfoText)`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
`

export const SubTitle = styled(Title)`
  font-size: 14px;
  margin-bottom: 0px;
`

export const ButtonText = styled(InfoText)`
  color: #FFFFFF;
  margin: 0px;
`

export const Input = styled.input`
  width: 100%;
  height: 38px;
  border: 0px;
  box-shadow: 0px 2px 10px -6px #252525;
  border-radius: 15px;
  text-align: center;
  cursor: ${props => props.disabled ? 'not-allowed' : 'text'};

  &::placeholder {
    color: #BBBBBB
  }

  &:disabled {
    background-color: #FFFFFF;
  }
  
  &:focus {
    border: 1px solid #CCCCCC !important;
    outline: none;
  }
`

export const FileContainer = styled.div`
  min-width: 200px;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 10px -6px #252525;
  border-radius: 15px;
  display: flex;
  justify-content: space-between; 
  align-items: center;
  padding: 5px 5px 5px 15px;
  gap: 15px;

  &:hover {
    background-color: #F7F7F7;
  }
`

export const Button = styled.button`
  width: 200px;
  display: flex;
  align-self: center;
  background-color: #252525;
  color: #FFFFFF;
  padding: 8px;
  border: 0px;
  border-radius: 15px;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #121212;
    color: #FFFFFF;
  }
`

export const WarningText = styled(InfoText)`
  color: #FF3939;
`

export const ErrorContainer = styled.div`
  padding: 9px;
  margin: 15px 10px 5px;
  border-radius: 15px;
  align-items: center;
  background-color: #FF3939;
`
