import styled from 'styled-components'
import Cleave from 'cleave.js/react'
import { NumericFormat } from 'react-number-format'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  width: 960px;
  background-color: #F7F7F7;
  box-shadow: 0px 2px 10px -6px #252525;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 20px;
`

export const FormField = styled(Container)`
  flex: ${props => props.stretch ? props.stretch : 1};
  justify-content: flex-end;
  padding: 0px;
  margin: 0px 5px;

  &:first-child {
    margin-left: 0px;
    margin-right: 5px;
  }

  &:last-child {
    margin-left: 5px;
    margin-right: 0px;
  }
`

export const Row = styled(Container)`
  width: 100%;
  padding: 0px;
  margin-bottom: 15px;
  flex-direction: row;
  align-items: flex-start;
`

export const Grid = styled.div`
  display: grid;
  grid-template-rows: 23px repeat(${({ numRows }) => numRows} , 1fr);
  grid-template-columns: 150px repeat(${({ numColumns }) => numColumns} , 1fr) 120px;
  justify-content: center;
  align-items: center;
  row-gap: 3px;
  width: 100%;
  padding: 0px;
  margin-bottom: 8px;
`

export const Image = styled.img`
  width: 20px;
  height: 20px;
  margin: 0px 8px 3px 0px;
`

export const Title = styled.h2`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
  align-self: center;
`;

export const SubTitle = styled.h3`
  font-size: 16px;
  font-weight: 500;
  margin: 5px 0px 15px;
  align-self: center;
`;

export const MoneyText = styled(SubTitle)`
  color: #44CF6C;
`

export const MoneyTextMask = styled(NumericFormat).attrs (props => {
  return {
    value: props.value.toFixed (2).replace ('.', ','),
    thousandSeparator: '.',
    decimalSeparator: ',',
    displayType: 'text',
    prefix: 'R$ '
  }
})`
  color: #44CF6C;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px;
  padding: 0px;
`

export const Label = styled.label`
  text-align: center;
  color: #000000;
  margin: 0px;

  &:hover {
    cursor: pointer;
  }
`

export const GridLabel = styled(Label)`
  margin-bottom: 3px;

  &:hover {
    cursor: default;
  }
`

export const WarningText = styled.p`
  margin: 0px;
  text-align: center;
  font-size: 14px;
  font-weight: 200;
  color: #FF3939;
`

export const ErrorContainer = styled.div`
  padding: 8px;
  margin-bottom: 20px;
  width: 100%;
  border-radius: 15px;
  text-align: center;
  background-color: #FF3939;

  p {
    color: #FFFFFF;
    margin: 0px;
  }
`

export const Button = styled.button`
  width: 200px;
  align-self: center;
  background-color: #252525;
  color: #FFFFFF;
  padding: 8px;
  border: 0px;
  border-radius: 15px;
  text-align: center;
  text-decoration: none;

  &:hover {
    background-color: #121212;
  }
`

export const TabButton = styled.button`
  color: ${({ selected }) => selected ? '#FFFFFF' : "#000000"};
  background-color: ${({ selected, color }) => selected ? color : '#FFFFFF'};
  font-size: 14px;
  font-weight: bold;
  width: 160px;
  height: 38px;
  margin: 0px 0px 10px;
  border: 0px;
  box-shadow: 0px 2px 10px -6px #252525;
  text-decoration: none;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${props => props.color};
  }
`

export const SearchButton = styled.button`
  color: #FFFFFF;
  background-color: #606060;
  height: 40px;
  margin: 0px 0px 8px;
  text-decoration: none;
  border: none;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;

  &:hover {
    background-color: #404040;
  }
`

export const DangerButton = styled(Button)`
  background-color: #FF3939;
  margin-top: 10px;

  &:hover {
    background-color: #FF2020;
    color: #FFFFFF;
  }
`

export const SmallDangerButton = styled.button`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  text-decoration: none;
  margin-left: 7px;
  border: 0px;
  color: #FF3939;
  background-color: transparent;

  &:hover {
    background-color: #FF2020;
    color: #FFFFFF;
  }
`

export const Input = styled.input`
  width: 100%;
  height: 38px;
  margin: 0px 5px;
  border: 0px;
  box-shadow: 0px 2px 10px -8px #252525;
  border-radius: 15px;
  text-align: center;
  cursor: ${props => props.disabled ? 'not-allowed' : 'text'};

  &::placeholder {
    color: #BBBBBB
  }

  &:disabled {
    background-color: #FFFFFF;
  }
  
  &:focus {
    border: 1px solid #CCCCCC !important;
    outline: none;
  }
`

export const MaskedInput = styled(Cleave)`
  width: 100%;
  height: 38px;
  margin: 0px 5px;
  border: 0px;
  box-shadow: 0px 2px 10px -8px #252525;
  border-radius: 15px;
  text-align: center;
  cursor: ${props => props.disabled ? 'not-allowed' : 'text'};

  &::placeholder {
    color: #BBBBBB
  }

  &:disabled {
    background-color: #FFFFFF;
  }
  
  &:focus {
    border: 1px solid #CCCCCC !important;
    outline: none;
  }
`
