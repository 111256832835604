import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import AdminNavbar from '../../../components/AdminNavbar'
import Earnings from './Earnings'
import Expenses from './Expenses'

import { 
  Container,
  TabRow,
  TabButton
} from './styles'

function Financial () {
  const [section, setSection] = useState (localStorage.getItem ('financial_section') || 'earnings')

  const SECTION_LIST = [
    {label: 'Receitas', value: 'earnings'},
    {label: 'Despesas', value: 'expenses'},
  ]

  return (
    <Container>
      <AdminNavbar/>
      <TabRow>
        {SECTION_LIST.map (s =>
          <TabButton
            key={s.value}
            selected={section === s.value}
            color={s.value === 'earnings' ? '#44CF6C' : '#FF3939'}
            onClick={() => {
              setSection (s.value)
              localStorage.setItem ('financial_section', s.value)
            }}
          >
            {s.label}
          </TabButton>
        )}
      </TabRow>
      {section === 'expenses' ? <Expenses/> : <Earnings/>}
    </Container>
  )
}

export default withRouter (Financial)
