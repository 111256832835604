export default class CustomerService {
  static sortCustomersByQuery (customers, query) { 
    return customers.sort ((a, b) => {
      const nameA = a.name.toLowerCase ()
      const nameB = b.name.toLowerCase ()
      const querytoLower = query.toLowerCase ()

      const distanceA = nameA.indexOf (querytoLower) !== -1 ? nameA.indexOf (querytoLower) : Infinity
      const distanceB = nameB.indexOf (querytoLower) !== -1 ? nameB.indexOf (querytoLower) : Infinity

      if (distanceA === distanceB) return nameA.localeCompare (nameB)
      return distanceA - distanceB
    })
  }
}