import React, { useState, useEffect } from 'react'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import BankAccountList from '../../../components/Lists/BankAccountList'
import AddBankAccountModal from '../../Modals/AddBankAccountModal'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'

import {
  Container,
  Button
} from '../styles'

function ConfigBankAccounts () {
  const [loading, setLoading] = useState (true)
  const [businessBankAccounts, setBusinessBankAccounts] = useState ([])
  const [showAddBankAccountModal, setShowAddBankAccountModal] = useState (false)
  const business = AuthService.getUser ()
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getData ()
  }, [])

  const getData = async () => {
    try {
      setLoading (true)
      const { data: bankAccounts } = await cutileiApi.get (
        `/businesses/${business.id}/bank_accounts`, requestConfig
      )

      setBusinessBankAccounts (bankAccounts)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  const toggleAddBankAccountModal = () => setShowAddBankAccountModal (!showAddBankAccountModal)

  return (
    <Container>
      <Button style={{marginBottom: 20}} onClick={toggleAddBankAccountModal}>
        Nova conta bancária
      </Button>
      {loading ? <Loading/> :
        <BankAccountList
          bankAccounts={businessBankAccounts}
          onEdit={bankAccount => 
            setBusinessBankAccounts (businessBankAccounts.map (ba => 
              ba.id === bankAccount.id ? {...bankAccount} : ba
            ))
          }
          onDelete={bankAccount => setBusinessBankAccounts (
            businessBankAccounts.filter (ba => ba.id !== bankAccount.id)
          )}
        />
      }
      <AddBankAccountModal
        visible={showAddBankAccountModal}
        onConfirm={bankAccount => setBusinessBankAccounts (
          [...businessBankAccounts, bankAccount]
        )}
        onClose={toggleAddBankAccountModal}
      />
    </Container>
  )
}

export default ConfigBankAccounts
