import React, { useState } from 'react'
import cutileiApi from '../../../../services/cutileiApi'
import AlertDialog from '../../../AlertDialog'
import AuthService from '../../../../services/auth'
import MoneyInput from '../../../Inputs/MoneyInput'
import { ReactComponent as ButtonLoading } from '../../../../icons/loading2.svg'
import earningAndExpenseValidator from '../../../../validators/earningAndExpenseValidator'
import { Formik } from 'formik'
import { DateTime } from 'luxon'
import * as FA from 'react-icons/fa'

import {
  Container,
  FormField,
  Row,
  Input,
  InfoText,
  MoneyTextMask,
  Button,
  WarningText
} from './styles'

function  ProfessionalCredit ({
  data: credit,
  type,
  onEdit: handleEdit,
  onDelete: handleDelete
}) {
  const [editting, setEditting] = useState (false)
  const [showAlertDialog, setShowAlertDialog] = useState (false)
  const [errorMessage, setErrorMessage] = useState (null)
  const [deleting, setDeleting] = useState (false)
  const url = `/${type === 'bonus' ? 'credits' : 'debts'}/${credit.id}`
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const deleteCredit = async () => {
    setDeleting (true)
    try {
      await cutileiApi.delete (url, requestConfig)
      setDeleting (false)
      handleDelete (credit)
    } catch (error) {
      setDeleting (false)
      console.log (error)
    }
  }

  const toggleEditting = () => setEditting (!editting)
  const toggleAlertDialog = () => setShowAlertDialog (!showAlertDialog)

  return editting ? (
    <Formik
      validationSchema={earningAndExpenseValidator}
      initialValues={{
        'date': credit.date,
        'description': credit.description,
        'value': Math.abs (credit.value)
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const { data: editedCredit } = await cutileiApi.put (url, values, requestConfig)

          setSubmitting (false)
          setEditting (false)
          handleEdit ({
            ...editedCredit,
            value: editedCredit.value * (credit.type === 'bonus' ? 1 : -1)
          })
        } catch (error) {
          if (error.response.data) setErrorMessage (error.response.data.message)
          setSubmitting (false)
          console.log (error)
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        handleChange,
        handleBlur, 
        handleSubmit
      }) => (
        <Container editting={editting}>
          <FormField>
            <Input 
              type='date'
              placeholder='Data referente'
              value={values.date}
              onChange={handleChange ('date')} 
              onBlur={handleBlur ('date')}
            />
            {touched.date && errors.date && (
              <WarningText>
                {errors.date}
              </WarningText>
            )}
          </FormField>
          <FormField>
            <Input
              type='text'
              name='description'
              placeholder='Descrição'
              value={values.description}
              onChange={handleChange ('description')}
              onBlur={handleBlur ('description')}
              style={{width: '100%'}}
            />
            {touched.description && errors.description && (
              <WarningText>
                {errors.description}
              </WarningText>
            )}
          </FormField>
          <FormField>
            <MoneyInput
              name='value'
              placeholder='Valor'
              value={values.value}
              onValueChange={setFieldValue}
              onBlur={handleBlur ('value')}
              style={{width: '100%'}}
            />
            {touched.value && errors.value && (
              <WarningText>
                {errors.value}
              </WarningText>
            )}
          </FormField>
          <Row>
            <Button type='button' onClick={handleSubmit} disabled={isSubmitting}>
              {isSubmitting ? (
                <ButtonLoading style={{margin: 0}}/>
              ) : (
                <FA.FaSave color='#35A7FF' size={16}/>
              )}
            </Button>
            <Button type='button' onClick={toggleEditting}>
              <FA.FaTimes color='#FF3939' size={18}/>
            </Button>
          </Row>
        </Container>
      )}
    </Formik>
  ) : (
    <Container edditing={editting}>
      <InfoText>
        {DateTime.fromISO (credit.date).toFormat ('dd/MM/yyyy')}
      </InfoText>
      <InfoText>
        {credit.description}
      </InfoText>
      <MoneyTextMask value={credit.value} style={{justifySelf: 'center'}}/>
      <Row>
        <Button onClick={toggleEditting}>
          <FA.FaEdit color='#35A7FF' size={18}/>
        </Button>
        <Button onClick={toggleAlertDialog}>
          {deleting ? (
            <ButtonLoading style={{margin: 0}}/>
          ) : (
            <FA.FaTrash color='#FF3939' size={16}/>
          )}
        </Button>
      </Row>
      <AlertDialog
        visible={showAlertDialog}
        title='Atenção!'
        message={
          `Deseja realmente excluir ${type === 'bonus' ? 'este bônus' : 'esta desconto'}?`
        }
        confirmText='Sim'
        cancelText='Não'
        onConfirm={deleteCredit}
        onClose={toggleAlertDialog}
        dangerous
      />
    </Container>
  )
}

export default ProfessionalCredit
