import React from 'react'
import Member from './Member'

import {
  Container,
  SubTitle
} from './styles'

function MemberList (props) {
  const members = props.members

  return (
    <Container>
      <SubTitle>
        {members.length + ' ' + (members.length === 1 ? 'Resultado' : 'Resultados')}
      </SubTitle>
      {members.map (member =>
        <Member
          key={member.id}
          data={member}
        />
      )}
    </Container>
  )
}

export default MemberList
