import styled from 'styled-components'

export const modalStyle = {
  overlay: {
    zIndex: 5
  },
  content: {
    padding: 0,
    width: 800,
    top: 80,
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    paddingBottom: 15,
    overflow: 'visible',
    maxHeight: 'calc(100vh - 200px)',
    transform: 'translate(-50%, 0%)',
    borderRadius: 15,
    boxShadow: '0px 2px 15px -8px #252525'
  }
}

export const Container = styled.div`
  max-height: 600px;
  width: 800px;
  background: #FFFFFF;
  border-radius: 15px;
  overflow: hidden;
  padding: 15px 15px 0px 15px;
  justify-content: center;
  align-items: center;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
`

export const Content = styled.div`
  width: 100%;
  max-height: 450px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
`

export const InfoText = styled.p`
  color: #000000;
  font-size: 16px;
  text-align: center;
`

export const Title = styled(InfoText)`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
`

export const SubTitle = styled(Title)`
  font-size: 16px;
`

export const ButtonText = styled(SubTitle)`
  color: #FFFFFF;
  margin: 0px;
  font-weight: 200;
`

export const Input = styled.input`
  width: 100%;
  height: 38px;
  margin: 0px 5px;
  border: 0px;
  box-shadow: 0px 2px 10px -6px #252525;
  border-radius: 15px;
  text-align: center;
  padding-right: ${({type}) => type === 'date' ? '9px' : '0px'};

  &::placeholder {
    color: #BBBBBB
  }
  
  &:focus {
    border: 1px solid #CCCCCC !important;
    outline: none;
  }
`

export const Button = styled.button`
  background-color: #252525;
  color: #F7F7F7;
  width: 100px;
  padding: 8px;
  border-radius: 15px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 10px;
  font-size: 14px;
  border: none;

  &:hover {
    background-color: #252525;
    color: #F7F7F7;
  }
`

export const SearchButton = styled.button`
  color: #FFFFFF;
  background-color: #606060;
  height: 40px;
  margin: 0px 0px 8px;
  text-decoration: none;
  border: none;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;

  &:hover {
    background-color: #404040;
  }
`

export const CloseButton = styled.button`
  position: fixed;
  top: 5px;
  right: 10px;
  width: 30px;
  height: 38px;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`
