import React from 'react'
import Phone from '../../../../services/Phone'

import {
  Container,
  SubTitle,
  InfoText,
  Row
} from '../styles'

function CustomerPersonalInfo ({ customer }) {
  return (
    <Container style={{marginBottom: 20}}>
      <Row>
        <InfoText>Nome:</InfoText>
        <SubTitle>{customer.name}</SubTitle>
      </Row>
      <Row>
        <InfoText>E-mail:</InfoText>
        <SubTitle>{customer.email}</SubTitle>
      </Row>
      <Row>
        <InfoText>Celular:</InfoText>
        <SubTitle>{Phone.formatPhone (customer.phone)}</SubTitle>
      </Row>
    </Container>
  )
}

export default CustomerPersonalInfo
