import React, { useState } from 'react'
import cutileiApi from '../../../../services/cutileiApi'
import AuthService from '../../../../services/auth'
import AlertDialog from '../../../AlertDialog'
import { ReactComponent as ButtonLoading } from '../../../../icons/loading2.svg'
import * as FA from 'react-icons/fa'

import {
  Container,
  Row,
  Image,
  NumberCircle,
  SubTitle,
  InfoText,
  LinkContainer,
  Button,
  LinkButton,
  DangerButton
} from './styles'

function Professional ({
  data: professional,
  onDelete: handleDelete,
  onRestore: handleRestore
}) {
  const token = AuthService.getToken ()
  const [showDeleteDialog, setShowDeleteDialog] = useState (false)
  const [showReactivateDialog, setShowReactivateDialog] = useState (false)
  const [showRestoreDialog, setShowRestoreDialog] = useState (false)
  const [deleting, setDeleting] = useState (false)
  const [retoring, setRestoring] = useState (false)

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const deleteProfessional = async () => {
    setDeleting (true)
    try {
      await cutileiApi.delete (
        `/professionals/${professional.id}`,
        requestConfig
      )
      setDeleting (false)
      handleDelete (professional)
    } catch (error) {
      setDeleting (false)
      console.log (error)
    }
  }

  const reactivateProfessional = async () => {
    setRestoring (true)
    try {
      await cutileiApi.put (
        `/professionals/${professional.id}/activation`, {active: true}, requestConfig
      )
      setRestoring (false)
      handleRestore ({...professional, active: true})
    } catch (error) {
      setRestoring (false)
      console.log (error)
    }
  }

  const restoreProfessional = async () => {
    setRestoring (true)
    try {
      await cutileiApi.post (
        `/professionals/${professional.id}/restore`, {}, requestConfig
      )
      setRestoring (false)
      handleRestore ({...professional, active: true})
    } catch (error) {
      setRestoring (false)
      console.log (error)
    }
  }

  const toggleDeleteDialog = () => setShowDeleteDialog (!showDeleteDialog)
  const toggleReactivateDialog = () => setShowReactivateDialog (!showReactivateDialog)
  const toggleRestoreDialog = () => setShowRestoreDialog (!showRestoreDialog)

  return (
    <Container>
      <Row>
        <NumberCircle>
          <SubTitle>
            {professional.number}
          </SubTitle>
        </NumberCircle>
        <Image src={professional.profile_picture}/>
        <InfoText>
          {professional.name}
        </InfoText>
      </Row>
      {!professional.deleted ? (
        <Row>
          <SubTitle style={{
            color: professional.active ? '#44CF6C' : '#FF3939',
            justifySelf: 'flex-end',
            marginRight: '5px'
          }}>
            {professional.active ? 'Ativo' : 'Inativo'}
          </SubTitle>
          <LinkContainer>
            {!professional.active && (
              <Button onClick={toggleReactivateDialog}>
                {retoring ? (
                  <ButtonLoading/>
                ) : (
                  <SubTitle>Reativar</SubTitle>
                )}
              </Button>
            )}
            <LinkButton to={`/business/professionals/${professional.id}/edit`}>
              <FA.FaPencilAlt/>
            </LinkButton>
            <DangerButton onClick={toggleDeleteDialog}>
              {deleting ? (
                <ButtonLoading style={{margin: 0}}/>
              ) : (
                <FA.FaTrash/>
              )}
            </DangerButton>
          </LinkContainer>
        </Row>
      ) : (
        <Row>
          <Button onClick={toggleRestoreDialog}>
            {retoring ? (
              <ButtonLoading/>
            ) : (
              <SubTitle>Restaurar</SubTitle>
            )}
          </Button>
        </Row>
      )}
      <AlertDialog
        visible={showDeleteDialog}
        title='Atenção!'
        message='Deseja realmente excluir este profissional?'
        confirmText='Sim'
        cancelText='Não'
        onConfirm={deleteProfessional}
        onClose={toggleDeleteDialog}
        dangerous
      />
      <AlertDialog
        visible={showReactivateDialog}
        title='Atenção!'
        message='Deseja realmente reativar este profissional na agenda?'
        confirmText='Sim'
        cancelText='Não'
        onConfirm={reactivateProfessional}
        onClose={toggleReactivateDialog}
      />
      <AlertDialog
        visible={showRestoreDialog}
        title='Atenção!'
        message='Deseja realmente restaurar este profissional?'
        confirmText='Sim'
        cancelText='Não'
        onConfirm={restoreProfessional}
        onClose={toggleRestoreDialog}
      />
    </Container>
  )
}

export default Professional
