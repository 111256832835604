import * as Yup from 'yup'

const bankAccountValidator = Yup.object ({
  bank: Yup.object ().nullable ().required ('Selecione o banco'),
  description: Yup.string ()
    .required ('Digite uma descrição para a conta')
    .max (64, 'A descrição conter até 64 caracteres')
})

export default bankAccountValidator
