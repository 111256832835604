import React, { useState, useEffect, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import AuthService from '../../services/auth'
import cutileiApi from '../../services/cutileiApi'
import TimeWindow from '../../services/timeWindow'
import viaCepApi from '../../services/viaCepApi'
import MaskedInputService from '../../services/maskedInput'
import NumberInput from '../../components/Inputs/NumberInput'
import Navbar from '../../components/Navbar'
import Select from '../../components/Inputs/Select'
import CheckBox from '../../components/CheckBox'
import PasswordInput from '../../components/Inputs/PasswordInput'
import ProfessionalServicesModal from '../Modals/ServicesModal/Professional'
import AlertDialog from '../../components/AlertDialog'
import ReactTooltip from 'react-tooltip'
import { Formik } from 'formik'
import createProfessionalValidator from '../../validators/createProfessionalValidator'
import { ReactComponent as Loading } from '../../icons/loading2.svg'
import { ReactComponent as ButtonLoading } from '../../icons/loading.svg'
import Logo from '../../icons/logo-cutilei-simple.png'
import * as FA from 'react-icons/fa'
import { DateTime } from 'luxon'

import {
  Container,
  Form,
  Grid,
  Button,
  Image,
  Title,
  SubTitle,
  MoneyTextMask,
  FormField,
  Label,
  GridLabel,
  WarningText,
  ErrorContainer,
  Input,
  MaskedInput,
  DangerButton,
  Row
} from './styles'

function AddProfessional ({ history }) {
  require ('cleave.js/dist/addons/cleave-phone.br')

  const [loading, setLoading] = useState (true)
  const [loadingAddress, setLoadingAddress] = useState (false)
  const [accessLevels, setAccessLevels] = useState ([])
  const [cutileiServices, setCutileiServices] = useState ([])
  const [serviceCategories, setServiceCategories] = useState ([])
  const [categoryToRemove, setCategoryToRemove] = useState (null)
  const [businessWorkingDays, setBusinessWorkingDays] = useState ([])
  const [workStartTimeWindows, setWorkStartTimeWindows] = useState ([])
  const [workEndTimeWindows, setWorkEndTimeWindows] = useState ([])
  const [cutileiStartTimeWindows, setCutileiStartTimeWindows] = useState ({})
  const [cutileiEndTimeWindows, setCutileiEndTimeWindows] = useState ({})
  const [addressFound, setAddressFound] = useState (false)
  const [errorMessage, setErrorMessage] = useState (null)
  const [showServicesModal, setShowServicesModal] = useState (false)
  const [showAlertDialog, setShowAlertDialog] = useState (false)
  const [showRemoveDialog, setShowRemoveDialog] = useState (false)
  const [showErrorDialog, setShowErrorDialog] = useState (false)
  const [createdProfessional, setCreatedProfessional] = useState (null)
  const [showOkDialog, setShowOkDialog] = useState (false)
  const business = AuthService.getUser ()
  const token = AuthService.getToken ()

  useEffect (() => {
    getData ()
  }, [])

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const getData = async () => {
    try {
      const { data: accessLevels } = await cutileiApi.get ('/access_levels')
      const { data: businessWorkingDays } = await cutileiApi.get (`/businesses/${business.id}/working_days`)
      const { data: serviceCategories } = await cutileiApi.get ('/service_categories', requestConfig)
      const { data: cutileiServices } = await cutileiApi.get (
        `/businesses/${business.id}/services?status=active&cutilei_services=true`, requestConfig
      )

      const timeWindows = {...businessWorkingDays.map (day => 
        TimeWindow.getTimeWindows (day.opening, day.closing)
      )}
      const cutileiTimeWindows = {...businessWorkingDays.map (day => day.accepts_cutilei
        ? TimeWindow.getTimeWindows (day.cutilei_opening, day.cutilei_closing)
        : TimeWindow.getTimeWindows (day.opening, day.closing)
      )}

      setAccessLevels (accessLevels)
      setServiceCategories (serviceCategories)
      setCutileiServices (cutileiServices)
      setBusinessWorkingDays (businessWorkingDays)
      setWorkStartTimeWindows (timeWindows)
      setWorkEndTimeWindows (timeWindows)
      setCutileiStartTimeWindows (cutileiTimeWindows)
      setCutileiEndTimeWindows (cutileiTimeWindows)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  const searchAddress = async (event, setFieldValue) => {
    const postal_code = event.target.rawValue

    setFieldValue ('postal_code', postal_code)
    
    if (postal_code.length === 8) {
      setLoadingAddress (true)
      try {
        const { data } = await viaCepApi.get (`/ws/${postal_code}/json`)

        setFieldValue ('route', data.logradouro)
        setFieldValue ('district', data.bairro)
        setFieldValue ('city', data.localidade)
        setFieldValue ('state', data.uf)

        setAddressFound (true)
      } catch (error) {
        console.log (error.response)
      } finally {
        setLoadingAddress (false)
      }
    }
  }

  const handleTimeSelected = (name, option, workingDays, setFieldValue) => {
    const dayIndex = parseInt (name.split ('.')[1])
    const businessClosing = DateTime.fromISO (businessWorkingDays[dayIndex].closing).toFormat ('H:mm')
    const businessOpening = DateTime.fromISO (businessWorkingDays[dayIndex].opening).toFormat ('H:mm')

    if (name.includes ('work_start')) {
      setWorkEndTimeWindows ({
        ...workEndTimeWindows,
        [dayIndex]: TimeWindow.getTimeWindows (option.value, businessClosing)
      })
      setCutileiStartTimeWindows ({
        ...cutileiStartTimeWindows,
        [dayIndex]: TimeWindow.getTimeWindows (
          option.value, workingDays[dayIndex].work_end.value || businessClosing
        )
      })
    } else if (name.includes ('work_end')) {
      setWorkStartTimeWindows ({
        ...workStartTimeWindows,
        [dayIndex]: TimeWindow.getTimeWindows (businessOpening, option.value)
      })
      setCutileiEndTimeWindows ({
        ...cutileiEndTimeWindows,
        [dayIndex]: TimeWindow.getTimeWindows (
          workingDays[dayIndex].work_start.value || businessOpening, option.value
        )
      })
    }

    setFieldValue ('working_days', workingDays.map ((day, index) => ({
      ...day,
      work_start: name.includes ('work_start') && !day.work_start
        ? option : index === dayIndex && name.includes ('work_start')
          ? option : day.work_start,
      work_end: name.includes ('work_end') && !day.work_end
        ? option : index === dayIndex && name.includes ('work_end')
          ? option : day.work_end
    })))
  }

  const handleCutileiTimeSelected = (name, option, workingDays, setFieldValue) => {
    const dayIndex = parseInt (name.split ('.')[1])
    const lastTimeWindowIndex = cutileiEndTimeWindows[dayIndex].length -1

    if (name.includes ('cutilei_opening')) {
      setCutileiEndTimeWindows ({
        ...cutileiEndTimeWindows,
        [dayIndex]: TimeWindow.getTimeWindows (
          option.value, cutileiEndTimeWindows[dayIndex][lastTimeWindowIndex].value
        )
      })
    } else if (name.includes ('cutilei_closing')) {
      setCutileiStartTimeWindows ({
        ...cutileiStartTimeWindows,
        [dayIndex]: TimeWindow.getTimeWindows (
          cutileiStartTimeWindows[dayIndex][0].value, option.value
        )
      })
    }

    setFieldValue ('working_days', workingDays.map ((day, index) => ({
      ...day,
      cutilei_start: name.includes ('cutilei_start') && day.cutilei_start === ''
        ? option : index === dayIndex && name.includes ('cutilei_start')
          ? option : day.cutilei_start,
      cutilei_end: name.includes ('cutilei_end') && day.cutilei_end === ''
        ? option : index === dayIndex && name.includes ('cutilei_end')
          ? option : day.cutilei_end
    })))
  }

  const removeServiceCategory = (category, values, setFieldValue) => {
    if (category.name.toLowerCase () === 'manicure e pedicure') {
      const editedWorkingDays = values.working_days.map (day => ({...day, accepts_cutilei: false}))

      setFieldValue ('working_days', editedWorkingDays)
    }

    setFieldValue ('service_categories', values.service_categories.filter (c => c.id !== category.id))
  }

  const handleServicesAdded = (addedServices, serviceCategories, values, setFieldValue) => {
    const uniqueCategories = new Set (values.service_categories.map (c => JSON.stringify (c)))

    serviceCategories.forEach (c => uniqueCategories.add (JSON.stringify (c)))
    const updatedCategories = Array.from (uniqueCategories).map (c => JSON.parse (c))

    setFieldValue ('services', [
      ...values.services,
      ...addedServices.map (s => ({
        ...s, comission_percentage: s.comission_percentage * 100
      }))
    ])
    setFieldValue ('service_categories', updatedCategories)
  }

  const handleServiceDeleted = (deletedService, values, setFieldValue) => {
    const updatedServices = values.services.filter (s => s.id !== deletedService.id)
    const serviceCategory = deletedService.category

    setFieldValue ('services', updatedServices)

    if (!updatedServices.some (s => s.category.id === serviceCategory.id))
      removeServiceCategory (serviceCategory, values, setFieldValue)
  }

  const toggleServicesModal = () => setShowServicesModal (!showServicesModal)
  const toggleAlertDialog = () => setShowAlertDialog (!showAlertDialog)
  const toggleRemoveDialog = () => setShowRemoveDialog (!showRemoveDialog)
  const toggleErrorDialog = () => setShowErrorDialog (!showErrorDialog)
  const toggleOkDialog = () => setShowOkDialog (!showOkDialog)

  return (
    <Container>
      <Navbar/>
      {loading ? <Loading/> : (
        <Formik
          validationSchema={createProfessionalValidator}
          initialValues={{
            'name': '',
            'nickname': '',
            'cpf': '',
            'rg': '',
            'order': null,
            'email': '',
            'birth': '',
            'gender': null,
            'phone': '',
            'access_level': null,
            'password': '',
            'password_confirmation': '',
            'route': '',
            'number': '',
            'complement': '',
            'district': '',
            'city': '',
            'state': '',
            'postal_code': '',
            'service_categories': [],
            'services': [],
            'working_days': businessWorkingDays.map ((day, index) => {
              const work_start = DateTime.fromISO (businessWorkingDays[index].opening).toFormat ('H:mm')
              const work_end = DateTime.fromISO (businessWorkingDays[index].closing).toFormat ('H:mm')
              const cutilei_start = DateTime.fromISO (businessWorkingDays[index].cutilei_opening).toFormat ('H:mm')
              const cutilei_end = DateTime.fromISO (businessWorkingDays[index].cutilei_closing).toFormat ('H:mm')

              return { 
                ...day,
                work_start: { label: work_start, value: work_start },
                work_end: { label: work_end, value: work_end },
                cutilei_start: { label: cutilei_start, value: cutilei_start },
                cutilei_end: { label: cutilei_end, value: cutilei_end },
                selected: true
              } 
            })
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const {
              name,
              nickname,
              cpf,
              rg,
              order,
              email,
              birth,
              gender,
              phone,
              access_level,
              password,
              password_confirmation,
              service_categories,
              services: _services,
              working_days: _working_days,
              ...address
            } = values

            setErrorMessage (null)
            setSubmitting (true)

            try {
              const services = _services.map (service => ({
                ...service, comission_percentage: service.comission_percentage / 100
              }))

              const working_days = _working_days.filter (day => day.selected).map (day => ({
                ...day,
                work_start: day.work_start.value,
                work_end: day.work_end.value,
                cutilei_start: day.cutilei_start.value,
                cutilei_end: day.cutilei_end.value
              }))

              const { data: professional } = await cutileiApi.post (
                `/businesses/${business.id}/professionals`, {
                  name,
                  nickname,
                  cpf,
                  rg,
                  number: order,
                  email,
                  birth: DateTime.fromFormat (birth, 'ddMMyyyy').toISODate (),
                  gender: gender.value,
                  phone,
                  access_level_id: access_level.value.id,
                  password,
                  password_confirmation,
                  address
                }, requestConfig
              )

              if (access_level.value.codename === 'service') {
                await cutileiApi.post (
                  `/professionals/${professional.id}/working_days`,
                  {working_days},
                  requestConfig
                )
  
                await cutileiApi.post (
                  `/professionals/${professional.id}/service_categories`,
                  {service_categories},
                  requestConfig
                )
  
                await cutileiApi.post (
                  `/professionals/${professional.id}/services`,
                  {services},
                  requestConfig
                )
              }

              setSubmitting (false)
              setCreatedProfessional (professional)
              toggleOkDialog ()
            } catch (error) {
              setSubmitting (false)
              console.log (error.response.data)
            }
          }}
        >
          {function AddProfessionalForm ({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            handleChange,
            handleBlur,
            handleSubmit
          }) {
            useEffect (() => {
              if (!values.service_categories.some (c => c.name.toLowerCase () === 'manicure e pedicure')) {
                setFieldValue ('services', values.services.filter (service => 
                  !cutileiServices.some (s => s.category.id === service.category.id)
                ))
              } else if (!values.services.some (s => s.cutilei_service === true)) {
                setFieldValue ('services', [
                  ...cutileiServices.map (service => ({
                    ...service, comission_percentage: service.comission_percentage * 100,
                  })),
                  ...values.services
                ])
              }
            }, [values.service_categories])

            const handleCategoriesAdded = (name, categories) => {
              setFieldValue (name, categories.map (c => c.value))
            }

            return (
              <Form onSubmit={handleSubmit}>
                <Title>Cadastrar profissional</Title>
                <SubTitle>Dados pessoais</SubTitle>
                <Row>
                  <FormField stretch={2}>
                    <Input
                      placeholder='Nome completo'
                      value={values.name}
                      onChange={handleChange ('name')}
                      onBlur={handleBlur ('name')}
                    />
                    {touched.name && errors.name && (
                      <WarningText>{errors.name}</WarningText>
                    )}
                  </FormField>
                  <FormField stretch={1}>
                    <Input
                      placeholder='Nome na agenda'
                      value={values.nickname}
                      onChange={handleChange ('nickname')}
                      onBlur={handleBlur ('nickname')}
                      data-for='nickname'
                      data-tip
                    />
                    <ReactTooltip id='nickname' effect='solid' backgroundColor='#252525' tooltipRadius='10'>
                      <Label style={{color: '#FFFFFF'}}>
                        Este é o nome que aparecerá na agenda do salão e para os clientes. <br/>
                        Se o campo não for preenchido, o primeiro nome será usado.
                      </Label>
                    </ReactTooltip>
                    {touched.nickname && errors.nickname && (
                      <WarningText>{errors.nickname}</WarningText>
                    )}
                  </FormField>
                  <FormField>
                    <MaskedInput
                      name='cpf'
                      placeholder='CPF'
                      options={{
                        blocks: [3,3,3,2],
                        delimiters: ['.', '.', '-'],
                        numericOnly: true
                      }}
                      value={values.cpf}
                      onChange={event => MaskedInputService.handleChange (event, setFieldValue)}
                      onBlur={handleBlur ('cpf')}
                    />
                    {touched.cpf && errors.cpf && (
                      <WarningText>{errors.cpf}</WarningText>
                    )}
                  </FormField>
                  <FormField>
                    <Input
                      placeholder='RG (opcional)'
                      value={values.rg}
                      onChange={handleChange ('rg')}
                      onBlur={handleBlur ('rg')}
                    />
                    {touched.rg && errors.rg && (
                      <WarningText>{errors.rg}</WarningText>
                    )}
                  </FormField>
                  <FormField>
                    <NumberInput
                      name='order'
                      placeholder='Ordem'
                      value={values.order}
                      minValue={1}
                      onChange={setFieldValue}
                    />
                  </FormField>
                </Row>
                <Row>
                  <FormField stretch={2}>
                    <Input
                      type='email'
                      placeholder='E-mail'
                      value={values.email}
                      onChange={handleChange ('email')}
                      onBlur={handleBlur ('email')}
                    />
                    {touched.email && errors.email && (
                      <WarningText>{errors.email}</WarningText>
                    )}
                  </FormField>
                  <FormField>
                    <MaskedInput
                      name='birth'
                      placeholder='Nascimento'
                      options={{date: true, datePattern: ['d', 'm', 'Y']}}
                      value={values.birth}
                      onChange={event => MaskedInputService.handleChange (event, setFieldValue)}
                      onBlur={handleBlur ('birth')}
                    />
                    {touched.birth && errors.birth && (
                      <WarningText>{errors.birth}</WarningText>
                    )}
                  </FormField>
                  <FormField>
                    <Select
                      name='gender'
                      placeholder='Gênero...'
                      value={values.gender}
                      options={[
                        {label: 'Masculino', value: 'Masculino'},
                        {label: 'Feminino', value: 'Feminino'},
                        {label: 'Não informar', value: 'Não informar'}
                      ]}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      error={errors.gender}
                      touched={touched.gender}
                    />
                    {touched.gender && errors.gender && (
                      <WarningText>{errors.gender}</WarningText>
                    )}
                  </FormField>
                  <FormField>
                    <MaskedInput
                      name='phone'
                      placeholder='Celular'
                      options={{
                        blocks: [0, 2, 5, 4],
                        delimiters: ['(', ') ', '-'],
                        numericOnly: true,
                      }}
                      value={values.phone}
                      onChange={event => MaskedInputService.handleChange (event, setFieldValue)}
                      onBlur={handleBlur ('phone')}
                    />
                    {touched.phone && errors.phone && (
                      <WarningText>{errors.phone}</WarningText>
                    )}
                  </FormField>
                </Row>
                <SubTitle>Segurança</SubTitle>
                <Row>
                  <FormField data-for='access_level_tip' data-tip>
                    <Select
                      name='access_level'
                      placeholder='Nível de acesso...'
                      value={values.access_level}
                      options={accessLevels.map (level => ({ label: level.name, value: level }))}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      error={errors.access_level}
                      touched={touched.access_level}
                    />
                    {values.access_level && 
                      <ReactTooltip id='access_level_tip' effect='solid' backgroundColor='#252525' tooltipRadius='10'>
                        <Label style={{color: '#FFFFFF'}}>
                          {values.access_level?.value.description}
                        </Label>
                      </ReactTooltip>
                    }
                    {touched.access_level && errors.access_level && (
                      <WarningText>{errors.access_level}</WarningText>
                    )}
                  </FormField>
                  <FormField>
                    <PasswordInput
                      placeholder='Senha'
                      value={values.password}
                      onChange={handleChange ('password')}
                      onBlur={handleBlur ('password')}
                    />
                    {touched.password && errors.password && (
                      <WarningText>{errors.password}</WarningText>
                    )}
                  </FormField>
                  <FormField>
                    <PasswordInput
                      placeholder='Confirmar senha'
                      value={values.password_confirmation}
                      onChange={handleChange ('password_confirmation')}
                      onBlur={handleBlur ('password_confirmation')}
                    />
                    {touched.password_confirmation && errors.password_confirmation && (
                      <WarningText>{errors.password_confirmation}</WarningText>
                    )}
                  </FormField>
                </Row>
                <SubTitle>Endereço</SubTitle>
                <Row>
                  <FormField>
                    <MaskedInput
                      name='postal_code'
                      placeholder='CEP'
                      options={{
                        blocks: [5,3],
                        delimiters: ['-'],
                        numericOnly: true
                      }}
                      value={values.postal_code}
                      onChange={event => searchAddress (event, setFieldValue)}
                      onBlur={handleBlur ('postal_code')}
                    />
                    {touched.postal_code && errors.postal_code && (
                      <WarningText>{errors.postal_code}</WarningText>
                    )}
                  </FormField>
                  <FormField stretch={2}>
                    <Input
                      placeholder='Rua/avenida'
                      value={values.route}
                      onChange={handleChange ('route')}
                      onBlur={handleBlur ('route')}
                      disabled
                    />
                  </FormField>
                  <FormField>
                    <Input
                      placeholder='Número'
                      value={values.number}
                      onChange={handleChange ('number')}
                      onBlur={handleBlur ('number')}
                    />
                    {touched.number && errors.number && (
                      <WarningText>{errors.number}</WarningText>
                    )}
                  </FormField>
                  <FormField>
                    <Input
                      placeholder='Complemento'
                      value={values.complement}
                      onChange={handleChange ('complement')}
                      onBlur={handleBlur ('complement')}
                    />
                  </FormField>
                </Row>
                <Row>
                  <FormField stretch={2}>
                    <Input
                      placeholder='Bairro'
                      value={values.district}
                      onChange={handleChange ('district')}
                      onBlur={handleBlur ('district')}
                      disabled
                    />
                  </FormField>
                  <FormField>
                    <Input
                      placeholder='Cidade'
                      value={values.city}
                      onChange={handleChange ('city')}
                      onBlur={handleBlur ('city')}
                      disabled
                    />
                  </FormField>
                  <FormField>
                    <Input
                      placeholder='Estado'
                      value={values.state}
                      onBlur={handleBlur ('state')}
                      disabled
                    />
                  </FormField>
                </Row>
                {values.access_level?.value.codename === 'service' && (
                  <>
                    <SubTitle>Disponibilidade de atendimento da agenda do profissional</SubTitle>
                    <Row style={{alignItems: 'flex-start'}}>
                      {values.working_days.map ((day, index) => (
                        <FormField key={day.id}>
                          <Row style={{marginBottom: -5, alignItems: 'center'}}>
                            <CheckBox
                              id={`working_days.${index}.selected`}
                              style={{marginRight: 6}}
                              value={day.selected}
                              onValueChange={value => {
                                if (value === true) {
                                  setFieldValue (`working_days.${index}.work_start`, '')
                                  setFieldValue (`working_days.${index}.work_end`, '')
                                  setFieldValue (`working_days.${index}.cutilei_start`, '')
                                  setFieldValue (`working_days.${index}.cutilei_end`, '')
                                  setFieldValue (`working_days.${index}.accepts_cutilei`, false)
                                  setFieldTouched (`working_days.${index}.work_start`, false)
                                  setFieldTouched (`working_days.${index}.work_end`, false)
                                  setFieldTouched (`working_days.${index}.cutilei_start`, false)
                                  setFieldTouched (`working_days.${index}.cutilei_end`, false)
                                  setFieldTouched (`working_days.${index}.accepts_cutilei`, false)
                                }
                                setFieldValue (`working_days.${index}.selected`, value)
                              }}
                            />
                            <Label htmlFor={`working_days.${index}.selected`}>
                              {day.name}
                            </Label>
                          </Row>
                          {day.selected && (
                            <>
                              <Select
                                name={`working_days.${index}.work_start`}
                                placeholder='Início...'
                                value={day.work_start}
                                options={workStartTimeWindows[index]}
                                onChange={(name, option) => handleTimeSelected (name, option, values.working_days, setFieldValue)}
                                onBlur={setFieldTouched}
                                containerStyles={{marginTop: '10px'}}
                                error={errors.working_days?.[index]}
                                touched={touched.working_days?.[index]}
                              />
                              {touched.working_days?.[index]?.work_start && errors.working_days?.[index]?.work_start && (
                                <WarningText>{errors.working_days[index].work_start}</WarningText>
                              )}
                              <Select
                                name={`working_days.${index}.work_end`}
                                placeholder='Término...'
                                value={day.work_end}
                                options={workEndTimeWindows[index]}
                                onChange={(name, option) => handleTimeSelected (name, option, values.working_days, setFieldValue)}
                                onBlur={setFieldTouched}
                                containerStyles={{marginTop: '10px'}}
                                error={errors.working_days?.[index]}
                                touched={touched.working_days?.[index]}
                              />
                              {touched.working_days?.[index]?.work_end && errors.working_days?.[index]?.work_end && (
                                <WarningText>{errors.working_days[index].work_end}</WarningText>
                              )}
                            </>
                          )}
                        </FormField>
                      ))}
                    </Row>
                    <SubTitle>Serviços e cadastro de comissões do profissional</SubTitle>
                    <Row>
                      <FormField>
                        <Select
                          name='service_categories'
                          placeholder='Categorias de serviço...'
                          value={values.service_categories.map (c => ({label: c.name, value: c}))}
                          options={serviceCategories.map (c => ({label: c.name, value: c}))}
                          isMulti={true}
                          onChange={handleCategoriesAdded}
                          onBlur={setFieldTouched}
                          onRemoveOption={option => {
                            if (values.services.some (s => s.category.id === option.value.id && !s.cutilei_service)) {
                              toggleErrorDialog ()
                            } else {
                              setCategoryToRemove (option.value)
                              toggleRemoveDialog ()
                            }
                          }}
                          error={errors.service_categories}
                          touched={touched.service_categories}
                          containerStyles={{width: 600}}
                        />
                      </FormField>
                    </Row>
                    {values.service_categories.some (c => c.name.toLowerCase () === 'manicure e pedicure') && (
                      <>
                        <SubTitle>Disponibilidade de atendimento para a Cutilei</SubTitle>
                        <Row>
                          {values.working_days.filter (day => day.selected).map ((day, index) => (
                            <FormField key={index}>
                              <Row style={{marginTop: 6, marginBottom: -5}}>
                                <CheckBox
                                  id={`working_days.${index}.accepts_cutilei`}
                                  style={{marginTop: 3, marginRight: 6}}
                                  value={day.accepts_cutilei}
                                  onValueChange={value => {
                                    if (value === false) {
                                      setFieldValue (`working_days.${index}.cutilei_start`, '')
                                      setFieldValue (`working_days.${index}.cutilei_end`, '')
                                      setFieldTouched (`working_days.${index}.cutilei_start`, false)
                                      setFieldTouched (`working_days.${index}.cutilei_end`, false)
                                    }
                                    setFieldValue (`working_days.${index}.accepts_cutilei`, value)
                                  }}
                                />
                                <Label htmlFor={`working_days.${index}.accepts_cutilei`}>
                                  {day.name}
                                </Label>
                              </Row>
                              {day.accepts_cutilei && (
                                <>
                                  <Select
                                    name={`working_days.${index}.cutilei_start`}
                                    placeholder='Abertura...'
                                    value={day.cutilei_start}
                                    options={cutileiStartTimeWindows[index]}
                                    onChange={(name, option) => handleCutileiTimeSelected (name, option, values.working_days, setFieldValue)}
                                    onBlur={setFieldTouched}
                                    containerStyles={{marginTop: '10px'}}
                                    error={errors.working_days?.[index]}
                                    touched={touched.working_days?.[index]}
                                  />
                                  {touched.working_days?.[index]?.cutilei_start && errors.working_days?.[index]?.cutilei_start && (
                                    <WarningText>{errors.working_days[index].cutilei_start}</WarningText>
                                  )}
                                  <Select
                                    name={`working_days.${index}.cutilei_end`}
                                    placeholder='Fechamento...'
                                    value={day.cutilei_end}
                                    options={cutileiEndTimeWindows[index]}
                                    onChange={(name, option) => handleCutileiTimeSelected (name, option, values.working_days, setFieldValue)}
                                    onBlur={setFieldTouched}
                                    containerStyles={{marginTop: '10px'}}
                                    error={errors.working_days?.[index]}
                                    touched={touched.working_days?.[index]}
                                  />
                                  {touched.working_days?.[index]?.cutilei_end && errors.working_days?.[index]?.cutilei_end && (
                                    <WarningText>{errors.working_days[index].cutilei_end}</WarningText>
                                  )}
                                </>
                              )}
                            </FormField>
                          ))}
                        </Row>
                      </>
                    )}
                    <SubTitle>Serviços adicionados</SubTitle>
                    {values.service_categories.length > 0 && values.services.length > 0 && (
                      <Grid numRows={values.services.length} numColumns={3}>
                        <GridLabel>Serviço</GridLabel>
                        <GridLabel>Duração (mins)</GridLabel>
                        <GridLabel>Tolerância (mins)</GridLabel>
                        <GridLabel>Comissão (%)</GridLabel>
                        <GridLabel style={{fontWeight: 'bold'}}>Valor:</GridLabel>
                        {values.services.map ((service, index) => (
                          <Fragment key={index}>
                            <Label>
                              {service.cutilei_service && <Image src={Logo}/>}
                              {service.nickname || service.name}
                            </Label>
                            <NumberInput
                              name={`services[${index}].duration`}
                              value={values.services[index].duration}
                              minValue={10}
                              onChange={setFieldValue}
                            />
                            <NumberInput
                              name={`services[${index}].max_delay`}
                              value={values.services[index].max_delay}
                              minValue={5}
                              maxValue={20}
                              onChange={setFieldValue}
                            />
                            <NumberInput
                              name={`services[${index}].comission_percentage`}
                              value={values.services[index].comission_percentage}
                              minValue={values.services[index].cutilei_service ? 40 : 0}
                              maxValue={100}
                              onChange={setFieldValue}
                            />
                            <Row style={{marginBottom: 0, alignItems: 'center'}}>
                              <MoneyTextMask
                                value={(values.services[index].comission_percentage / 100 * service.price)}
                                style={{textAlign: 'center'}}
                              />
                              {!service.cutilei_service && (
                                <DangerButton type='button' onClick={toggleAlertDialog}>
                                  <FA.FaTrash/>
                                </DangerButton>
                              )}
                              <AlertDialog
                                visible={showAlertDialog}
                                title='Atenção!'
                                message='Deseja realmente excluir este serviço?'
                                confirmText='Sim'
                                cancelText='Não'
                                onConfirm={() => handleServiceDeleted (service, values, setFieldValue)}
                                onClose={toggleAlertDialog}
                                dangerous
                              />
                            </Row>
                          </Fragment>
                        ))}
                      </Grid>
                    )}
                    <Button type='button' onClick={toggleServicesModal} style={{marginBottom: 20}}>
                      Adicionar serviços
                    </Button>
                    <ProfessionalServicesModal
                      visible={showServicesModal}
                      serviceCategories={serviceCategories}
                      professionalCategories={values.service_categories}
                      onConfirm={(addedServices, serviceCategories) => {
                        handleServicesAdded (addedServices, serviceCategories, values, setFieldValue)
                      }}
                      onClose={toggleServicesModal}
                    />
                    {categoryToRemove && (
                      <AlertDialog
                        visible={showRemoveDialog}
                        title='Atenção!'
                        message='Deseja realmente remover esta categoria deste profissional?'
                        confirmText='Sim'
                        cancelText='Não'
                        onConfirm={() => removeServiceCategory (categoryToRemove, values, setFieldValue)}
                        onClose={toggleRemoveDialog}
                        dangerous
                      />
                    )}
                    <AlertDialog
                      visible={showErrorDialog}
                      title='Atenção!'
                      message='Para remover esta categoria, remova do profissional os serviços dela primeiro'
                      confirmText='Ok'
                      onConfirm={toggleErrorDialog}
                      onClose={toggleErrorDialog}
                    />
                  </>
                )}
                {errorMessage && (
                  <ErrorContainer>
                    <p>{errorMessage}</p>
                  </ErrorContainer>
                )}
                <AlertDialog
                  visible={showOkDialog && createdProfessional !== null}
                  title='Sucesso'
                  message='Profissional cadastrado!'
                  confirmText='Ok'
                  onConfirm={() => history.replace (`/business/professionals/${createdProfessional?.id}/edit`)}
                  onClose={() => history.replace (`/business/professionals/${createdProfessional?.id}/edit`)}
                />
                <Button type='submit' disabled={isSubmitting}>
                  {isSubmitting ? <ButtonLoading/> : 'Finalizar cadastro'}
                </Button>
              </Form>
            )
          }}
        </Formik>
      )}
    </Container>
  )
}

export default withRouter (AddProfessional)
