import * as Yup from 'yup'

const billBundleValidator = Yup.object ({
  bundle: Yup.object ().nullable ().required ('Selecione o pacote'),
  professional: Yup.object ().nullable (),
  custom_price: Yup.number (),
  custom_comission_percentage: Yup.number (),
})

export default billBundleValidator
