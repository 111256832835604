import React, { useState } from 'react'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import PasswordInput from '../../../components/Inputs/PasswordInput'
import AlertDialog from '../../../components/AlertDialog'
import { Formik } from 'formik'
import passwordValidator from '../../../validators/passwordValidator'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'

import {
  Container,
  Form,
  Button,
  SubTitle,
  FormField,
  WarningText,
  ErrorContainer,
  Row
} from '../styles'

function ChangeProfessionalPassword ({ professional }) {
  require ('cleave.js/dist/addons/cleave-phone.br')

  const [errorMessage, setErrorMessage] = useState (null)
  const [showOkDialog, setShowOkDialog] = useState (false)
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const toggleOkDialog = () => setShowOkDialog (!showOkDialog)

  return (
    <Container>
      <Formik
        validationSchema={passwordValidator}
        initialValues={{
          'password': '',
          'password_confirmation': ''
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const { password } = values

          setErrorMessage (null)
          setSubmitting (true)

          try {
            await cutileiApi.put (`/professionals/${professional?.id}`, {password}, requestConfig)

            setSubmitting (false)
            toggleOkDialog ()
          } catch (error) {
            setSubmitting (false)
            console.log (error.response.data)
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <Form onSubmit={handleSubmit} style={{width: 480}}>
            <SubTitle>Alterar senha</SubTitle>
            <Row>
              <FormField>
                <PasswordInput
                  placeholder='Nova senha'
                  value={values.password}
                  onChange={handleChange ('password')}
                  onBlur={handleBlur ('password')}
                />
                {touched.password && errors.password && (
                  <WarningText>{errors.password}</WarningText>
                )}
              </FormField>
            </Row>
            <Row>
              <FormField>
                <PasswordInput
                  placeholder='Confirmar senha'
                  value={values.password_confirmation}
                  onChange={handleChange ('password_confirmation')}
                  onBlur={handleBlur ('password_confirmation')}
                />
                {touched.password_confirmation && errors.password_confirmation && (
                  <WarningText>{errors.password_confirmation}</WarningText>
                )}
              </FormField>
            </Row>

            {errorMessage && (
              <ErrorContainer>
                <p>{errorMessage}</p>
              </ErrorContainer>
            )}

            <AlertDialog
              visible={showOkDialog}
              title='Sucesso'
              message='Senha alterada!'
              confirmText='Ok'
              onConfirm={toggleOkDialog}
              onClose={toggleOkDialog}
            />

            <Button type='submit' disabled={isSubmitting}>
              {isSubmitting ? <ButtonLoading/> : 'Salvar alterações'}
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default ChangeProfessionalPassword
