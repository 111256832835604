import React from 'react'
import Logo from '../../../../icons/logo-cutilei-simple.png'

import {
  Container,
  SubTitle,
  InfoText
} from './styles'

function Category ({
  data: category,
  categoryType
}) {
  return (
    <Container to={{
      pathname: `/admin/${categoryType}_categories/${category.id}/edit`,
      state: {category}
    }}>
      <SubTitle>
        {category.name}
      </SubTitle>
      <InfoText>
        {category.description}
      </InfoText>
      <SubTitle style={{color: category.deleted ? '#FF3939' : '#44CF6C'}}>
        {category.deleted ? 'Inativo' : 'Ativo'}
      </SubTitle>
    </Container>
  )
}

export default Category
