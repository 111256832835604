export default class General {
  static groupBy (objectArray, prop) {
    return Object.values (objectArray.reduce ((acc, obj) => {
      let key = obj[prop]
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push (obj)
      return acc
    }, {}))
  }

  static getStringType (input) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const regexTelefone = /^(\(?\d{2}\)?\s?)?(\d{4,5}-?\d{4})$/
    
    if (emailRegex.test (input)) {
      return 'email'
    } else if (regexTelefone.test (input)) {
      return 'phone'
    } else {
      return 'text'
    }
  }
}