import React from 'react'

import {
  Container,
  Row,
  Image,
  InfoText
} from './styles'

function Business ({
  data: business,
}) {
  return (
    <Container to={{
      pathname: `/admin/businesses/${business.id}`,
      state: {business}
    }}>
      <Row>
        <Image src={business.logo}/>
        <InfoText>
          { business.name }
        </InfoText>
      </Row>
    </Container>
  )
}

export default Business
