import React, { useState } from 'react'
import cutileiApi from '../../../../services/cutileiApi'
import AlertDialog from '../../../AlertDialog'
import AuthService from '../../../../services/auth'
import { ReactComponent as ButtonLoading } from '../../../../icons/loading2.svg'
import * as FA from 'react-icons/fa'
import { DateTime } from 'luxon'

import {
  Container,
  Row,
  InfoText,
  Button
} from './styles'

function CutileiReservation ({
  data: reservation,
  onEdit: handleEdit,
  onDelete: handleDelete
}) {
  const [showDeleteReservationDialog, setShowDeleteReservationDialog] = useState (false)
  const [deleting, setDeleting] = useState (false)
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const getPeriod = reservation => {
    const period = DateTime.fromISO (reservation.end_date)
      .diff (DateTime.fromISO (reservation.start_date), 'days').toObject ()

    return period.days > 0 ? `${period.days} dias` : '1 dia'
  }

  const period = getPeriod (reservation)

  const deleteReservation = async () => {
    try {
      setDeleting (true)
      await cutileiApi.delete (`/cutilei_reservations/${reservation.id}`, requestConfig)
      handleDelete (reservation)
    } catch (error) {
      console.log (error)
    } finally {
      setDeleting (false)
    }
  }

  const toggleDeleteReservationDialog = () => setShowDeleteReservationDialog (!showDeleteReservationDialog)

  return (
    <Container>
      <InfoText>
        {reservation.description}
      </InfoText>
      <InfoText>
        {DateTime.fromISO (reservation.start_date).toFormat ('dd/MM/yyyy')}
      </InfoText>
      <InfoText>
        {DateTime.fromISO (reservation.end_date).toFormat ('dd/MM/yyyy')}
      </InfoText>
      <InfoText>
        {period}
      </InfoText>
      <Row>
        <Button onClick={() => handleEdit (reservation)}>
          <FA.FaEdit color='#35A7FF' size={18}/>
        </Button>
        <Button onClick={toggleDeleteReservationDialog}>
          {deleting ? (
            <ButtonLoading style={{margin: 0}}/>
          ) : (
            <FA.FaTrash color='#FF3939' size={16}/>
          )}
        </Button>
      </Row>
      <AlertDialog
        visible={showDeleteReservationDialog}
        title='Atenção!'
        message='Deseja realmente excluir esta reserva?'
        confirmText='Sim'
        cancelText='Não'
        onConfirm={deleteReservation}
        onClose={toggleDeleteReservationDialog}
        dangerous
      />
    </Container>
  )
}

export default CutileiReservation
