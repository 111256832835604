import styled from 'styled-components'

const cellHeight = 26

export const Container = styled.button`
  display: flex;
  height: ${cellHeight - 1 + 'px'};
  background-color: ${({ isDraggingOver }) => isDraggingOver ? '#F0F0F0': 'transparent'};
  border: ${({ isDraggingOver }) => isDraggingOver ? '2px solid #FFFFFF': '0px'};
  margin: 1px 0px;
  border-radius: 8px;
  text-decoration: none;
  grid-row-start: ${({ positiony }) => positiony + 1};
  grid-row-end: ${({ positiony }) => positiony + 2};
  grid-column-start: ${({ positionx }) => positionx + 1};
  grid-column-end: ${({ positionx }) => positionx + 2};
  align-self: stretch;
  justify-self: stretch;
  justify-content: center;
  align-items: center;

  p {display: ${({ isDraggingOver }) => isDraggingOver ? 'block': 'none'};}

  &:hover {
    background-color: #F0F0F0;
    border: 2px solid #FFFFFF;
    p {display: block;}
  }

  &:first-child {
    &:before {
      content: '';
      background-color: #CCCCCC;
      position: absolute;
      top: 0px;
      margin-left: 0px;
      margin-right: calc((100vw - 465px) / ${({ numColumns }) => numColumns});
      width: 1px;
      height: calc(100% - ${cellHeight}px);
    }
  }
  
  &:after {
    content: '';
    background-color: #CCCCCC;
    position: absolute;
    top: 0px;
    margin-left: calc((100vw - 465px) / ${({ numColumns }) => numColumns});
    margin-right: 0px;
    width: 1px;
    height: calc(100% - ${cellHeight}px);
  }
`

export const InfoText = styled.p`
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #000000;
  margin: 0px;
`
