import * as Yup from 'yup'

const billProductValidator  = Yup.object ({
  product: Yup.object ().nullable ().required ('Selecione o produto'),
  professional: Yup.object ().nullable (),
  amount: Yup.number (),
  custom_price: Yup.number (),
  custom_comission_percentage: Yup.number (),
})

export default billProductValidator 
