import React, { useState } from 'react'
import { Formik } from 'formik'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import NumberInput from '../../Inputs/NumberInput'
import MoneyInput from '../../Inputs/MoneyInput'
import Select from '../../Inputs/Select'
import billServiceValidator from '../../../validators/billServiceValidator'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import * as FA from 'react-icons/fa'

import {
  Form,
  FormField,
  Button,
  CloseButton,
  FieldGrid,
  Row,
  InfoText,
  Label,
  WarningText,
  ErrorContainer
} from './styles'

function BillServiceForm ({
  bill,
  services,
  professionals,
  customerServices = [],
  onConfirm: handleConfirm,
  onClose: handleClose
}) {
  const [errorMessage, setErrorMessage] = useState (null)
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const handleServiceSelected = async (name, option, setFieldValue) => {
    const service = option.value

    setFieldValue ('custom_price', service.price)
    setFieldValue ('custom_comission_percentage', service.comission_percentage * 100)
    setFieldValue (name, option)
  }

  return (
    <Formik
      validationSchema={billServiceValidator}
      initialValues={{
        'service': null,
        'professional': null,
        'amount': 1,
        'custom_price': 0,
        'custom_comission_percentage': 0
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const addedServices = []
          const professional_id = values.professional?.value || null
          const serviceData = {
            service_id: values.service.value.id,
            professional_id,
            amount: values.amount,
            custom_price: values.custom_price,
            custom_comission_percentage: values.custom_comission_percentage / 100
          }
  
          const handAndFootServices = services.filter (s => (
            s.name.toLowerCase () === 'mão' || s.name.toLowerCase () === 'pé'
          ))

          const hasHandOrFeetService = customerServices.some (service => (
            handAndFootServices.map (s => s.id).includes (service.id)
          ))

          if (values.service.value.name.toLowerCase () === 'pé e mão' && hasHandOrFeetService) {
            await Promise.all (handAndFootServices.map (async service => {
              const { data: addedService } = await cutileiApi.post (
                `/business_bills/${bill.id}/services`, {
                  service_id: service.id,
                  professional_id,
                  amount: 1,
                  custom_price: service.price,
                  custom_comission_percentage: service.comission_percentage
                }, requestConfig
              )
              addedServices.push (addedService)
            }))
          } else {
            const { data: service } = await cutileiApi.post (
              `/business_bills/${bill.id}/services`, serviceData, requestConfig
            )
            addedServices.push (service)
          }
          
          setSubmitting (false)
          handleConfirm (addedServices)
          handleClose ()
        } catch (error) {
          if (error.response.data) setErrorMessage (error.response.data.message)
          setSubmitting (false)
          console.log (error)
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        handleBlur,
        handleSubmit
      }) => (
        <Form>
          <FieldGrid>
            <FormField>
              {touched.service && errors.service && (
                <WarningText>
                  {errors.service}
                </WarningText>
              )}
              <Select
                name='service'
                placeholder='Serviço...'
                value={values.service}
                options={services.map (service => ({
                  label: service.name, value: service
                }))}
                onChange={(name, option) => handleServiceSelected (name, option, setFieldValue)}
                onBlur={setFieldTouched}
                error={errors.service}
                touched={touched.service}
              />        
            </FormField>
            <FormField>
              <Select
                name='professional'
                placeholder='Profissional...'
                value={values.professional}
                options={professionals.map (professional => ({
                  label: professional.nickname || professional.name.split (' ')[0], value: professional.id
                }))}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                error={errors.professional}
                touched={touched.professional}
              />
            </FormField>
            <FormField>
              <Label htmlFor='service_amount'>
                Qtd
              </Label>
              <NumberInput
                id='service_amount'
                name='amount'
                value={values.amount}
                minValue={1}
                maxValue={99}
                onChange={(name, value) => {
                  setFieldValue ('custom_price', (values.service?.value.price ?? 0) * value)
                  setFieldValue (name, value)
                }}
                style={{width: '100%'}}
              />
            </FormField>
            <FormField>
              <Label htmlFor='service_price'>
                Preço
              </Label>
              <MoneyInput
                id='service_price'
                name='custom_price'
                placeholder='Preço'
                value={values.custom_price}
                onValueChange={setFieldValue}
                onBlur={handleBlur ('custom_price')}
              />
            </FormField>
            <FormField>
              <Label htmlFor='service_comission_percentage'>
                Comissão (%)
              </Label>
              <NumberInput
                id='service_comission_percentage'
                name='custom_comission_percentage'
                value={values.custom_comission_percentage}
                minValue={0}
                maxValue={100}
                onChange={setFieldValue}
                style={{width: '100%'}}
              />
            </FormField>
            <Button type='button' onClick={handleSubmit} disabled={isSubmitting}>
              {isSubmitting ? <ButtonLoading/> : 'Adicionar'}
            </Button>
            <CloseButton onClick={handleClose}>
              <FA.FaTimes color='#FF3939' size={18}/>
            </CloseButton>
          </FieldGrid>
          <Row>
            {errorMessage && (
              <ErrorContainer>
                <InfoText>{errorMessage}</InfoText>
              </ErrorContainer>
            )}
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default BillServiceForm
