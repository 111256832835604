import * as Yup from 'yup'

const createScheduleValidator = Yup.object ({
  schedules: Yup.array ().of (Yup.object ().shape ({
    professional: Yup
      .object ()
      .nullable ()
      .required ('Selecione um profissional'),

    customer: Yup
      .object ()
      .nullable ()
      .required ('Selecione um cliente'),

    date: Yup
      .string ()
      .required ('Selecione uma data'),

    time: Yup
      .object ()
      .nullable ()
      .required ('Selecione o horário'),

    service: Yup
      .object ()
      .nullable ()
      .required ('Selecione o serviço')
  }))
})

export default createScheduleValidator
