import * as Yup from 'yup'

const earningAndExpenseValidator = Yup.object ({
  value: Yup
    .number ()
    .positive ('Insira o valor'),

  description: Yup
    .string ()
    .required ('Insira a descrição')
    .max (256, 'A descrição deve conter no máximo 256 caracteres')
})

export default earningAndExpenseValidator
