import React from 'react'
import CustomerCredit from './CustomerCredit'

import {
  Container,
  SubTitle,
  LabelRow,
  InfoText
} from './styles'

function CustomerCreditList ({
  credits,
  styles,
  onEdit: handleEdit,
  onDelete: handleDelete
}) {
  return (
    <Container style={styles}>
      {credits.length > 0 ? (
        <>
          <LabelRow>
            <SubTitle>Data</SubTitle>
            <SubTitle>Descrição</SubTitle>
            <SubTitle>Valor</SubTitle>
            <SubTitle>Ações</SubTitle>
          </LabelRow>
          {credits.map (credit =>
            <CustomerCredit
              key={credit.id}
              data={credit}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          )}
        </>
      ) : (
        <InfoText>
          Este cliente ainda não possui créditos ou dívidas
        </InfoText>
      )}
    </Container>
  )
}

export default CustomerCreditList
