import styled from 'styled-components'
import { NumericFormat } from 'react-number-format'

export const Container = styled.div`
  display: flex;
  min-width: 480px;
  width: 640px;
  box-shadow: 0px 2px 10px -6px #252525;
  cursor: pointer;
  border-radius: 15px;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 15px !important;
  margin-bottom: 10px;
  text-decoration: none;

  &:hover {
    background-color: #F7F7F7;
  }
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`

export const InfoText = styled.p`
  color: #000000;
  font-size: 14px;
  text-align: center;
  margin-bottom: 0px;
`

export const Title = styled(InfoText)`
  font-size: 16px;
  font-weight: bold;
  padding: 0px;
`

export const SubTitle = styled(Title)`
  font-size: 14px;
`

export const MoneyTextMask = styled(NumericFormat).attrs (props => {
  return {
    value: props.value.toFixed (2).replace ('.', ','),
    thousandSeparator: '.',
    decimalSeparator: ',',
    displayType: 'text',
    prefix: 'R$ '
  }
})`
  color: #44CF6C;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px;
  padding: 0px;
`

export const Button = styled.button`
  background-color: #252525;
  color: #F7F7F7;
  width: 200px;
  padding: 8px;
  border-radius: 15px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 10px auto 0px;
  font-size: 14px;
  border: none;

  &:hover {
    background-color: #121212;
    color: #F7F7F7;
  }
`

export const DangerButton = styled(Button)`
  background-color: #FF3939;
  margin-top: 10px;

  &:hover {
    background-color: #FF2020;
    color: #FFFFFF;
  }
`
