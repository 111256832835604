import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
`

export const TabRow = styled(Container)`
  flex-direction: row;
  margin-bottom: 10px;
  padding: 0px;
  gap: 10px;
`

export const TabButton = styled.button`
  color: ${props => props.selected ? '#FFFFFF' : props.color};
  background-color: ${props => props.selected ? props.color : '#FFFFFF'};
  font-size: 14px;
  font-weight: bold;
  width: 200px;
  height: 38px;
  border: 0px;
  box-shadow: 0px 2px 10px -6px #252525;
  text-decoration: none;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: #FFFFFF;
    background-color: ${props => props.color};
  }
`
