import React, { useState } from 'react'
import CustomerService from './CustomerService'
import CustomerServiceDetailsModal from '../../../pages/Modals/CustomerServiceDetailsModal'

import {
  Container,
  SubTitle,
  InfoText,
  LabelRow
} from './styles'

function CustomerServiceList ({
  customer,
  customerServices
}) {
  const [serviceData, setServiceData] = useState (null)
  const [showServiceDetailsModal, setShowServiceDetailsModal] = useState (false)

  const handleClick = service => {
    setServiceData (service)
    toggleServiceDetailsModal ()
  }

  const toggleServiceDetailsModal = () => setShowServiceDetailsModal (!showServiceDetailsModal)

  return (
    <Container>
      {customerServices.length > 0 ? (
        <>
          <LabelRow>
            <SubTitle>Serviço</SubTitle>
            <SubTitle>Pacote</SubTitle>
            <SubTitle>Comanda</SubTitle>
            <SubTitle>Qtd.</SubTitle>
            <SubTitle>Qtd. utilizada</SubTitle>
            <SubTitle>Qtd. disponível</SubTitle>
            <SubTitle>Validade</SubTitle>
          </LabelRow>
          {customerServices.map ((service, index) =>
            <CustomerService
              key={index}
              data={service}
              onClick={handleClick}
            />
          )}
          {serviceData && (
            <CustomerServiceDetailsModal
              visible={showServiceDetailsModal}
              customer={customer}
              service={serviceData}
              onClose={toggleServiceDetailsModal}
            />
          )}
        </>
      ) : (
        <InfoText>Este cliente ainda não possui pacotes</InfoText>
      )}
    </Container>
  )
}

export default CustomerServiceList
