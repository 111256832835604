import React from 'react'
import { DateTime } from 'luxon'
import Logo from '../../../../icons/logo-cutilei-simple.png'
import { FaTimes } from 'react-icons/fa'

import {
  Container,
  Row,
  Image,
  SubTitle,
  InfoText,
  MoneyTextMask,
  Light
} from './styles'

function Schedule ({
  data: schedule,
  detailed = false,
  onClick: handleClick
}) {
  const firstName = schedule.professional.name.split(' ')[0]
  const scheduleDateTime = DateTime.fromFormat (`${schedule.date} ${schedule.time}`, 'yyyy-MM-dd H:mm')
  const isReservation = schedule.validated === undefined
  const isCompleted = DateTime.now () >= scheduleDateTime?.plus ({minutes: schedule.max_delay + 1})
  const isInProgress = !isCompleted && DateTime.now () > scheduleDateTime.minus ({minutes: 20})
  const isValidated = schedule.validated

  return (
    <Container detailed={detailed} onClick={() => handleClick (schedule)}>
      {detailed && (
        <Row>
          <Light
            isInProgress={isInProgress}
            isCompleted={isCompleted}
            isValidated={isValidated}
            isReservation={isReservation}
            cutileiSchedule={schedule.cutilei_schedule}
            status={schedule.status}
          >
            {schedule.canceled && <FaTimes color='#FFFFFF' size={12}/>}
          </Light>
          {schedule.cutilei_schedule && <Image src={Logo} alt='Agendamento Cutilei'/>}
          <SubTitle>
            {schedule.member?.name.split (' ')[0] || schedule.customer?.name.split (' ')[0]}
          </SubTitle>
        </Row>
      )}
      <InfoText>
        {schedule.service.name}
      </InfoText>
      <InfoText>
        {schedule.professional.nickname || firstName}
      </InfoText>
      <InfoText>
        {DateTime.fromISO (schedule.date).toFormat ('dd/MM/yyyy')}
      </InfoText>
      <InfoText>
        {schedule.time}
      </InfoText>
      <InfoText>
        {schedule.comission_percentage * 100}
      </InfoText>
      <MoneyTextMask value={schedule.price} style={{justifySelf: 'center'}}/>
    </Container>
  )
}

export default Schedule
