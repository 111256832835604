export default class ScheduleReservation {
  static PERIODS = [
    {label: '1 dia', value: 0},
    {label: '7 dias', value: 7},
    {label: '15 dias', value: 15},
    {label: '1 mês', value: 30},
    {label: 'Indeterminado', value: -1},
    {label: 'Personalizado', value: -2}
  ]
}
