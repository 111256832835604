import React, { useState } from 'react'
import EditBillModal from '../../../../pages/Modals/EditBillModal'
import { DateTime } from 'luxon'

import {
  Container,
  SubTitle,
  InfoText,
  InfoLink
} from './styles'

function CustomerService ({
  data: service,
  onClick: handleClick
}) {
  const { bundle, bill } = service
  const [showEditBillModal, setShowEditBillModal] = useState (false)

  const handleBillClick = e => {
    e?.stopPropagation ()
    toggleEditBillModal ()
  }

  const toggleEditBillModal = () => setShowEditBillModal (!showEditBillModal) 

  return (
    <>
      <Container onClick={() => handleClick (service)}>
        <SubTitle>
          {service.name}
        </SubTitle>
        <InfoText>
          {bundle.name}
        </InfoText>
        {bill ? (
          <InfoLink onClick={handleBillClick}>
            <InfoText>
              Nº{bill?.number} ({DateTime.fromISO (bill?.date).toFormat ('dd/MM/yyyy')})
            </InfoText>
          </InfoLink>
        ) : (
          <InfoText>-</InfoText>
        )}
        <InfoText>
          {service.total_amount}
        </InfoText>
        <InfoText>
          {service.total_amount - service.available_amount}
        </InfoText>
        <InfoText>
          {service.available_amount}
        </InfoText>
        <InfoText>
          {service.expires_at
            ? DateTime.fromISO (service.expires_at).toFormat ('dd/MM/yyyy')
            : 'Indeterminado'
          }
        </InfoText>
      </Container>
      {bill && (
        <EditBillModal
          visible={showEditBillModal}
          data={bill}
          onClose={toggleEditBillModal}
          onConfirmEdit={() => null}
          onCloseBill={() => null}
        />
      )}
    </>
  )
}

export default CustomerService
