import React, { useState } from 'react'
import Modal from 'react-modal'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import MoneyInput from '../../../components/Inputs/MoneyInput'
import { Formik } from 'formik'
import cashRegisterValidator from '../../../validators/cashRegisterValidator'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import * as FA from 'react-icons/fa'
import { DateTime } from 'luxon'

import {
  modalStyle,
  Form,
  FormField,
  Title,
  Label,
  Input,
  Button,
  CloseButton,
  Row,
  InfoText,
  WarningText,
  ErrorContainer
} from './styles'

function AddCashRegisterModal ({
  visible,
  onConfirm: handleConfirm,
  onClose: handleClose
}) {
  const [errorMessage, setErrorMessage] = useState (null)
  const token = AuthService.getToken ()
  const user = AuthService.getUser ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      <Formik
        validationSchema={cashRegisterValidator}
        initialValues={{
          'businessId': AuthService.isProfessional () ? user.business_id : user.id,
          'initialCashValue': 0,
          'initialCheckValue': 0,
          'openedAt': `${DateTime.now ().toFormat ('yyyy-MM-dd')}T${DateTime.now ().toFormat ('HH:mm:ss')}`
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const { data: cashRegister } = await cutileiApi.post ('/cash_registers', values, requestConfig)

            setSubmitting (false)
            handleConfirm (cashRegister)
            handleClose ()
          } catch (error) {
            if (error.response.data) setErrorMessage (error.response.data.message)
            setSubmitting (false)
            console.log (error)
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleBlur,
          handleSubmit
        }) => (
          <Form onSubmit={handleSubmit}>
            <Title>Novo caixa</Title>
            <CloseButton onClick={handleClose}>
              <FA.FaTimes color='#FF3939' size={18}/>
            </CloseButton>
            <Row>
              <FormField>
                <Label>Valor inicial (Dinheiro)</Label>
                <MoneyInput
                  name='initialCashValue'
                  placeholder='Valor inicial (Dinheiro)'
                  value={values.initialCashValue}
                  onValueChange={setFieldValue}
                  onBlur={handleBlur ('initialCashValue')}
                />
              </FormField>
              <FormField>
                <Label>Valor inicial (Cheque)</Label>
                <MoneyInput
                  name='initialCheckValue'
                  placeholder='Valor inicial (Cheque)'
                  value={values.initialCheckValue}
                  onValueChange={setFieldValue}
                  onBlur={handleBlur ('initialCheckValue')}
                />
              </FormField>
            </Row>
            <Row>
              <FormField>
                <Label>Data e hora de abertura</Label>
                <Input 
                  type='datetime-local'
                  placeholder='Data e hora de abertura'
                  value={values.openedAt}
                  disabled={true}
                />
                {touched.openedAt && errors.openedAt && (
                  <WarningText>
                    {errors.openedAt}
                  </WarningText>
                )}
              </FormField>
            </Row>
            {errorMessage && (
              <ErrorContainer>
                <InfoText>{errorMessage}</InfoText>
              </ErrorContainer>
            )}
            <Button type='submit' disabled={isSubmitting}>
              {isSubmitting ? <ButtonLoading/> : 'Abrir caixa'}
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default AddCashRegisterModal
