import React, { useState } from 'react'
import cutileiApi from '../../services/cutileiApi'
import AuthService from '../../services/auth'
import AlertDialog from '../AlertDialog'
import * as FA from 'react-icons/fa'

import {
  Container,
  Row,
  InfoText,
  MoneyTextMask,
  SmallButton
} from './styles'

function Earning ({
  data: earning,
  enableEdit,
  onDelete: handleDelete
}) {
  const [showAlertDialog, setShowAlertDialog] = useState (false)
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }
  
  const deleteEarning = async () => {
    try {
      await cutileiApi.delete (`/earnings/${earning.id}`, requestConfig)
      handleDelete (earning)
    } catch (error) {
      console.log (error)
    }
  }

  const toggleAlertDialog = () => setShowAlertDialog (!showAlertDialog)

  return (
    <Container key={earning.id}>
      <InfoText>{earning.description}</InfoText>
      <Row>
        <MoneyTextMask value={earning.value}/>
        {enableEdit && (
          <SmallButton onClick={toggleAlertDialog}>
            <FA.FaTrash color='#FF3939' size={12}/>
          </SmallButton>
        )}
      </Row>
      {enableEdit && (
        <AlertDialog
          visible={showAlertDialog}
          title='Atenção!'
          message='Deseja realmente excluir este suprimento?'
          confirmText='Sim'
          cancelText='Não'
          onConfirm={deleteEarning}
          onClose={toggleAlertDialog}
          dangerous
        />
      )}
    </Container>
  )
}

export default Earning
