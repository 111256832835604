import * as Yup from 'yup'

const productValidator = Yup.object ({
  name: Yup.string ()
    .required ('Digite um nome')
    .min (4, 'O nome deve conter no minimo 4 caracteres!')
    .max (64, 'O nome deve conter até 64 caracteres!'),

  brand: Yup.string ()
    .required ('Digite o nome da marca')
    .min (4, 'O nome da marca deve conter no minimo 4 caracteres!')
    .max (64, 'O nome da marca deve conter até 64 caracteres!'),

  category: Yup
    .object ()
    .nullable ()
    .required ('Selecione uma categoria'),

  description: Yup
    .string ()
    .max (256, 'A descrição deve ter até 256 caracteres')
})

export default productValidator
