import React, { useState } from 'react'
import cutileiApi from '../../services/cutileiApi'
import AuthService from '../../services/auth'
import AlertDialog from '../AlertDialog'
import * as FA from 'react-icons/fa'

import {
  Container,
  Row,
  InfoText,
  MoneyTextMask,
  SmallButton
} from './styles'

function Expense ({
  data: expense,
  enableEdit,
  onDelete: handleDelete
}) {
  const [showAlertDialog, setShowAlertDialog] = useState (false)
  const token = AuthService.getToken ()
  
  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const deleteExpense = async () => {
    try {
      await cutileiApi.delete (`/expenses/${expense.id}`, requestConfig)
      handleDelete (expense)
    } catch (error) {
      console.log (error)
    }
  }

  const toggleAlertDialog = () => setShowAlertDialog (!showAlertDialog)

  return (
    <Container key={expense.id}>
      <InfoText>{expense.description}</InfoText>
      <Row>
        <MoneyTextMask value={expense.value * -1}/>
        {enableEdit && (
          <SmallButton onClick={toggleAlertDialog}>
            <FA.FaTrash color='#FF3939' size={12}/>
          </SmallButton>
        )}
      </Row>
      {enableEdit && (
        <AlertDialog
          visible={showAlertDialog}
          title='Atenção!'
          message='Deseja realmente excluir esta sangria?'
          confirmText='Sim'
          cancelText='Não'
          onConfirm={deleteExpense}
          onClose={toggleAlertDialog}
          dangerous
        />
      )}
    </Container>
  )
}

export default Expense
