import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import cutileiApi from '../../../services/cutileiApi'
import AdminNavbar from '../../../components/AdminNavbar'
import MemberList from '../../../components/Lists/MemberList'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'

import {
  Container,
  TabRow,
  TabButton
} from './styles'

function Members () {
  const [loading, setLoading] = useState (true)
  const [status, setStatus] = useState (localStorage.getItem ('member_status') || 'active')
  const [members, setMembers] = useState ([])

  const STATUS_LIST = [
    {label: 'Ativos', value: 'active'},
    {label: 'Inadimplentes', value: 'overdue'},
    {label: 'Cancelados', value: 'canceled'}
  ]

  useEffect (() => {
    getMembers (status)
  }, [status])

  const getMembers = async (status) => {
    try {
      setLoading (true)
      const { data: membersResponse } = await cutileiApi.get (`/members?status=${status}`)
      setMembers (membersResponse)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  return (
    <Container>
      <AdminNavbar/>
      <TabRow>
        {STATUS_LIST.map (s =>
          <TabButton
            key={s.value}
            selected={status === s.value}
            color={
              s.value === 'active' ? '#44CF6C'
              : s.value === 'overdue' ? '#FFC100' : '#FF3939'
            }
            onClick={() => {
              setStatus (s.value)
              localStorage.setItem ('member_status', s.value)
            }}
          >
            {s.label}
          </TabButton>
        )}
      </TabRow>
      {loading ? <Loading style={{marginTop: 30}}/> :
        <MemberList members={members}/>
      }
    </Container>
  )
}

export default withRouter (Members)
