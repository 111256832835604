import React from 'react'
import Modal from 'react-modal'
import * as FA from 'react-icons/fa'

import {
  modalStyle,
  Container,
  Header,
  Row,
  Title,
  Message,
  Button,
  DangerButton,
  CloseButton,
  ButtonText
} from './styles'

function AlertDialog ({
  visible,
  title = 'Confirmar',
  message,
  dangerous = false,
  confirmText = 'Ok',
  cancelText = '',
  onConfirm,
  onClose: handleClose,
  containerStyle = {},
  buttonStyle = {}
}) {
  const handleConfirm = () => {
    onConfirm ()
    handleClose ()
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      <Container style={containerStyle}>
        <Header>
          <Title>
            {title}
          </Title>
          <CloseButton onClick={handleClose}>
            <FA.FaTimes color='#FF3939' size={18}/>
          </CloseButton>
        </Header>
        <Message>
          {message}
        </Message>
        <Row>
          {dangerous ? (
            <DangerButton onClick={handleConfirm} style={buttonStyle}>
              <ButtonText>
                {confirmText}
              </ButtonText>
            </DangerButton>
          ) : (
            <Button
              onClick={handleConfirm}
              style={{backgroundColor: '#FFC100', ...buttonStyle}}
            >
              <ButtonText>
                {confirmText}
              </ButtonText>
            </Button>
          )}
          {cancelText && (
            <Button onClick={handleClose} style={buttonStyle}>
              <ButtonText>
                {cancelText}
              </ButtonText>
            </Button>
          )}
        </Row>
      </Container>
    </Modal>
  )
}

export default AlertDialog
