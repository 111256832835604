import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
`

export const Row = styled(Container)`
  width: 100%;
  padding: 0px;
  gap: 10px;
  margin-bottom: 15px;
  flex-direction: row;
  align-items: flex-start;
`

export const CalendarContainer = styled(Container)`
  padding: 0px;
  margin: 0px 30px 0px 0px;
`

export const FormField = styled(Container)`
  flex: 1;
  justify-content: flex-end;
  padding: 0px;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  background-color: #F7F7F7;
  margin-top: 10px;
  border-radius: 15px;
`

export const Input = styled.input`
  width: 100%;
  height: 38px;
  border: 0px;
  box-shadow: 0px 2px 10px -8px #252525;
  border-radius: 15px;
  text-align: center;
  padding-right: ${({type}) => type === 'date' ? '9px' : '0px'};

  &::placeholder {
    color: #BBBBBB
  }

  &:disabled {
    background-color: #FFFFFF;
  }
  
  &:focus {
    border: 1px solid #CCCCCC !important;
    outline: none;
  }
`

export const Title = styled.h2`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  align-self: center;
`;

export const InfoText = styled.p`
  text-align: center;
  color: #FFFFFF;
  margin: 0px;
`

export const WarningText = styled(InfoText)`
  color: #FF3939;
`

export const Label = styled(InfoText)`
  margin-bottom: 5px;
  color: #000000;
`

export const Button = styled.button`
  width: 180px;
  align-self: center;
  background-color: #606060;
  color: #FFFFFF;
  padding: 8px 0px 9px;
  margin-bottom: 10px;
  border: 0px;
  border-radius: 15px;
  text-align: center;
  text-decoration: none;

  &:hover {
    background-color: #404040;
  }
`

export const ErrorContainer = styled.div`
  padding: 9px;
  margin-bottom: 15px;
  width: 100%;
  border-radius: 15px;
  align-items: center;
  background-color: #FF3939;
`
