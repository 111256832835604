import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import AuthService from '../../../../services/auth'
import cutileiApi from '../../../../services/cutileiApi'
import Dashboard from '../../../../services/Dashboard'
import PieChart from '../../../../components/Charts/PieChart'
import ColumnChart from '../../../../components/Charts/ColumnChart'
import Select from '../../../../components/Inputs/Select'
import { ReactComponent as Loading } from '../../../../icons/loading2.svg'
import * as FA from 'react-icons/fa'
import { DateTime } from 'luxon'

import {
  modalStyle,
  InfoContainer,
  ModalContainer,
  FilterContainer,
  LoadingContainer,
  CloseButton,
  SubTitle,
  InfoText,
  LinkText
} from '../../styles'

function EarningsByBillItem ({
  data: earnings,
  selectedMonth = DateTime.now ().month,
  selectedYear = DateTime.now ().year
}) {
  const now = DateTime.now ()
  const token = AuthService.getToken ()
  const businessId = AuthService.getBusinessId ()
  const periodFilterOptions = Dashboard.TIME_FILTERS
  const [loading, setLoading] = useState (true)
  const [isMounted, setMounted] = useState (false)
  const [periodFilter, setPeriodFilter] = useState (null)
  const [prevPeriodFilter, setPrevPeriodFilter] = useState (null)
  const [showDetails, setShowDetails] = useState (false)
  const [filteredEarnings, setFilteredEarnings] = useState ([])
  const [earningsByMonth, setEarningsByMonth] = useState ([])

  const bills = earnings.filter (earning => earning.type === 'bill')
  const sumItemTotal = (total, item) => total + item.custom_price

  const earningsByBillItem = [
    ['Item de comanda', 'Valor (R$)'],
    [
      'Agendamentos', bills.reduce ((total, bill) => (
        total + bill.schedules.reduce ((total, schedule) => total + schedule.price, 0)
      ), 0)
    ],
    [
      'Serviços adicionais', 
      bills.reduce ((total, bill) => total + bill.services.reduce (sumItemTotal, 0), 0)
    ],
    [
      'Produtos',
      bills.reduce ((total, bill) => total + bill.products.reduce (sumItemTotal, 0), 0)
    ],
    [
      'Pacotes',
      bills.reduce ((total, bill) => total + bill.bundles.reduce (sumItemTotal, 0), 0)
    ]
  ]

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    if (isMounted) setPeriodFilter (periodFilterOptions[0])
  }, [isMounted])

  useEffect (() => {
    getEarningsByMonth (filteredEarnings)
  }, [filteredEarnings])
  
  useEffect (() => {
    if (periodFilter?.value > prevPeriodFilter?.value || !prevPeriodFilter) getData ()
    else filterData ()
  }, [periodFilter])

  const getData = async () => {
    if (showDetails) try {
      setLoading (true)

      const startDate = now.minus ({months: periodFilter.value}).startOf ('month').toISODate ()
      const endDate = prevPeriodFilter
        ? now.minus ({months: prevPeriodFilter.value + 1}).endOf ('month').toISODate ()
        : now.toISODate ()

      const filters = `?business_id=${businessId}&order_by=created_at&order=asc`
                  + '&detailed=true&open=false&deleted=false&page=1&per_page=999999'
                  + `&start_date=${startDate}&end_date=${endDate}`
        
      const { data: bills } = await cutileiApi.get (`/business_bills${filters}`, requestConfig)

      const earnings = bills.map (b => ({...b, type: 'bill'}))

      setFilteredEarnings ([...earnings, ...filteredEarnings])
    } catch (error) {
      console.log (error.response?.data)
    } finally {
      setLoading (false)
    }
  }

  const filterData = () => {
    setLoading (true)
    const startDate = now.minus ({months: periodFilter.value}).startOf ('month')

    setFilteredEarnings (filteredEarnings.filter (earning => (
      DateTime.fromISO (earning.date) >= startDate
    )))

    setTimeout (() => {
      setLoading (false)
    }, 800)
  }

  const getEarningsByMonth = earnings => {
    const earningsByMonth = Dashboard.groupEarningsByMonth (earnings)
      
    setEarningsByMonth ([
      ['Item de comanda', 'Agendamentos', 'Serviços adicionais', 'Produtos', 'Pacotes'],
      ...Object.keys (earningsByMonth).map (month => ([
        month,
        earningsByMonth[month].reduce ((total, bill) => (
          total + bill.schedules.reduce ((total, schedule) => total + schedule.price, 0)
        ), 0),
        earningsByMonth[month].reduce ((total, bill) => total + bill.services.reduce (sumItemTotal, 0), 0),
        earningsByMonth[month].reduce ((total, bill) => total + bill.products.reduce (sumItemTotal, 0), 0),
        earningsByMonth[month].reduce ((total, bill) => total + bill.bundles.reduce (sumItemTotal, 0), 0)
      ]))
    ])
  }

  const handlePeriodFilterSelected = option => {
    setPrevPeriodFilter (periodFilter)
    setPeriodFilter (option)
  }
  
  const toggleDetails = () => {
    if (!isMounted) setMounted (true)
    setShowDetails (!showDetails)
  }

  return (
    <InfoContainer>
      <SubTitle>Faturamento por item de comanda</SubTitle>
      <PieChart data={earningsByBillItem}/>
      <LinkText onClick={toggleDetails}>
        Ver histórico
      </LinkText>
      <Modal
        isOpen={showDetails}
        onRequestClose={toggleDetails}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        style={modalStyle}
      >
        <ModalContainer>
          <SubTitle>Faturamento por item de comanda</SubTitle>
          <CloseButton onClick={toggleDetails}>
            <FA.FaTimes color='#FF3939' size={18}/>
          </CloseButton>
          <FilterContainer>
            <Select
              name='periodFilter'
              placeholder='Período...'
              value={periodFilter}
              options={periodFilterOptions}
              onChange={(_, option) => handlePeriodFilterSelected (option)}
              containerStyles={{boxShadow: '0px 1px 10px -7px', borderRadius: 15, width: 250}}
            />
          </FilterContainer>
          {loading ? (
            <LoadingContainer>
              <Loading/>
              <InfoText>Gerando gráfico...</InfoText>
            </LoadingContainer>
          ) : (
            <ColumnChart
              data={earningsByMonth}
              options={{
                chartArea: {
                  width: '64%',
                  left: 110
                }
              }}
            />
          )}
        </ModalContainer>
      </Modal>
    </InfoContainer>
  )
}

export default EarningsByBillItem
