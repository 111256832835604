import * as Yup from 'yup'
import { isValid as isValidCpf, strip } from "@fnando/cpf"
import cutileiApi from '../services/cutileiApi'

const isNonExistingUser = async (professionalID, key, value) => {
  const query = `?professional_id=${professionalID}`

  try {
    await cutileiApi.post (`/professionals/verify_existing${query}`, {
      [key]: value
    })
    return true
  } catch (error) {
    return false
  }
}

const phoneRegex = /\(|\)| |-/g
const rgRegex = (/^(\d{1,2})(\d{3})(\d{3})([\dX])$/)
const postalCodeRegex = (/^(\d{5})(\d{3})$/)

const professionalPersonalInfoValidator = Yup.object ({
  id: Yup.string (),

  name: Yup.string ()
    .required ('Digite um nome')
    .min (4, 'O nome deve conter no minimo 4 caracteres')
    .max (128, 'O nome deve conter até 128 caracteres'),

  nickname: Yup.string ()
    .min (2, 'O apelido deve conter no minimo 2 caracteres')
    .max (64, 'O apelido deve conter até 64 caracteres'),

  cpf: Yup.string ()
    .required ('Digite o CPF')
    .test ('validCpf', 'Digite um CPF válido', function (value) {
      return isValidCpf (value)
    })
    .test ('newCpf', 'Este CPF já foi cadastrado', function (value) {
      if (value) {
        return isNonExistingUser (this.parent.id, 'cpf', strip (value))
      }
    }),

  rg: Yup.string ()
    .test ('validRG', 'Digite um RG válido', function (value) {
      if (!value) return true
      return rgRegex.test (value)
    })
    .test ('newRg', 'Este RG já foi cadastrado', function (value) {
      if (value) {
        return isNonExistingUser (this.parent.id, 'rg', value)
      }
      return true
    }),

  email: Yup.string ()
    .required ('Digite um email')
    .email ('Insira um email válido')
    .max (128, 'O e-mail deve conter até 128 caracteres')
    .test ('newEmail', 'Este endereço de e-mail já foi cadastrado', function (value) {
      if (value) {
        return isNonExistingUser (this.parent.id, 'email', value)
      }
    }),

  birth: Yup.string ()
    .required ('Digite a data de nascimento')
    .min (8, 'Digite uma data válida (dd/mm/aaaa)'),
  
  phone: Yup.string ()
    .required ('Digite o número de celular')
    .test ('validPhone', 'Insira um número válido', function (value) {
      if (!value) return true
      return value.replace (phoneRegex, '').length === 11
    })
    .test ('newPhone', 'Este número de celular já foi cadastrado', function (value) {
      if (value) {
        return isNonExistingUser (this.parent.id, 'phone', value.replace (phoneRegex, ''))
      }
    }),

  gender: Yup.object ()
    .nullable ()
    .required ('Selecione o gênero'),

  postal_code: Yup
    .string ()
    .required ('Digite o CEP')
    .test ('validPostalCode', 'Digite um CEP válido', function (value) {
      return postalCodeRegex.test (value)
    }),

  number: Yup
    .string ()
    .required ('Digite o número da residência')
    .max (5, 'O numero deve ter no máximo 5 digitos'),

  complement: Yup.string ()
})

export default professionalPersonalInfoValidator
