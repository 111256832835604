import * as Yup from 'yup'

const billValidator = Yup.object ({
  customer: Yup
    .object ()
    .nullable ()
    .required ('Selecione um cliente'),

  date: Yup
    .string ()
    .required ('Selecione uma data')
})

export default billValidator
