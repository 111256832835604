import styled from 'styled-components'

export const InputButton = styled.button.attrs ({
  type: 'button'
})`
  width: 200px;
  align-self: center;
  background-color: #606060;
  color: #FFFFFF;
  padding: 8px;
  border: 0px;
  border-radius: 15px;
  text-align: center;
  text-decoration: none;

  &:hover {
    background-color: #404040;
  }
`
