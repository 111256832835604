import * as Yup from 'yup'

const categoryValidator = Yup.object ({
  name: Yup
    .string ()
    .required ('Insira um nome')
    .max (32, 'O nome deve ter até 32 caracteres'),

  description: Yup
    .string ()
    .max (256, 'A descrição deve ter até 256 caracteres'),
})

export default categoryValidator
