import React, { useState } from 'react'
import AuthService from '../../../../services/auth'
import cutileiApi from '../../../../services/cutileiApi'
import AlertDialog from '../../../AlertDialog'
import { ReactComponent as ButtonLoading } from '../../../../icons/loading2.svg'
import * as FA from 'react-icons/fa'

import {
  Container,
  Row,
  SubTitle,
  InfoText,
  MoneyTextMask,
  Button
} from './styles'

function Bundle ({
  data: bundle,
  onEdit,
  onDelete: handleDelete,
}) {
  const [showAlertDialog, setShowAlertDialog] = useState (false)
  const [deleting, setDeleting] = useState (false)
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const handleEdit = () => onEdit (bundle)

  const deleteBundle = async () => {
    setDeleting (true)
    try {
      await cutileiApi.delete (`/bundles/${bundle.id}`, requestConfig)
      setDeleting (false)
      handleDelete (bundle)
    } catch (error) {
      setDeleting (false)
      console.log (error)
    }
  }

  const toggleAlertDialog = () => setShowAlertDialog (!showAlertDialog)

  return (
    <Container>
      <SubTitle>
        {bundle.name}
      </SubTitle>
      <MoneyTextMask value={bundle.price} style={{justifySelf: 'center'}}/>
      <InfoText>
        {bundle.valid_period === 0 ? 'Indeterminado' : `${bundle.valid_period} dias`}
      </InfoText>
      <Row style={{justifySelf: 'center'}}>
        <Button onClick={handleEdit}>
          <FA.FaEdit color='#35A7FF' size={18}/>
        </Button>
        <Button onClick={toggleAlertDialog}>
          {deleting ? (
            <ButtonLoading style={{margin: 0}}/>
          ) : (
            <FA.FaTrash color='#FF3939' size={16}/>
          )}
        </Button>
      </Row>
      <AlertDialog
        visible={showAlertDialog}
        title='Atenção!'
        message='Deseja realmente excluir este pacote?'
        confirmText='Sim'
        cancelText='Não'
        onConfirm={deleteBundle}
        onClose={toggleAlertDialog}
        dangerous
      />
    </Container>
  )
}

export default Bundle
