import * as Yup from 'yup'

const serviceValidator = Yup.object ({
  name: Yup
    .string ()
    .required ('Insira um nome')
    .max (48, 'O nome deve ter até 48 caracteres'),

  price: Yup
    .number ()
    .required ('Insira o preço'),

  category: Yup
    .object ()
    .nullable ()
    .required ('Selecione a categoria'),

  description: Yup
    .string ()
    .max (256, 'A descrição deve ter até 256 caracteres'),

  cutilei_service: Yup.boolean ()
})

export default serviceValidator
