import React from 'react'
import Category from './Category'

import {
  Container,
  SubTitle,
  LabelRow
} from './styles'

function CategoryList ({ categories, categoryType }) {
  return (
    <Container>
      {categories.length > 0 ? (
        <>
          <SubTitle>
            {categories.length + ' ' + (categories.length === 1 ? 'Resultado' : 'Resultados')}
          </SubTitle>
          <LabelRow>
            <SubTitle>Nome</SubTitle>
            <SubTitle>Descrição</SubTitle>
            <SubTitle>Status</SubTitle>
          </LabelRow>
          {categories.map (category =>
            <Category
              key={category.id}
              data={category}
              categoryType={categoryType}
            />
          )}
        </>
      ) : (
        <SubTitle>
          0 resultados
        </SubTitle>
      )}
    </Container>
  )
}

export default CategoryList
