import React, { useState, useEffect } from 'react'

import {
  CheckToggle,
  ToggleMark
} from './styles'

function RadioButton ({
  value,
  onValueChange,
  circleColor='#FFC100',
  dotColor='#FFC100',
  disabled,
  style
}) {
  const [checked, setChecked] = useState (value)

  useEffect (() => {
    setChecked (value)
  }, [value])

  const handleValueChanged = () => {
    setChecked (prevValue => prevValue !== value ? !checked : prevValue)
    onValueChange (!checked)
  }

  return (
    <CheckToggle 
      style={style}
      color={circleColor}
      disabled={disabled}
      onClick={handleValueChanged}
    >
      {checked && <ToggleMark color={dotColor}/>}
    </CheckToggle>
  )
}

export default RadioButton
