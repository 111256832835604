import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import cutileiApi from '../../../../services/cutileiApi'
import AuthService from '../../../../services/auth'
import AdminNavbar from '../../../../components/AdminNavbar'
import CategoryList from '../../../../components/Lists/CategoryList'
import { ReactComponent as Loading } from '../../../../icons/loading2.svg'
import * as FA from 'react-icons/fa'

import {
  ListContainer,
  Row,
  Button
} from '../styles'

function ProductCategories ({ history }) {
  const [loading, setLoading] = useState (true)
  const [categories, setCategories] = useState ([])
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getData ()
  }, [])

  const getData = async () => {
    try {
      setLoading (true)

      const { data: categories } = await cutileiApi.get ('/product_categories', requestConfig)

      setCategories (categories)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  return (
    <ListContainer>
      <AdminNavbar/>
      <Row>
        <Button onClick={() => history.push ('/admin/product_categories/new')}>
          <FA.FaPlus style={{marginRight: 10}}/>
          Nova categoria de produto
        </Button>
      </Row>
      {loading ? <Loading style={{marginTop: 30}}/> :
        <CategoryList categories={categories}  categoryType='product'/>
      }
    </ListContainer>
  )
}

export default withRouter (ProductCategories)
