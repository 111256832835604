import * as Yup from 'yup'

const phoneRegex = /\(|\)| |-/g

const customerValidator = Yup.object ({
  name: Yup.string ()
    .required ('Digite um nome')
    .min (4, 'O nome deve conter no minimo 4 caracteres')
    .max (128, 'O nome deve conter até 128 caracteres'),

  email: Yup.string ()
    .email ('Insira um email válido')
    .max (128, 'O e-mail deve conter até 128 caracteres'),
    
  phone: Yup.string ()
    .test ('validPhone', 'Insira um número válido', function (value) {
      if (!value) return true
      return value.replace (phoneRegex, '').length === 11
    }),
})

export default customerValidator
