import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 20px 10px 60px;
`

export const Row = styled(Container)`
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;
  margin: 0px 0px 10px;
  z-index: 99;
`

export const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 90vw;
  padding: 5px 10px 10px;
`

export const Header = styled.div`
  display: block;
  position: fixed;
  top: 0;
  background-color: #FFFFFF;
  width: 100%;
  height: 84px;
  z-index: 2;
`

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
`

export const CalendarContainer = styled(Container)`
  position: sticky;
  align-items: center;
  top: 16px;
  padding: 0px;
  margin: 0px;
  z-index: 4;
`

export const Schedules = styled.div`
  padding: 0px 20px;
  display: flex;
  justify-content: flex-start;
`

export const ImageButton = styled.button`
  width: 50px;
  height: 50px;
  box-shadow: 0px 2px 10px -6px #252525;
  padding: 0px;
  border: 0px;
  border-radius: 10px;

  &:hover {
    box-shadow: 0px 2px 10px -4px #252525;
  }
`

export const Image = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 10px;
`

export const Title = styled.h2`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 6px;
  align-self: center;
  z-index: 4;
`

export const SubTitle = styled.h3`
  font-size: 15px;
  font-weight: 500;
  margin: 0px;
`

export const Button = styled.button`
  background-color: #252525;
  color: #F7F7F7;
  width: 100%;
  padding: 8px;
  border: 0px;
  border-radius: 15px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;

  &:hover {
    background-color: #252525;
    color: #F7F7F7;
  }
`

export const SmallButton = styled(Button)`
  color: #FFFFFF;
  background-color: #606060;
  width: 56px;
  height: 39px;
  margin: 0px;
  border-radius: 15px;
  padding: 0px 20px;

  &:hover {
    background-color: #404040;
  }
`

export const LinkButton = styled(Link)`
  background-color: #252525;
  color: #F7F7F7;
  width: 100%;
  padding: 8px;
  border-radius: 15px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;

  &:hover {
    background-color: #252525;
    color: #F7F7F7;
  }
`

export const LinkText = styled(Link)`
  text-decoration: none;
  font-size: 16px;
`

export const Label = styled.p`
  font-size: 16px;
  align-self: center;
  margin-top: 10px;
`

export const TooltipLabel = styled.label`
  text-align: center;
  color: #FFFFFF;
  margin: 0px;
`
