import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
`

export const Input = styled.input`
  width: 100%;
  height: 38px;
  border: 0px;
  box-shadow: 0px 2px 10px -8px #252525;
  border-radius: 15px;
  text-align: center;

  &::placeholder {
    color: #BBBBBB
  }

  &:focus {
    border: 1px solid #CCCCCC !important;
    outline: none;
  }
`

export const EyeToggle = styled.button.attrs ({
  type: 'button'
})`
  display: flex;
  background-color: transparent;
  align-items: center;
  margin-left: -38px;
  text-decoration: none;
  border: 0;
`
