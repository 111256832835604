import React, { useState } from 'react'
import AuthService from '../../../../services/auth'
import cutileiApi from '../../../../services/cutileiApi'
import Phone from '../../../../services/Phone'
import AlertDialog from '../../../AlertDialog'
import { ReactComponent as ButtonLoading } from '../../../../icons/loading2.svg'
import * as FA from 'react-icons/fa'

import {
  Container,
  Row,
  SubTitle,
  InfoText,
  MoneyTextMask,
  Button
} from './styles'

function Customer ({
  data: customer,
  onClick,
  onEdit,
  onDelete: handleDelete
}) {
  const [showAlertDialog, setShowAlertDialog] = useState (false)
  const [deleting, setDeleting] = useState (false)
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }
  
  const handleEdit = e => {
    e?.stopPropagation ()
    onEdit (customer)
  }

  const toggleAlertDialog = e => {
    e?.stopPropagation ()
    setShowAlertDialog (!showAlertDialog)
  }

  const deleteCustomer = async () => {
    setDeleting (true)
    try {
      await cutileiApi.delete (`/customers/${customer.id}`, requestConfig)
      setDeleting (false)
      handleDelete (customer)
    } catch (error) {
      setDeleting (false)
      console.log (error)
    }
  }

  const handleClick = () => onClick (customer)

  return (
    <Container onClick={handleClick}>
      <SubTitle>
        {customer.name}
      </SubTitle>
      <InfoText>
        {customer.email ||'-'}
      </InfoText>
      <InfoText>
        {customer.phone ? Phone.formatPhone (customer.phone) : '-'}
      </InfoText>
      <MoneyTextMask value={customer.balance} style={{justifySelf: 'center'}}/>
      <Row style={{justifySelf: 'center'}}>
        <Button onClick={handleEdit}>
          <FA.FaEdit color='#35A7FF' size={18}/>
        </Button>
        <Button onClick={toggleAlertDialog}>
          {deleting ? (
            <ButtonLoading style={{margin: 0}}/>
          ) : (
            <FA.FaTrash color='#FF3939' size={16}/>
          )}
        </Button>
      </Row>
      <AlertDialog
        visible={showAlertDialog}
        title='Atenção!'
        message='Deseja realmente excluir este cliente?'
        confirmText='Sim'
        cancelText='Não'
        onConfirm={deleteCustomer}
        onClose={toggleAlertDialog}
        dangerous
      />
    </Container>
  )
}

export default Customer
