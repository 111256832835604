import React from 'react'
import { withRouter } from 'react-router-dom'
import Comission from '../../../../services/Comission'
import AdminNavbar from '../../../../components/AdminNavbar'
import { Chart } from 'react-google-charts'

import { 
  Container,
  InfoContainer,
  SubTitle
} from '../styles'

function AdminDailyUsage ({ history }) {
  const { day, daySchedules } = history.location.state
  const schedulesByService = [
    ['Serviço', 'Quantidade'],
    ['Mão', daySchedules.filter (schedule => schedule.service.name === 'Mão').length],
    ['Pé', daySchedules.filter (schedule => schedule.service.name === 'Pé').length],
    ['Pé e mão', daySchedules.filter (schedule => schedule.service.name === 'Pé e mão').length],
    ['Esmaltação - Mão', daySchedules.filter (schedule => schedule.service.name === 'Esmaltação - Mão').length]
  ]

  const chartOptions = {
    is3D: false,
    colors: ['#FF3939', '#44CF6C', '#35A7FF', '#FFC100'],
    pieSliceTextStyle: {
      bold: true
    },
    chartArea: {
      top: 20,
      bottom: 20
    }, 
    legend: {
      alignment: 'center'
    },
    tooltip: {
      trigger: 'selection',
      textStyle: {
        fontSize: 16
      }
    }
  }

  return (
    <Container>
      <AdminNavbar/>
      <InfoContainer style={{height: 'auto', alignItems: 'center'}}>
        <SubTitle> Total do dia {day} </SubTitle>
        <Chart
          chartType='PieChart'
          width='100%'
          height='250px'
          data={schedulesByService}
          options={chartOptions}
        />
      </InfoContainer>

      {/* {daySchedules.map ((schedule, index) => {
        const { business, service, comission_percentage } = schedule

        return (
          <InfoContainer key={index}>
            <Row style={{justifyContent: 'center'}}>
              <SubTitle>
                {business.name}:
              </SubTitle>
              <MoneyTextMask value={service.price}/>
            </Row>
            <Row>
              <Row>
                <InfoText> Comissão do salão: </InfoText>
                <MoneyTextMask value={(service.price * (1 - comission_percentage))}/>
              </Row>
              <Row>
                <InfoText> Comissão dos profissionais: </InfoText>
                <MoneyTextMask value={(service.price * comission_percentage)}/>
              </Row>
            </Row>
          </InfoContainer>
        )
      })} */}
    </Container>
  )
}

export default withRouter (AdminDailyUsage)
