import React from 'react'
import ReactSelect from 'react-select'
import styles, { RemoveOptionContainer } from './styles'
import * as FA from 'react-icons/fa'

export default function Select ({
  name,
  value = '',
  defaultValue,
  touched,
  error,
  options,
  placeholder,
  isMulti = false,
  isClearable = false,
  onChange,
  onBlur = () => null,
  onRemoveOption: handleRemoveOption = () => null,
  DropdownIndicator,
  noOptionsMessage = 'Nenhuma opção encontrada',
  onMenuOpen,
  containerStyles,
  ...props
}) {
  const handleChange = option => {
    onChange (name, option)
  }

  const handleBlur = () => {
    onBlur (name, true)
  }

  const MultiValueRemove = ({ data, innerProps }) => (
    <RemoveOptionContainer
      {...innerProps}
      onClick={e => {
        e.stopPropagation ()
        handleRemoveOption (data)
      }}
    >
      <FA.FaTimes color='#FF3939' size={13}/>
    </RemoveOptionContainer>
  )

  return (
    <ReactSelect
      options={options}
      styles={error && touched
        ? styles ('error', containerStyles)
        : styles ('default', containerStyles)
      }
      value={value}
      defaultValue={defaultValue}
      placeholder={placeholder}
      noOptionsMessage={() => noOptionsMessage}
      isMulti={isMulti}
      isClearable={isClearable}
      onChange={handleChange}
      onBlur={handleBlur}
      components={{
        ...DropdownIndicator !== undefined && {DropdownIndicator},
        MultiValueRemove
      }}
      onMenuOpen={onMenuOpen}
      menuPlacement='auto'
      {...props}
    />
  )
}
