import styled from 'styled-components'
import { NumericFormat } from 'react-number-format'

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`

export const Container = styled(Row)`
  width: 100%;
`

export const InfoText = styled.p`
  color: #000000;
  font-size: 14px;
  text-align: center;
  margin-bottom: 0px;
`

export const MoneyTextMask = styled(NumericFormat).attrs (props => {
  return {
    value: props.value.toFixed (2).replace ('.', ','),
    thousandSeparator: '.',
    decimalSeparator: ',',
    displayType: 'text',
    prefix: 'R$ '
  }
})`
  color: ${({value}) => parseFloat (value.replace (',', '.')) >= 0 ? '#44CF6C' : '#FF3939'};
  font-size: 16px;
  font-weight: bold;
  line-height: 14px;
  margin-bottom: 0px;
  padding: 0px;
`

export const SmallButton = styled.button`
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-color: transparent;
`
