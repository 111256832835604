import React, { useState } from 'react'
import cutileiApi from '../../../services/cutileiApi'
import { Formik } from 'formik'
import emailValidator from '../../../validators/emailValidator'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'

import {
  Form,
  FormField,
  InfoText,
  Input,
  Button,
  WarningText,
  ErrorContainer
} from '../styles'

function RequestCodeForm ({
  onSubmit: handleSubmitForm
}) {
  const [errorMessage, setErrorMessage] = useState (null)

  return (
    <Formik
      validationSchema={emailValidator}
      initialValues={{
        'email': ''
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const { email } = values

        try {
          setSubmitting (true)

          await cutileiApi.post ('/verify_account/businesses', {email})

          setSubmitting (false)
          handleSubmitForm ()
        } catch (error) {
          setSubmitting (false)
          setErrorMessage (error.response?.data.message)
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <Form onSubmit={handleSubmit}>
          <InfoText>
            Insira seu endereço de e-mail e te enviaremos um código para redefinir sua senha
          </InfoText>
          <FormField>
            <Input
              type='email'
              placeholder='E-mail'
              value={values.email}
              onChange={handleChange ('email')}
              onBlur={handleBlur ('email')}
            />
            {touched.email && errors.email && (
              <WarningText>
                {errors.email}
              </WarningText>
            )}
          </FormField>
          {errorMessage && (
            <ErrorContainer>
              <p>{errorMessage}</p>
            </ErrorContainer>
          )}
          <Button type='submit' disabled={isSubmitting}>
            {isSubmitting ? <ButtonLoading/> : 'Enviar código'}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default RequestCodeForm
