import React, { useState } from 'react'
import Modal from 'react-modal'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import Select from '../../../components/Inputs/Select'
import MoneyInput from '../../../components/Inputs/MoneyInput'
import { Formik } from 'formik'
import professionalCreditsValidator from '../../../validators/professionalCreditsValidator'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import * as FA from 'react-icons/fa'
import { DateTime } from 'luxon'

import {
  modalStyle,
  Form,
  FormField,
  Title,
  Input,
  Button,
  CloseButton,
  Row,
  Label,
  InfoText,
  WarningText,
  ErrorContainer
} from './styles'

function AddProfessionalDebtModal ({
  visible,
  professionals,
  onConfirm: handleConfirm = () => null,
  onClose: handleClose
}) {
  const [errorMessage, setErrorMessage] = useState (null)
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      <Formik
        validationSchema={professionalCreditsValidator}
        initialValues={{
          'professional': null,
          'description': '',
          'value': 0,
          'date': DateTime.now ().toFormat ('yyyy-MM-dd')
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const debtValues = {
              ...values,
              professionalId: values.professional.value
            }

            const { data: debt } = await cutileiApi.post (
              '/debts', debtValues, requestConfig
            )

            setSubmitting (false)
            handleConfirm (debt)
            handleClose ()
          } catch (error) {
            if (error.response.data) setErrorMessage (error.response.data.message)
            setSubmitting (false)
            console.log (error)
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Title>Adicionar desconto</Title>
            <CloseButton onClick={handleClose}>
              <FA.FaTimes color='#FF3939' size={18}/>
            </CloseButton>
            <Row>
              <FormField>
                <Select
                  name='professional'
                  placeholder='Profissional...'
                  value={values.professional}
                  options={professionals.map (professional => ({
                    label: professional.nickname ? professional.nickname : professional.name,
                    value: professional.id,
                  }))}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  error={errors.professional}
                  touched={touched.professional}
                />
                {touched.professional && errors.professional && (
                  <WarningText>
                    {errors.professional}
                  </WarningText>
                )}
              </FormField>
              <FormField>
                <Input 
                  placeholder='Descrição'
                  value={values.description}
                  onChange={handleChange ('description')} 
                  onBlur={handleBlur ('description')}
                />
                {touched.description && errors.description && (
                  <WarningText>
                    {errors.description}
                  </WarningText>
                )}
              </FormField>
            </Row>
            <Row>
              <FormField>
                <Label>Valor</Label>
                <MoneyInput
                  name='value'
                  placeholder='Valor'
                  value={values.value}
                  onValueChange={setFieldValue}
                  onBlur={handleBlur ('value')}
                />
                {touched.value && errors.value && (
                  <WarningText>
                    {errors.value}
                  </WarningText>
                )}
              </FormField>
              <FormField>
                <Label>Data referente</Label>
                <Input 
                  type='date'
                  placeholder='Data referente'
                  value={values.date}
                  onChange={handleChange ('date')} 
                  onBlur={handleBlur ('date')}
                />
                {touched.date && errors.date && (
                  <WarningText>
                    {errors.date}
                  </WarningText>
                )}
              </FormField>
            </Row>
            {errorMessage && (
              <ErrorContainer>
                <InfoText>{errorMessage}</InfoText>
              </ErrorContainer>
            )}
            <Button type='submit' disabled={isSubmitting}>
              {isSubmitting ? <ButtonLoading/> : 'Adicionar desconto'}
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default AddProfessionalDebtModal
