import React from 'react'
import ReactSelectAsync from 'react-select/async'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import styles from './styles'

export default function SelectAsync ({
  name,
  value = '',
  loadOptions,
  defaultValue,
  touched,
  error,
  options,
  placeholder,
  isClearable = false,
  onChange,
  onBlur,
  DropdownIndicator,
  loadingMessage = 'Buscando opções',
  noOptionsMessage = 'Nenhuma opção encontrada',
  onMenuOpen,
  containerStyles,
  ...props
}) {
  const handleChange = option => {
    onChange (name, option)
  }

  const handleBlur = () => {
    onBlur (name, true)
  }

  return (
    <ReactSelectAsync
      options={options}
      loadOptions={loadOptions}
      styles={error && touched
        ? styles ('error', containerStyles) 
        : styles ('default', containerStyles)
      }
      value={value}
      defaultValue={defaultValue}
      placeholder={placeholder}
      loadingMessage={() => loadingMessage}
      noOptionsMessage={() => noOptionsMessage}
      isClearable={isClearable}
      onChange={handleChange}
      onBlur={handleBlur}
      components={{
        ...DropdownIndicator !== undefined && {DropdownIndicator},
        LoadingIndicator: () => <Loading style={{height: 18}}/>
      }}
      onMenuOpen={onMenuOpen}
      menuPlacement='auto'
      {...props}
    />
  )
}
