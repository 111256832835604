import React, { useState, useEffect } from 'react'
import AuthService from '../../../../services/auth'
import cutileiApi from '../../../../services/cutileiApi'
import CustomerServiceList from '../../../../components/Lists/CustomerServiceList'
import { ReactComponent as Loading } from '../../../../icons/loading2.svg'
import { DateTime } from 'luxon'

import {
  Container
} from '../styles'

function CustomerBundles ({ customer }) {
  const [loading, setLoading] = useState (true)
  const [customerServices, setCustomerServices] = useState ([])
  const businessId = AuthService.getBusinessId ()
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getData ()
  }, [])

  const getData = async () => {
    try {
      const { data: customerServices } = await cutileiApi.get (
        `/customers/${customer.id}/services?business_id=${businessId}`,
        requestConfig
      )
      
      setCustomerServices (customerServices.sort (orderByBillDate))
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  const orderByBillDate = (a, b) => (
    DateTime.fromISO (b.bill?.date).toMillis () - DateTime.fromISO (a.bill?.date).toMillis ()
  )

  return (
    <Container>
      {loading ? <Loading style={{marginBottom: 20}}/> : (
        <CustomerServiceList
          customer={customer}
          customerServices={customerServices}
        />
      )}
    </Container>
  )
}

export default CustomerBundles
