import React, { useState } from 'react'
import cutileiApi from '../../../services/cutileiApi'
import AlertDialog from '../../../components/AlertDialog'
import { Formik } from 'formik'
import passwordValidator from '../../../validators/passwordValidator'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'

import {
  Form,
  FormField,
  InfoText,
  Input,
  Button,
  WarningText,
  ErrorContainer
} from '../styles'

function ResetPasswordForm ({
  onSubmit: handleSubmitForm,
  userData
}) {
  const [errorMessage, setErrorMessage] = useState (null)
  const [showOkDialog, setShowOkDialog] = useState (false)
  const { business, token } = userData

  const toggleOkDialog = () => setShowOkDialog (!showOkDialog)

  return (
    <Formik
      validationSchema={passwordValidator}
      initialValues={{
        'password': '',
        'password_confirmation': ''
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const { password } = values

        try {
          setSubmitting (true)

          await cutileiApi.put (`/businesses/${business.id}`, {password}, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })

          setSubmitting (false)
          toggleOkDialog ()
        } catch (error) {
          setSubmitting (false)
          setErrorMessage (error.response?.data.message)
          console.log (error)
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <Form onSubmit={handleSubmit}>
          <InfoText>
            Insira e confirme sua nova senha
          </InfoText>
          <FormField>
            <Input
              placeholder='Senha'
              type='password'
              value={values.password}
              onChange={handleChange ('password')}
              onBlur={handleBlur ('password')}
            />
            {touched.password && errors.password && (
              <WarningText>
                {errors.password}
              </WarningText>
            )}
          </FormField>
          <FormField>
            <Input
              placeholder='Confirmar senha'
              type='password'
              value={values.password_confirmation}
              onChange={handleChange ('password_confirmation')}
              onBlur={handleBlur ('password_confirmation')}
            />
            {touched.password_confirmation && errors.password_confirmation && (
              <WarningText>
                {errors.password_confirmation}
              </WarningText>
            )}
          </FormField>
          {errorMessage && (
            <ErrorContainer>
              <p>{errorMessage}</p>
            </ErrorContainer>
          )}
          <AlertDialog
            visible={showOkDialog}
            title='Sucesso'
            message='Senha alterada!'
            confirmText='Ok'
            onConfirm={handleSubmitForm}
            onClose={handleSubmitForm}
          />
          <Button type='submit' disabled={isSubmitting}>
            {isSubmitting ? <ButtonLoading/> : 'Redefinir senha'}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default ResetPasswordForm
