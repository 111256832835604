import * as Yup from 'yup'

const createServiceValidator = Yup.object ({
  name: Yup
    .string ()
    .required ('Insira um nome')
    .max (48, 'O nome deve ter até 48 caracteres'),

  category: Yup
    .object ()
    .nullable ()
    .required ('Selecione a categoria'),

  description: Yup
    .string ()
    .max (256, 'A descrição deve ter até 256 caracteres')
})

export default createServiceValidator
