import React, { useState } from 'react'
import { Formik } from 'formik'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import NumberInput from '../../Inputs/NumberInput'
import MoneyInput from '../../Inputs/MoneyInput'
import Select from '../../Inputs/Select'
import tipValidator from '../../../validators/tipValidator'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import * as FA from 'react-icons/fa'

import {
  Form,
  FormField,
  Button,
  CloseButton,
  FieldGrid,
  Row,
  InfoText,
  Label,
  Input,
  WarningText,
  ErrorContainer,
} from './styles'

function BillTipForm ({
  bill,
  professionals,
  onConfirm: handleConfirm,
  onClose: handleClose
}) {
  const [errorMessage, setErrorMessage] = useState (null)
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  return (
    <Formik
      validationSchema={tipValidator}
      initialValues={{
        'professional': null,
        'description': '',
        'value': 0,
        'comission_percentage': 100
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const tipData = {
          billId: bill.id,
          professionalId: values.professional.value,
          description: values.description,
          value: values.value,
          comissionPercentage: values.comission_percentage / 100
        }

        try {
          const { data: tip } = await cutileiApi.post ('/tips', tipData, requestConfig)

          setSubmitting (false)
          handleConfirm (tip)
          handleClose ()
        } catch (error) {
          if (error.response.data) setErrorMessage (error.response.data.message)
          setSubmitting (false)
          console.log (error)
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <Form>
          <FieldGrid>
            <FormField>
              {touched.professional && errors.professional && (
                <WarningText>
                  {errors.professional}
                </WarningText>
              )}
              <Select
                name='professional'
                placeholder='Profissional...'
                value={values.professional}
                options={professionals.map (professional => ({
                  label: professional.nickname || professional.name.split (' ')[0], value: professional.id
                }))}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                error={errors.professional}
                touched={touched.professional}
              />
            </FormField>
            <FormField>
              <Input
                type='text'
                name='description'
                placeholder='Descrição'
                value={values.description}
                onChange={handleChange ('description')}
                onBlur={handleBlur ('description')}
              />
            </FormField>
            <FormField>
              <Label htmlFor='tip_value'>
                Valor
              </Label>
              <MoneyInput
                id='tip_value'
                name='value'
                placeholder='Valor'
                value={values.value}
                onValueChange={setFieldValue}
                onBlur={handleBlur ('value')}
              />
            </FormField>
            <FormField>
              <Label htmlFor='tip_comission_percentage'>
                Comissão (%)
              </Label>
              <NumberInput
                id='tip_comission_percentage'
                name='comission_percentage'
                value={values.comission_percentage}
                minValue={0}
                maxValue={100}
                onChange={setFieldValue}
                style={{width: '100%'}}
              />
            </FormField>
            <Button type='button' onClick={handleSubmit} disabled={isSubmitting}>
              {isSubmitting ? <ButtonLoading/> : 'Adicionar'}
            </Button>
            <CloseButton onClick={handleClose}>
              <FA.FaTimes color='#FF3939' size={18}/>
            </CloseButton>
          </FieldGrid>
          <Row>
            {errorMessage && (
              <ErrorContainer>
                <InfoText>{errorMessage}</InfoText>
              </ErrorContainer>
            )}
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default BillTipForm
