import React from 'react'

import {
  Container,
  Row,
  Image,
  InfoText
} from './styles'

function Member ({
  data: member,
}) {
  return (
    <Container to={{
      pathname: `/admin/members/${member.id}`,
      state: {member}
    }}>
      <Row>
        <Image src={member.profile_picture}/>
        <InfoText>
          { member.name }
        </InfoText>
      </Row>
    </Container>
  )
}

export default Member
