import * as Yup from 'yup'

const bundleValidator = Yup.object ({
  name: Yup.string ()
    .required ('Digite um nome')
    .min (4, 'O nome deve conter no minimo 4 caracteres')
    .max (64, 'O nome deve conter até 64 caracteres'),

  price: Yup.number (),
  discountPrice: Yup.number ().nullable (),
  discountPercentage: Yup.number (),
  comissionPercentage: Yup.number (),

  comissionType: Yup.object ()
    .nullable ()
    .required ('Selecione o modelo de comissão dos serviços'),

  services: Yup.array ().of (Yup.object ().shape ({
    id: Yup.string (),
    name: Yup.string (),
    price: Yup.number ().positive ('Insira o preço'),
    amount: Yup.number ()
  })),

  validPeriod: Yup
    .string ()
    .required ('Insira o período de validade do pacote'),
})

export default bundleValidator
