import styled from 'styled-components'

export const InfoContainer = styled.div`
  display: flex;
  min-width: 480px;
  width: 640px;
  box-shadow: 0px 2px 10px -6px #252525;
  border-radius: 15px;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 15px;
  margin-bottom: 10px;
`

export const ProfessionalContainer = styled.div`
  min-width: 920px;
  width: 980px;
  height: 40px;
  display: grid;
  grid-template-columns: ${({bonuses, discounts}) => {
    let numColumns = 4
    if (bonuses?.length > 0) numColumns++
    if (discounts?.length > 0) numColumns++
    return `160px repeat(${numColumns}, 1fr)`
  }};
  box-shadow: 0px 2px 10px -6px #252525;
  border-radius: 15px;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  padding: 8px 15px !important;
  margin-bottom: 10px;
  text-decoration: none;

  &:hover {
    background-color: #F7F7F7;
  }
`

export const LabelRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr repeat(3, 2fr);
  justify-content: center;
  align-items: center;
  justify-items: center;
  margin-bottom: 7px;
  padding: 0px 15px;
  min-width: 720px;
  width: 960px;
`

export const ProfessionalLabelRow = styled(LabelRow)`
  grid-template-columns: ${({bonuses, discounts}) => {
    let numColumns = 4
    if (bonuses?.length > 0) numColumns++
    if (discounts?.length > 0) numColumns++
    return `160px repeat(${numColumns}, 1fr)`
  }};
  min-width: 920px;
  width: 980px;
  align-items: flex-end;
`

export const EarningContainer = styled(InfoContainer)`
  display: grid;
  grid-template-columns: 2fr 1fr 2fr 2fr 2fr;
  justify-content: center;
  align-items: center;
  justify-items: center;
  min-width: 720px;
  width: 960px;
  height: auto;
`

export const Image = styled.img`
  width: 20px;
  height: 20px;
`
