import React, { useState, useEffect } from 'react'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import Dashboard from '../../../services/Dashboard'
import Select from '../../../components/Inputs/Select'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import { DateTime } from 'luxon'

import {
  DashboardContainer,
  LoadingContainer,
  InfoText,
  FilterContainer
} from '../styles'

function Sales () {
  const now = DateTime.now ()
  const [loading, setLoading] = useState (true)
  const [periodFilter, setPeriodFilter] = useState (null)
  const [businessEarnings, setBusinessEarnings] = useState ([])
  const token = AuthService.getToken ()
  const businessId = AuthService.getBusinessId ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getData ()
  }, [])

  const getData = async () => {
    try {
      setLoading (true)

      const startDate = now.minus ({months: periodFilter.value}).startOf ('month').toISODate ()
      const endDate = now.toISODate ()

      const filters = `?business_id=${businessId}&order_by=created_at&order=desc&cutilei_schedules=true`
                    + '&detailed=true&open=false&status=validated&deleted=false&page=1&per_page=999'
                    + `&start_date=${startDate}&end_date=${endDate}`
        
      const { data: schedules } = await cutileiApi.get (`/schedules${filters}`, requestConfig)
      const { data: bills } = await cutileiApi.get (`/business_bills${filters}`, requestConfig)

      const businessEarnings = [
        ...schedules.data.map (s => ({...s, type: 'cutilei'})),
        ...bills.map (b => ({...b, type: 'bill'}))
      ]
      
      setBusinessEarnings (businessEarnings)
    } catch (error) {
      console.log (error.response?.data)
    } finally {
      setLoading (false)
    }
  }

  return (
    <DashboardContainer>
      <FilterContainer>
        <Select
          name='periodFilter'
          placeholder='Período...'
          value={periodFilter}
          options={Dashboard.TIME_FILTERS}
          onChange={(_, option) => setPeriodFilter (option)}
          containerStyles={{boxShadow: '0px 1px 10px -7px', borderRadius: 15, width: 250}}
        />
      </FilterContainer>
      {loading ? (
        <LoadingContainer>
          <Loading/>
          <InfoText>Gerando gráficos...</InfoText>
        </LoadingContainer>
      ) : (
        <>
        </>
      )}
    </DashboardContainer>
  )
}

export default Sales
