import React, { useState } from 'react'
import cutileiApi from '../../services/cutileiApi'
import AuthService from '../../services/auth'
import AlertDialog from '../AlertDialog'
import { withRouter } from 'react-router-dom'
import * as FA from 'react-icons/fa'
import { DateTime } from 'luxon'

import { 
  Container,
  Row,
  SubTitle,
  InfoText,
  MoneyTextMask,
  DangerButton
} from './styles'

function Bill ({ data: bill, history }) {
  const [showMore, setShowMore] = useState (false)
  const [showAlertDialog, setShowAlertDialog] = useState (false)
  const token = AuthService.getToken ()

  const STATUS_LIST = {
    paid: 'Paga',
    pending: 'A pagar',
    canceled: 'Cancelada'
  }

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const toggleShowMore = () => setShowMore (!showMore)
  const toggleAlertDialog = () => setShowAlertDialog (!showAlertDialog)

  const refundBill = async () => {
    try {
      await cutileiApi.delete (`/charges/${bill.charges[0].id}`, requestConfig)
      history.replace ('/business')
    } catch (error) {
      console.log (error)
    }
  }

  return (
    <Container onClick={toggleShowMore}>
      <Row>
        <SubTitle> {bill.customer.name} </SubTitle>
        <Row>
          <MoneyTextMask value={parseFloat (bill.amount)}/>
          {showMore ? (
            <FA.FaChevronUp size={15} color='#252525'/>
          ) : (
            <FA.FaChevronDown size={15} color='#252525'/>
          )}
        </Row>
      </Row>
      {showMore && (
        <>
          <Row style={{marginTop: 10}}>
            <Row>
              <InfoText> Status: </InfoText>
              <SubTitle> {STATUS_LIST[bill.status]} </SubTitle>
            </Row>
            <Row>
              <InfoText> Plano: </InfoText>
              <SubTitle> {bill.subscription.plan.name} </SubTitle>
            </Row>
          </Row>
          <Row style={{marginTop: 10}}>
            <Row>
              <InfoText> Gerada em: </InfoText>
              <SubTitle>
                {DateTime.fromISO (bill.created_at).toFormat ('dd/MM/yyyy - H:mm')}
              </SubTitle>
            </Row>
            <Row>
              <InfoText> Última cobrança: </InfoText>
              <SubTitle> 
                {DateTime.fromISO (bill.charges[0].created_at).toFormat ('dd/MM/yyyy - H:mm')}
              </SubTitle>
            </Row>
          </Row>
          <Row style={{marginTop: 10}}>
            <DangerButton onClick={toggleAlertDialog}>
              Estornar
            </DangerButton>
          </Row>
        </>
      )}
      <AlertDialog
        visible={showAlertDialog}
        title='Atenção!'
        message='Deseja realmente estornar esta fatura?'
        confirmText='Sim'
        cancelText='Não'
        onConfirm={refundBill}
        onClose={toggleAlertDialog}
        dangerous
      />
    </Container>
  )
}

export default withRouter (Bill)
