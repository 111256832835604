import React from 'react'
import Modal from 'react-modal'
import ScheduleList from '../../../components/Lists/ScheduleList'
import * as FA from 'react-icons/fa'

import {
  modalStyle,
  Container,
  Header,
  CloseButton,
  Title,
} from './styles'

function SchedulesModal ({
  visible,
  schedules,
  onClose: handleClose
}) {
  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      <Container>
        <Header>
          <Title>Agendamentos</Title>
          <CloseButton onClick={handleClose}>
            <FA.FaTimes color='#FF3939' size={18}/>
          </CloseButton>
        </Header>
        <ScheduleList
          schedules={schedules}
          totalSchedules={schedules.length}
          hasMore={false}
          onScroll={() => null}
          detailed
        />
      </Container>
    </Modal>
  )
}

export default SchedulesModal
