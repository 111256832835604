import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import Select from '../../../components/Inputs/Select'
import MoneyInput from '../../../components/Inputs/MoneyInput'
import NumberInput from '../../../components/Inputs/NumberInput'
import { Formik } from 'formik'
import createProductValidator from '../../../validators/createProductValidator'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import * as FA from 'react-icons/fa'

import {
  modalStyle,
  Form,
  FormField,
  Title,
  Input,
  Button,
  CloseButton,
  Row,
  Label,
  InfoText,
  WarningText,
  ErrorContainer
} from './styles'

function AddProductModal ({
  visible,
  productCategories = [],
  onConfirm: handleConfirm,
  onClose: handleClose
}) {
  const [errorMessage, setErrorMessage] = useState (null)
  const businessId = AuthService.getBusinessId ()
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const calculateComissionValue = (price, comissionPercentage) => {
    return Math.round (price * (comissionPercentage / 100 || 0))
  }

  const calculateComissionPercentage = (price, comissionValue) => {
    return Math.round ((comissionValue / price) * 100)
  }

  const recalcutaleComissionValue = (values, setFieldValue) => {
    const comissionValue = calculateComissionValue (values.price, values.comission_percentage)
    setFieldValue ('professional_comission', comissionValue)
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      <Formik
        validationSchema={createProductValidator}
        initialValues={{
          'name': '',
          'brand': '',
          'description': '',
          'category': null,
          'stock': 0,
          'price': 0,
          'comission_percentage': 40,
          'professional_comission': 0,
          'business_comission': 0
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const { category, comission_percentage, ...productData } = values

          try {
            const { data: product } = await cutileiApi.post ('/products', {
              productCategoryId: category.value,
              businessId: businessId,
              comission_percentage: comission_percentage / 100,
              ...productData
            }, requestConfig)

            setSubmitting (false)
            handleConfirm (product)
            handleClose ()
          } catch (error) {
            setSubmitting (false)
            setErrorMessage (error.response?.data.message)
            console.log (error)
          }
        }}
      >
        {function AddServiceForm ({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          handleSubmit
        }) {
          useEffect (() => {
            recalcutaleComissionValue (values, setFieldValue)
          }, [values.price])

          useEffect (() => {
            setFieldValue ('business_comission', Math.abs (values.price - values.professional_comission))
          }, [values.price, values.comission_percentage, values.professional_comission])

          return (
            <Form onSubmit={handleSubmit}>
              <Title>Cadastrar produto</Title>
              <CloseButton onClick={handleClose}>
                <FA.FaTimes color='#FF3939' size={18}/>
              </CloseButton>
              <Row>
                <FormField>
                  <Input 
                    placeholder='Nome'
                    value={values.name}
                    onChange={handleChange ('name')} 
                    onBlur={handleBlur ('name')}
                  />
                  {touched.name && errors.name && (
                    <WarningText>
                      {errors.name}
                    </WarningText>
                  )}
                </FormField>
                <FormField>
                  <Input
                    placeholder='Marca'
                    value={values.brand}
                    onChange={handleChange ('brand')}
                    onBlur={handleBlur ('brand')}
                  />
                  {touched.brand && errors.brand && (
                    <WarningText>
                      {errors.brand}
                    </WarningText>
                  )}
                </FormField>
              </Row>
              <Row>
                <FormField>
                  <Select
                    name='category'
                    placeholder='Categoria...'
                    value={values.category}
                    options={productCategories}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.category}
                    touched={touched.category}
                  />
                  {touched.category && errors.category && (
                    <WarningText>
                      {errors.category}
                    </WarningText>
                  )}
                </FormField>
                <FormField>
                  <Input 
                    placeholder='Descrição (opcional)'
                    value={values.description}
                    onChange={handleChange ('description')} 
                    onBlur={handleBlur ('description')}
                  />
                  {touched.description && errors.description && (
                    <WarningText>
                      {errors.description}
                    </WarningText>
                  )}
                </FormField>
              </Row>
              <Row>
                <FormField>
                  <Label htmlFor='stock'>
                    Qtd. estoque
                  </Label>
                  <NumberInput
                    id='stock'
                    name='stock'
                    value={values.stock}
                    minValue={0}
                    onChange={setFieldValue}
                  />
                </FormField>
                <FormField>
                  <Label htmlFor='price'>
                    Preço (R$)
                  </Label>
                  <MoneyInput
                    id='price'
                    name='price'
                    placeholder='Preço'
                    value={values.price}
                    onValueChange={setFieldValue}
                    onBlur={handleBlur ('price')}
                  />
                  {touched.price && errors.price && (
                    <WarningText>
                      {errors.price}
                    </WarningText>
                  )}
                </FormField>
                <FormField>
                  <Label htmlFor='comission_percentage'>
                    Comissão (%)
                  </Label>
                  <NumberInput
                    id='comission_percentage'
                    name='comission_percentage'
                    placeholder='Comissão (%)'
                    value={values.comission_percentage}
                    minValue={0}
                    maxValue={100}
                    onChange={(name, value) => {
                      setFieldValue (name, value)
                      setFieldValue ('professional_comission', calculateComissionValue (values.price, value))
                    }}
                    onBlur={handleBlur}
                  />
                </FormField>
              </Row>
              <Row>
                <FormField>
                  <Label htmlFor='professional_comission'>
                    Valor (profissional)
                  </Label>
                  <MoneyInput
                    id='professional_comission'
                    name='professional_comission'
                    placeholder='Valor líquido'
                    value={values.professional_comission}
                    maxValue={values.price}
                    onValueChange={(name, value) => {
                      setFieldValue (name, value)
                      setFieldValue ('comission_percentage', calculateComissionPercentage (values.price, value))
                    }}
                    onBlur={handleBlur ('professional_comission')}
                    style={{color: '#44CF6C', fontSize: 16, fontWeight: 'bold'}}
                  />
                </FormField>
                <FormField>
                  <Label htmlFor='business_comission'>
                    Valor (salão)
                  </Label>
                  <MoneyInput
                    id='business_comission'
                    name='business_comission'
                    value={values.business_comission}
                    style={{color: '#44CF6C', fontSize: 16, fontWeight: 'bold'}}
                    disabled
                  />
                </FormField>
              </Row>
              {errorMessage && (
                <ErrorContainer>
                  <InfoText>{errorMessage}</InfoText>
                </ErrorContainer>
              )}
              <Button type='submit' disabled={isSubmitting}>
                {isSubmitting ? <ButtonLoading/> : 'Criar produto'}
              </Button>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default AddProductModal
