import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Comission from '../../../../services/Comission'
import Navbar from '../../../../components/Navbar'
import BusinessBillList from '../../../../components/Lists/BusinessBillList'
import ProfessionalCreditList from '../../../../components/Lists/ProfessionalCreditList'
import ScheduleList from '../../../../components/Lists/ScheduleList'

import { 
  Container, 
  InfoText, 
  InfoContainer,
  Row,
  TabButton,
  Info,
  SubTitle,
  MoneyTextMask
} from '../../styles'

function Daily ({ history }) {
  const { day, earningType, category, selectedProfessional } = history.location.state
  const [dayEarnings, setDayEarnings] = useState (history.location.state.dayEarnings)
  const [bonuses, setBonuses] = useState ([])
  const [discounts, setDiscounts] = useState ([])
  const [bonusTotal, setBonusTotal] = useState (0)
  const [discountTotal, setDiscountTotal] = useState (0)
  const dailyTotalEarnings = Comission.getTotalEarnings (dayEarnings, earningType, category)
  const dailyBusinessEarnings = Comission.getBusinessEarnings (dayEarnings, earningType, category)
  const dailyProfessionalEarnings = Comission.getProfessionalEarnings (dayEarnings, earningType, category)
  const [filteredDayEarnings, setFilteredDayEarnings] = useState ([])
  const [typeOption, setTypeOption] = useState (earningType.value === 'all_earnings' ? {
    label: 'Comandas do salão', value: 'bill'
  } : earningType)

  const TYPE_OPTION_LIST = [
    {label: 'Comandas do salão', value: 'bill'},
    {label: 'Agendamentos Cutilei', value: 'cutilei'},
    bonuses.length > 0 && {label: 'Bônus', value: 'bonus'},
    discounts.length > 0 && {label: 'Descontos', value: 'discount'}
  ].filter (o => o)
  
  useEffect (() => {
    handleTypeOptionSelected (typeOption)
  }, [])

  useEffect (() => {
    setBonuses (dayEarnings.filter (earning => earning.type === 'bonus'))
    setDiscounts (dayEarnings.filter (earning => earning.type === 'discount'))
  }, [dayEarnings])

  useEffect (() => {
    setBonusTotal (bonuses.reduce ((total, bonus) => total + bonus.value, 0))
  }, [bonuses])

  useEffect (() => {
    setDiscountTotal (discounts.reduce ((total, bonus) => total + bonus.value, 0))
  }, [discounts])

  const handleTypeOptionSelected = typeOption => {
    setFilteredDayEarnings (dayEarnings.filter (e => e.type === typeOption.value))
    setTypeOption (typeOption)
  }

  const handleDeleteCredit = credit => {
    const index = TYPE_OPTION_LIST.findIndex (o => o && o.value === typeOption.value)
    const filteredEarnings = dayEarnings.filter (e => e.id !== credit.id)
    
    setDayEarnings (filteredEarnings)
    setFilteredDayEarnings (filteredEarnings)

    if (!filteredEarnings.some (e => e.type === credit.type))
      handleTypeOptionSelected (TYPE_OPTION_LIST [index - 1])
  }

  return (
    <Container>
      <Navbar/>
      <InfoContainer style={{alignItems: 'center'}}>
        <SubTitle style={{marginBottom: 7}}>Total do dia - {day}</SubTitle>
        <Info style={{alignItems: 'center', height: 'auto'}}>
          <Row>
            <InfoText>Faturamento bruto:</InfoText>
            <MoneyTextMask value={dailyTotalEarnings}/>
          </Row>
          <Row>
            <InfoText>
              Comissão {selectedProfessional.value === 'all_professionals' ? 'dos profissionais: ' : 'do profissional: '}
            </InfoText>
            <MoneyTextMask value={dailyProfessionalEarnings}/>
          </Row>
          {bonuses.length > 0 && (
            <Row>
              <InfoText>Bônus do profissional:</InfoText>
              <MoneyTextMask value={bonusTotal ?? 0}/>
            </Row>
          )}
          {discounts.length > 0 && (
            <Row>
              <InfoText>Descontos do profissional:</InfoText>
              <MoneyTextMask value={discountTotal ?? 0}/>
            </Row>
          )}
          {(bonuses.length > 0 || discounts.length > 0) && (
            <Row>
              <InfoText>Total do profissional:</InfoText>
              <MoneyTextMask value={dailyProfessionalEarnings + bonusTotal ?? 0 + discountTotal ?? 0}/>
            </Row>
          )}
          <Row>
            <SubTitle>Comissão do salão:</SubTitle>
            <MoneyTextMask value={dailyBusinessEarnings}/>
          </Row>
        </Info>
      </InfoContainer>
      <Row>
        {TYPE_OPTION_LIST.map ((o, index) =>
          <TabButton
            key={index}
            selected={typeOption.value === o.value}
            color={typeOption.value === o.value ? '#FFC100' : '#FFFFFF'}
            onClick={() => handleTypeOptionSelected (o)}
            style={{width: 190}}
          >
            {o.label}
          </TabButton>
        )}
      </Row>
      {typeOption.value === 'cutilei' ? (
        <ScheduleList
          schedules={filteredDayEarnings}
          totalSchedules={filteredDayEarnings.length}
          hasMore={false}
          onScroll={() => null}
          detailed
        />
      ) : typeOption.value === 'bill' ? (
        <BusinessBillList
          bills={filteredDayEarnings}
          type={typeOption}
          category={category}
          showComissions={true}
          onDelete={bill => setFilteredDayEarnings (filteredDayEarnings.filter (e => e.id !== bill.id))}
        />
      ) : (
        <ProfessionalCreditList
          credits={filteredDayEarnings}
          type={typeOption.value}
          onEdit={credit => {
            setDayEarnings (dayEarnings.map (e => e.id === credit.id ? {...credit} : e))
          }}
          onDelete={handleDeleteCredit}
          styles={{width: 960, minWidth: 840}}
        />
      )}
    </Container>
  )
}

export default withRouter (Daily)
