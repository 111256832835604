import { DateTime } from 'luxon'

export default class CutileiReservation {
  static PERIODS = [
    {label: '1 dia', value: 0},
    {label: 'Personalizado', value: -2}
  ]

  static DESCRIPTIONS = [
    {label: 'Feriado', value: 'Feriado'},
    {label: 'Natal', value: 'Natal', day: 25, month: 12},
    {label: 'Ano novo', value: 'Ano novo', day: 1, month: 1}
  ]

  static getPeriod = reservation => {
    const period = DateTime.fromISO (reservation.end_date)
      .diff (DateTime.fromISO (reservation.start_date), ['days', 'months']).toObject ()

    const label = period.days === 0 ? '1 dia' : 'Personalizado'
    const value = period.days === 0 ? period.days : -2

    return { label, value }
  }

  static handleDescriptionChange = (name, option, setFieldValue) => {
    const { day, month } = option

    if (day && month) {
      const now = DateTime.now ()
      const currentYear = now.year
      const selectedDate = DateTime.local (currentYear, month, day)

      const startDate = selectedDate.plus ({years: selectedDate < now ? 1 : 0})
  
      setFieldValue ('startDate', startDate.toISODate ())
    }

    setFieldValue (name, option)
  }

  static handlePeriodChange = (name, option, values, setFieldValue) => {
    const startDate = DateTime.fromISO (values.startDate)
    const interval = option.value === -2 ? null : {days: option.value}

    if (interval) {
      setFieldValue ('endDate', startDate.plus (interval).toISODate ())
    } else if (startDate) {
      setFieldValue ('endDate', startDate.plus ({days: 1}).toISODate ())
    }

    setFieldValue (name, option)
  }
}
