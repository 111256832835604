import React, { useState, useEffect } from 'react'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import Select from '../../../components/Inputs/Select'
import ClearableInput from '../../../components/Inputs/ClearableInput'
import ProfessionalList from '../../../components/Lists/ProfessionalList'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'

import {
  Container,
  Row
} from '../styles'

function ConfigProfessionals () {
  const [loading, setLoading] = useState (true)
  const [professionals, setProfessionals] = useState ([])
  const [status, setStatus] = useState ({label: 'Ativos', value: 'active'})
  const [name, setName] = useState ('')
  const user = AuthService.getUser ()
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getProfessionals ()
  }, [name, status])

  const getProfessionals = async () => {
    let filters = 'order_by=number'
    if (status.value === 'deleted') filters += '&deleted=true'
    else if ((status.value === 'all')) filters += ''
    else filters += `&status=${status.value}`
    if (name) filters += `&name=${name}`

    try {
      setLoading (true)
      const { data } = await cutileiApi.get (
        `/businesses/${user.id}/professionals?${filters}`, requestConfig
      )

      setProfessionals (data)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  return (
    <Container>
      <Row style={{gap: 10, width: 600}}>
        <Select
          name='status'
          placeholder='Status...'
          value={status}
          options={[
            {label: 'Ativos', value: 'active'},
            {label: 'Inativos', value: 'inactive'},
            {label: 'Excluídos', value: 'deleted'},
            {label: 'Todos', value: 'all'}
          ]}
          onChange={(_, option) => setStatus (option)}
          containerStyles={{boxShadow: '0px 1px 10px -7px', borderRadius: 15, height: 38}}
        />
        <ClearableInput
          placeholder='Pesquisar por nome'
          value={name}
          onChange={e => setName (e.target.value)}
          onClear={() => setName ('')}
          inputStyle={{boxShadow: '0px 1px 10px -6px', paddingInline: 12}}
        />
      </Row>
      {loading ? <Loading/> :
        <ProfessionalList professionals={professionals}/>
      }
    </Container>
  )
}

export default ConfigProfessionals
