import React, { useState, useEffect } from 'react'
import AuthService from '../../../../services/auth'
import cutileiApi from '../../../../services/cutileiApi'
import CustomerCreditList from '../../../../components/Lists/CustomerCreditList'
import AddCustomerCreditModal from '../../AddCustomerCreditModal'
import AddCustomerDebtModal from '../../AddCustomerDebtModal'
import { ReactComponent as Loading } from '../../../../icons/loading2.svg'
import * as FA from 'react-icons/fa'
import { DateTime } from 'luxon'

import {
  Container,
  Row,
  Button
} from '../styles'

function CustomerCredits ({ customer }) {
  const [loading, setLoading] = useState (true)
  const [credits, setCredits] = useState ([])
  const [showAddCreditModal, setShowAddCreditModal] = useState (false)
  const [showAddDebtModal, setShowAddDebtModal] = useState (false)
  const businessId = AuthService.getBusinessId ()
  const token = AuthService.getToken ()
  
  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getCredits ()
  }, [])

  const getCredits = async () => {
    try {
      const { data: credits } = await cutileiApi.get (
        `/credits?business_id=${businessId}&customer_id=${customer.id}`,
        requestConfig
      )

      const { data: debts } = await cutileiApi.get (
        `/debts?business_id=${businessId}&customer_id=${customer.id}`,
        requestConfig
      )

      setCredits ([
        ...credits.map (credit => ({...credit, type: 'credit'})),
        ...debts.map (debt => ({...debt, value: debt.value * -1, type: 'debt'}))
      ].sort (compareCredits))
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  const compareCredits = (a, b) => (
    DateTime.fromISO (b.created_at).toMillis () - DateTime.fromISO (a.created_at).toMillis ()
  )

  const toggleAddCreditModal = () => setShowAddCreditModal (!showAddCreditModal)
  const toggleAddDebtModal = () => setShowAddDebtModal (!showAddDebtModal)

  return (
    <Container style={{paddingInline: 10}}>
      <Row>
        <Button onClick={toggleAddCreditModal}>
          <FA.FaPlus size={12} style={{marginTop: 2, marginRight: 7}}/>
          Crédito
        </Button>
        <Button onClick={toggleAddDebtModal}>
          <FA.FaPlus size={12} style={{marginTop: 2, marginRight: 7}}/>
          Dívida
        </Button>
      </Row>
      {loading ? <Loading style={{marginBottom: 20}}/> : (
        <CustomerCreditList
          credits={credits}
          onEdit={credit => setCredits (credits.map (c => c.id === credit.id ? {...credit} : c))}
          onDelete={credit => setCredits (credits.filter (c => c.id !== credit.id))}
        />
      )}
      <AddCustomerCreditModal
        visible={showAddCreditModal}
        customer={customer}
        onConfirm={credit => setCredits ([...credits, credit].sort (compareCredits))}
        onClose={toggleAddCreditModal}
      />
      <AddCustomerDebtModal
        visible={showAddDebtModal}
        customer={customer}
        onConfirm={
          debt => setCredits ([...credits, {...debt, value: debt.value * -1}].sort (compareCredits))
        }
        onClose={toggleAddDebtModal}
      />
    </Container>
  )
}

export default CustomerCredits
