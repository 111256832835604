import styled from 'styled-components'
import Cleave from 'cleave.js/react'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 20px;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 540px;
  box-shadow: 0px 2px 10px -6px #252525;
  padding: 10px;
  border-radius: 15px;
  margin: 20px;

  @media (max-width: 640px) {
    width: 100%;
  }
`

export const LinkContainer = styled(Link)`
  background-color: #FFFFFF;
  width: 100%;
  margin: 0px 0px 8px;
  box-shadow: 0px 2px 10px -6px #252525;
  text-decoration: none;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;

  &:hover {
    background-color: #F7F7F7;
  }
`

export const Image = styled.img`
  width: 120px;
  height: 120px;
  box-shadow: 0px 2px 10px -6px #252525;
  border-radius: 20px;
  margin-bottom: 20px;
`

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
`

export const Row = styled(Container)`
  width: 100%;
  padding: 0px;
  flex-direction: row;
  align-items: flex-start;
`

export const InfoText = styled.p`
  font-size: 16px;
  text-align: center;
  margin: 0px;
`

export const Title = styled(InfoText)`
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
`

export const SubTitle = styled(Title)`
  font-size: 16px;
  margin-bottom: 0px;
`

export const MoneyText = styled(SubTitle)`
  color: #44CF6C;
`

export const Label = styled.label`
  text-align: center;
  color: #000000;
  margin: 0px;
`
