import styled from 'styled-components'

export const CheckToggle = styled.button.attrs ({
  type: 'button'
})`
  width: 16px;
  height: 16px;
  border: ${({ color }) => `2px solid ${color}`};
  background-color: transparent;
  text-decoration: none;
  border-radius: 20px;
  margin-right: 5px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
`

export const ToggleMark = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${({ color }) => color};
  border-radius: 15px;
`
