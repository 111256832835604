import styled from 'styled-components'
import { NumericFormat } from 'react-number-format'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Form = styled.div`
  width: 100%;
  margin: 4px 0px 10px;
  box-shadow: 0px 2px 10px -6px #252525;
  padding: 7px 10px;
  text-decoration: none;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
`

export const Row = styled(Container)`
  width: 100%;
  padding: 0px;
  gap: 10px;
  flex-direction: row;
`

export const FieldGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 3fr 140px 100px 100px 30px;
  column-gap: 10px;
  align-items: flex-end;
  margin-bottom: 3px;
`

export const FormField = styled(Container)`
  justify-content: flex-end;
  padding: 0px;
`

export const Button = styled.button`
  height: 38px;
  background-color: #606060;
  color: #FFFFFF;
  padding: 8px 20px;
  border: 0px;
  border-radius: 15px;
  text-align: center;
`

export const CloseButton = styled.button`
  width: 30px;
  height: 38px;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`

export const Input = styled.input`
  width: 100%;
  height: 38px;
  border: 0px;
  box-shadow: 0px 2px 10px -8px #252525;
  border-radius: 15px;
  text-align: center;

  &::placeholder {
    color: #BBBBBB
  }

  &:disabled {
    background-color: #FFFFFF;
  }
  
  &:focus {
    border: 1px solid #CCCCCC !important;
    outline: none;
  }
`

export const MoneyTextMask = styled(NumericFormat).attrs (props => {
  return {
    value: props.value.toFixed (2).replace ('.', ','),
    thousandSeparator: '.',
    decimalSeparator: ',',
    displayType: 'text',
    prefix: 'R$ '
  }
})`
  color: ${({value}) => parseFloat (value.replace (',', '.')) >= 0 ? '#44CF6C' : '#FF3939'};
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0px;
  padding: 0px;
`

export const InfoText = styled.p`
  font-size: 14px;
  text-align: center;
  color: #FFFFFF;
  margin: 0px;
`

export const Label = styled.label`
  text-align: center;
  color: #000000;
  margin-bottom: 4px;

  &:hover {
    cursor: pointer;
  }
`

export const WarningText = styled(InfoText)`
  color: #FF3939;
  margin-bottom: 4px;
`

export const ErrorContainer = styled.div`
  padding: 9px;
  margin: 15px 0px;
  width: 100%;
  border-radius: 15px;
  align-items: center;
  background-color: #FF3939;
`
