import styled from 'styled-components'

export const Input = styled.input`
  width: 100%;
  height: 38px;
  border: 0px;
  box-shadow: 0px 2px 10px -8px #252525;
  border-radius: 15px;
  text-align: center;
  padding-right: ${({type}) => type === 'datetime-local' ? '9px' : '0px'};

  &::placeholder {
    color: #BBBBBB
  }

  &:disabled {
    background-color: #FFFFFF;
  }
  
  &:focus {
    border: 1px solid #CCCCCC !important;
    outline: none;
  }
`
