import React, { useState } from 'react'
import Calendar from 'react-calendar'
import Modal from 'react-modal'
import { DateTime } from 'luxon'
import * as IO from 'react-icons/io5'

import {
  modalStyle,
  Container,
  CalendarContainer,
  InfoText,
  Button,
} from './styles'

export default function DateRangeInput ({
  value: dateRange,
  onChange,
  style = {}
}) {
  const [showCalendar, setShowCalendar] = useState (false)
  const [startDate, setStartDate] = useState (
    DateTime.fromJSDate (dateRange[0]).toFormat ('dd/MM/yyyy')
  )
  const [endDate, setEndDate] = useState (
    DateTime.fromJSDate (dateRange[1]).toFormat ('dd/MM/yyyy')
  )

  const handleChange = range => {
    setStartDate (DateTime.fromJSDate (range[0]).toFormat ('dd/MM/yyyy'))
    setEndDate (DateTime.fromJSDate (range[1]).toFormat ('dd/MM/yyyy'))
    toggleCalendar ()
    onChange (range)
  }

  const toggleCalendar = () => setShowCalendar (!showCalendar)

  return ( 
    <Container style={style}>
      <Button type='button' onClick={toggleCalendar}>
        <IO.IoCalendar size={16}/>
        <InfoText>{startDate} - {endDate}</InfoText>
      </Button>
      <Modal
        isOpen={showCalendar}
        onRequestClose={toggleCalendar}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        style={modalStyle}
      >
        <CalendarContainer>
          <Calendar 
            value={dateRange}
            selectRange={true}
            calendarType = 'US'
            returnValue='range'
            onChange={handleChange}
          />
        </CalendarContainer>
      </Modal>
    </Container>
  )
}
