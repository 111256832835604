import React, { useState, useEffect } from 'react'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import ClearableInput from '../../../components/Inputs/ClearableInput'
import BundleList from '../../../components/Lists/BundleList'
import AddBundleModal from '../../Modals/AddBundleModal'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import { DateTime } from 'luxon'

import {
  Container,
  Row,
  Button
} from '../styles'

function EditBundles () {
  const [loading, setLoading] = useState (true)
  const [bundles, setBundles] = useState ([])
  const [name, setName] = useState ('')
  const [showAddBundleModal, setShowAddBundleModal] = useState (false)
  const token = AuthService.getToken ()
  const businessId = AuthService.getBusinessId ()

  useEffect (() => {
    getData ()
  }, [name])

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const getData = async () => {
    let filters = ''
    if (name) filters += `&name=${name}`
    
    try {
      setLoading (true)

      const { data: bundles } = await cutileiApi.get (
        `/businesses/${businessId}/bundles?status=active${filters}`, requestConfig
      )
      setBundles (bundles)
    } catch (error) {
      console.log (error.response)
    } finally {
      setLoading (false)
    }
  }

  const compareBundles = (a, b) => (
    DateTime.fromISO (a.created_at).toMillis () - DateTime.fromISO (b.created_at).toMillis ()
  )

  const toggleAddBundleModal = () => setShowAddBundleModal (!showAddBundleModal)

  return (
    <Container>
      <Row style={{gap: 10, width: 600}}>
        <ClearableInput
          placeholder='Pesquisar por nome'
          value={name}
          onChange={e => setName (e.target.value)}
          onClear={() => setName ('')}
          inputStyle={{boxShadow: '0px 1px 10px -6px', paddingInline: 12}}
          containerStyle={{width: 360}}
        />
      </Row>
      <Button style={{marginBottom: 20}} onClick={toggleAddBundleModal}>
        Novo pacote
      </Button>
      {loading ? <Loading/> : (
        <>
          <BundleList
            bundles={bundles}
            onEdit={bundle => setBundles (bundles.map (b => b.id === bundle.id ? {...bundle} : b))}
            onDelete={deletedService => setBundles (bundles.filter (s => s.id !== deletedService.id))}
          />
          <AddBundleModal
            visible={showAddBundleModal}
            onConfirm={addedBundle => setBundles (
              [...bundles, addedBundle].sort (compareBundles)
            )}
            onClose={toggleAddBundleModal}
          />
        </>
      )}
    </Container>
  )
}

export default EditBundles
