import * as Yup from 'yup'

const editAvaliabilityValidator = Yup.object ({
  working_days: Yup.array ().of (Yup.object ().shape ({
    selected: Yup.boolean (),
    opening: Yup.object ().nullable ().when ('selected', {
      is: true,
      then: schema => schema.required ('Selecione o horário de abertura')
    }),
    closing: Yup.object ().nullable ().when ('selected', {
      is: true,
      then: schema => schema.required ('Selecione o horário de fechamento')
    }),
    accepts_cutilei: Yup.boolean (),
    cutilei_opening: Yup.object ().nullable ().when ('accepts_cutilei', {
      is: true,
      then: schema => schema.required ('Selecione o horário de início do atendimento Cutilei')
    }),
    cutilei_closing: Yup.object ().nullable ().when ('accepts_cutilei', {
      is: true,
      then: schema => schema.required ('Selecione o horário de término do atendimento Cutilei')
    })
  }))
})

export default editAvaliabilityValidator
