import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import Navbar from '../../components/Navbar'
import Earnings from './Earnings'
import Sales from './Sales'

import {
  Container,
  TabRow,
  TabButton,
  Title
} from './styles'

function Dashboard () {
  const OPTION_LIST = [
    {label: 'Rendimentos', value: 'earnings', component: <Earnings/>},
    {label: 'Vendas', value: 'sales', component: <Sales/>},
  ]

  const [option, setOption] = useState (OPTION_LIST[0])

  return (
    <Container>
      <Navbar/>
      <Title>Painel de estatísticas do salão</Title>
      <TabRow>
        {OPTION_LIST.map ((o, index) =>
          <TabButton
            key={index}
            selected={option.value === o.value}
            color={option.value === o.value ? '#FFC100' : '#FFFFFF'}
            onClick={() => setOption (o)}
          >
            {o.label}
          </TabButton>
        )}
      </TabRow>
      {option.component}
    </Container>
  )
}

export default withRouter (Dashboard)
