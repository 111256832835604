import React, { useState } from 'react'
import BankAccount from './BankAccount'
import EditBankAccountModal from '../../../pages/Modals/EditBankAccountModal'

import {
  Container,
  SubTitle,
  InfoText,
  LabelRow
} from './styles'

function BankAccountList ({
  bankAccounts,
  onEdit: handleEdit,
  onDelete: handleDelete
}) {
  const [bankAccountData, setBankAccountData] = useState (null)
  const [showEditBankAccountModal, setShowEditBankAccountModal] = useState (false)
  
  const editBankAccount = bankAccount => {
    setBankAccountData (bankAccount)
    toggleEditBankAccountModal ()
  }

  const toggleEditBankAccountModal = () => setShowEditBankAccountModal (!showEditBankAccountModal) 

  return (
    <Container>
      {bankAccounts.length > 0 ? (
        <>
          <LabelRow>
            <SubTitle>Banco</SubTitle>
            <SubTitle>Descrição</SubTitle>
            <SubTitle>Ações</SubTitle>
          </LabelRow>
          {bankAccounts.map ((bankAccount, index) =>
            <BankAccount
              key={index}
              data={bankAccount}
              onEdit={editBankAccount}
              onDelete={handleDelete}
            />
          )}
          <EditBankAccountModal
            visible={showEditBankAccountModal}
            data={bankAccountData}
            onClose={toggleEditBankAccountModal}
            onConfirm={bankAccount => handleEdit (bankAccount)}
          />
        </>
      ) : (
        <InfoText>Você ainda não possui contas bancárias cadastradas</InfoText>
      )}
    </Container>
  )
}

export default BankAccountList
