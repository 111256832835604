import React from 'react'
import AuthService from '../../services/auth'
import Logo from '../../icons/logo-cutilei-simple.png'
import AppStoreLogo from '../../icons/available-app-store.png'
import GooglePlayLogo from '../../icons/available-google-play.png'

import {
  Container,
  DivColumn,
  DivRow,
  Image,
  LogoImg,
  MoneyText,
  Label,
  SmallTitle,
  Header,
  Footer,
  LinkShortcut,
  ImgContainer,
  Slogan,
  SecondSlogan,
  DivPlans,
  BlackContainer,
  WhiteContainer,
  LinkButton,
  CenterPadding,
  LabelWhite,
  SmallTitleWhite,
  LinkButtonWhite,
  H1,
} from './styles'

function Homepage () {
  const isSigned = AuthService.isSigned ()
  const isBusiness = AuthService.isBusiness ()
  
  return (
    <Container>
      <Header>
        <LinkShortcut href='#inicio' style={{display: 'flex', padding: 0}}>
          <LogoImg src={Logo} alt='Cutilei'/>
          <H1>Cutilei</H1>
        </LinkShortcut>
        <LinkShortcut href='#quemSomos'>
          Quem Somos
        </LinkShortcut>
        <LinkShortcut href='#planos'>
          Planos
        </LinkShortcut>
        <CenterPadding/>
        <LinkShortcut
          href='https://wa.me/message/SH5AWAQ6UP4IF1'
          target='_blank'
          style={{display: 'flex'}}
        >
          Precisa de Ajuda?
        </LinkShortcut>
        <LinkButton to={isSigned && isBusiness ? '/business' : '/login'}>
          Entrar
        </LinkButton>
      </Header>
      <ImgContainer id='inicio'>
        <Image src='IMG-3317.jpg' alt='Cutilei'/>
        <Slogan>
          Uma assinatura e unhas ilimitadas para você.
          <SecondSlogan>
            Acesso aos melhores salões a partir de R$49,90 mês.
          </SecondSlogan>
        </Slogan>
      </ImgContainer>
      <BlackContainer id='quemSomos'>
        <DivColumn>
          <SmallTitleWhite>Quem Somos</SmallTitleWhite>
          <LabelWhite>
            Olá, somos a Cutilei! Podemos parecer um simples clube de assinatura de unhas, mas nascemos para ir muito além. Somos o seu próximo lifestyle e vamos te provar isso a partir do momento em que decidir fazer parte desse seleto grupo de pessoas que pensam à frente do seu tempo.
          </LabelWhite>
          <LabelWhite>
            Vamos facilitar o seu dia a dia, te conectando ao melhores salões e profissionais manicures. Experimente, saia da sua zona de conforto e deixe a Cutilei te mostrar o futuro.
          </LabelWhite>
          <LinkButtonWhite to='/signup'>
            Cadastrar Salão
          </LinkButtonWhite>
        </DivColumn>
      </BlackContainer>
      <WhiteContainer id='planos'>
        <DivColumn>
          <SmallTitle>Planos</SmallTitle>
          <DivRow>
            <DivPlans>
              <Label style={{fontWeight: 'bold', marginBottom: 10, color: '#FFC100'}}>
                Experiência completa
              </Label>
              <Label style={{fontSize: 14, textAlign: 'left'}}>
                • Mão (cutilagem e esmaltação) <br/>
                • Pé (cutilagem e esmaltação) <br/>
                • Pé e mão (cutilagem e esmaltação) <br/>
                • Esmaltação de mão <br/>
              </Label>
              <MoneyText>
                R$ 89,90 <Label style={{fontSize: 16}}>/ mês</Label>
              </MoneyText>
            </DivPlans>
            <DivPlans>
              <Label style={{fontWeight: 'bold', marginBottom: 10, color: '#FFC100'}}>
                Experiência básica
              </Label>
              <Label style={{fontSize: 14, textAlign: 'left'}}>
                • Mão (cutilagem e esmaltação) <br/>
                • Esmaltação de mão <br/>
              </Label>
              <MoneyText>
                R$ 49,90 <Label style={{fontSize: 16}}>/ mês</Label>
              </MoneyText>
            </DivPlans>
          </DivRow>
        </DivColumn>
      </WhiteContainer>
      <Footer>
        <DivRow style={{padding: 0}}>
          <LinkShortcut
            href='https://apps.apple.com/az/app/cutilei/id1634300864'
            target='_blank'
            style={{padding: 0, display: 'flex'}}
          >
            <LogoImg src={AppStoreLogo} alt='Cutilei' height={50}/>
          </LinkShortcut>

          <LinkShortcut
            href='https://play.google.com/store/apps/details?id=com.cutilei.cutileimembro'
            target='_blank'
            style={{padding: 0, display: 'flex'}}
          >
            <LogoImg src={GooglePlayLogo} alt='Cutilei' height={50}/>
          </LinkShortcut>
        </DivRow>
        <p style={{marginTop: 10, marginBottom: 10}}>©Cutilei 2023</p>
      </Footer>
    </Container>
  )
}

export default Homepage