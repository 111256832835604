import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import cutileiApi from '../../../services/cutileiApi'
import Comission from '../../../services/Comission'
import General from '../../../services/General'
import TimeWindow from '../../../services/timeWindow'
import AuthService from '../../../services/auth'
import AdminNavbar from '../../../components/AdminNavbar'
import Select from '../../../components/Inputs/Select'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import { Chart } from 'react-google-charts'
import * as FA from 'react-icons/fa'
import { DateTime } from 'luxon'

import { 
  Container,
  InfoContainer,
  LinkContainer,
  FilterContainer,
  Row,
  SubTitle,
  InfoText,
  MoneyTextMask
} from './styles'

function Usage () {
  const [loading, setLoading] = useState (true)
  const [schedulesByMonth, setSchedulesByMonth] = useState ([])
  const [schedulesByService, setSchedulesByService] = useState ([])
  const [schedulesByDay, setSchedulesByDay] = useState ([])
  const [month, setMonth] = useState (JSON.parse (localStorage.getItem ('month_usage')) || {
    label: DateTime.now ().setLocale ('pt-BR').toFormat ('MMMM'),
    value: DateTime.now ().month
  })
  const [year, setYear] = useState (JSON.parse (localStorage.getItem ('year_usage')) || {
    label: DateTime.now ().year,
    value: DateTime.now ().year
  })
  const token = AuthService.getToken ()
  
  const chartOptions = {
    is3D: false,
    colors: ['#FF3939', '#44CF6C', '#35A7FF', '#FFC100'],
    pieSliceTextStyle: {
      bold: true
    },
    chartArea: {
      top: 20,
      bottom: 20
    }, 
    legend: {
      alignment: 'center'
    },
    tooltip: {
      trigger: 'selection',
      textStyle: {
        fontSize: 16
      }
    }
  }

  useEffect (() => {
    getSchedulesByMonth ()
  }, [month, year])

  const getSchedulesByMonth = async () => {
    const filters = `?order=desc&mode=agenda&status=validated&month=${month.value}`
                  + `&year=${year.value}&cutilei_schedules=true&page=1&per_page=999`

    try {
      setLoading (true)

      const { data: scheduleResponse } = await cutileiApi.get (`/schedules${filters}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })

      const schedules = scheduleResponse.data.map (schedule => ({...schedule, type: 'cutilei'}))
      setSchedulesByMonth (schedules)
      setSchedulesByDay (General.groupBy (schedules, 'date'))
      setSchedulesByService ([
        ['Serviço', 'Quantidade'],
        ['Mão', schedules.filter (schedule => schedule.service.name === 'Mão').length],
        ['Pé', schedules.filter (schedule => schedule.service.name === 'Pé').length],
        ['Pé e mão', schedules.filter (schedule => schedule.service.name === 'Pé e mão').length],
        ['Esmaltação - Mão', schedules.filter (schedule => schedule.service.name === 'Esmaltação - Mão').length]
      ])
    } catch (error) {
      console.log (error.response.data)
    } finally {
      setLoading (false)
    }
  }

  return (
    <Container>
      <AdminNavbar/>
      <FilterContainer>
        <Row>
          <Select
            name='month'
            placeholder='Selecionar...'
            value={month}
            options={TimeWindow.getMonths ()}
            onChange={(_, option) => {
              setMonth (option)
              localStorage.setItem ('month_usage', JSON.stringify (option))
            }}
            containerStyles={{boxShadow: '0px 1px 10px -6px', borderRadius: 15}}
          />
          <Select
            name='year'
            placeholder='Selecionar...'
            value={year}
            options={TimeWindow.getYears ('2021', `${DateTime.now ().year}`)}
            onChange={(_, option) => {
              setYear (option)
              localStorage.setItem ('year_usage', JSON.stringify (option))
            }}
            containerStyles={{boxShadow: '0px 1px 10px -6px', borderRadius: 15}}
          />
        </Row>
      </FilterContainer>
      {loading ? <Loading style={{marginTop: 10}}/> : (
        <>
          <InfoContainer style={{height: 'auto', alignItems: 'center'}}>
            <SubTitle>Total do mês</SubTitle>
            {schedulesByMonth.length > 0 ? (
              <Chart
                chartType='PieChart'
                width='100%'
                height='250px'
                data={schedulesByService}
                options={chartOptions}
              />
            ) : (
              <InfoText>Ainda não houveram agendamentos neste mês</InfoText>
            )}
          </InfoContainer>
          {schedulesByDay.map ((schedules, index) => {
            const day = DateTime.fromISO (schedules[0].date).day
            
            return (
              <LinkContainer
                key={index}
                style={{justifyContent: 'center', height: 40}}
                to={{
                  pathname: '/admin/usage/daily',
                  search: `?day=${day}`,
                  state: {
                    day: day,
                    daySchedules: schedules
                  }
                }}
              >
                <Row>
                  <SubTitle>{`Dia ${day}`}</SubTitle>
                  <MoneyTextMask value={Comission.getTotalEarnings (schedules, {value: 'cutilei'})}/>
                  <FA.FaChevronRight size={15} color='#252525'/>
                </Row>
              </LinkContainer>
            )
          })}
        </>
      )}
    </Container>
  )
}

export default withRouter (Usage)
