import React, { useState, useEffect } from 'react'
import * as FA from 'react-icons/fa'

import {
  Container,
  Input,
  ArrowContainer,
  ArrowButton
} from './styles'

function NumberInput ({
  name,
  value,
  minValue = 0,
  maxValue = 1000,
  step = 1,
  placeholder = '',
  onChange,
  onBlur = () => null,
  disabled = false,
  style = {},
  inputStyle = {},
  ...props
}) {
  const [inputValue, setInputValue] = useState (value?.toString () || '')

  useEffect (() => {
    if (value !== undefined && value !== null) {
      setInputValue (value.toString ())
    }
  }, [value])

  const addStep = () => {
    if (parseInt (inputValue) === maxValue) return
    onChange (name, value + step)
  }

  const subtractStep = () => {
    if (parseInt (inputValue) === minValue) return
    onChange (name, value - step)
  }

  const handleChange = e => {
    const text = e.target.value
    const numericValue = parseInt (text.replace (/\D/g, ''), 10)

    if (!isNaN (numericValue)) {
      if (numericValue <= maxValue) {
        setInputValue (numericValue.toString ())
        onChange (name, numericValue)
      }
    } else if (text === '') {
      setInputValue ('')
    }
  }

  const handleBlur = () => {
    if (inputValue === '' || inputValue < minValue) {
      setInputValue (minValue.toString ())
      onChange (minValue)
    }
    onBlur (name)
  }

  return ( 
    <Container style={style}>
      <Input
        placeholder={placeholder}
        value={inputValue}
        onChange={handleChange}
        onBlur={handleBlur}
        style={inputStyle}
        {...props}
      />
      {!disabled && (
        <ArrowContainer>
          <ArrowButton onClick={addStep}>
            <FA.FaChevronUp size={12}/>
          </ArrowButton>
          <ArrowButton onClick={subtractStep}>
            <FA.FaChevronDown size={12}/>
          </ArrowButton>
        </ArrowContainer>
      )}
    </Container>
  )
}

export default NumberInput
