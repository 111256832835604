import React from 'react'
import BillProduct from './BillProduct'

import {
  Container,
  SubTitle,
  LabelRow
} from './styles'

function BillProductList ({
  bill,
  billProducts,
  professionals,
  enableEditting = true,
  onEdit: handleEdit,
  onDelete: handleDelete
}) {

  return billProducts.length > 0 && (
    <Container>
      <LabelRow enableEditting={enableEditting}>
        <SubTitle>Nome</SubTitle>
        <SubTitle>Profissional</SubTitle>
        <SubTitle>Qtd</SubTitle>
        <SubTitle>Preço</SubTitle>
        <SubTitle>Comissão</SubTitle>
        {enableEditting && <SubTitle>Ações</SubTitle>}
      </LabelRow>
      {billProducts.map ((product, index) =>
        <BillProduct
          key={index}
          bill={bill}
          data={product}
          professionals={professionals}
          enableEditting={enableEditting}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      )}
    </Container>
  )
}

export default BillProductList
