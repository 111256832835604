import React, { useState } from 'react'
import Modal from 'react-modal'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import MoneyInput from '../../../components/Inputs/MoneyInput'
import CheckBox from '../../../components/CheckBox'
import AlertDialog from '../../../components/AlertDialog'
import businessServiceValidator from '../../../validators/businessServiceValidator'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import { Formik } from 'formik'
import * as FA from 'react-icons/fa'

import {
  modalStyle,
  Form,
  FormField,
  Button,
  DangerButton,
  CloseButton,
  Row,
  Title,
  InfoText,
  Label,
  ErrorContainer
} from './styles'

function ServicePromotionModal ({
  visible,
  weekdays = [],
  data: service,
  onConfirm: handleConfirm,
  onClose: handleClose
}) {
  const promotion = service.promotions?.[0]
  const [errorMessage, setErrorMessage] = useState (null)
  const [showAlertDialog, setShowAlertDialog] = useState (false)
  const [deleting, setDeleting] = useState (false)
  const businessId = AuthService.getBusinessId ()
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const deletePromotion = async () => {
    try {
      setDeleting (true)
      
      await cutileiApi.delete (
        `/businesses/${businessId}/promotions/${promotion.id}`, requestConfig
      )

      setDeleting (false)
      handleConfirm ({...service, promotions: []})
      handleClose ()
    } catch (error) {
      setDeleting (false)
      console.log (error)
    }
  }

  const toggleAlertDialog = () => setShowAlertDialog (!showAlertDialog)

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      <Formik
        validationSchema={businessServiceValidator}
        initialValues={{
          'promotion_weekdays': weekdays.map (day => {
            const promotionDay = promotion?.weekdays.find (weekday => weekday.id === day.id)
            if (promotionDay) return {...promotionDay, selected: true}
            else return {...day, price: service.price, selected: false}
          })
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setErrorMessage (null)
          setSubmitting (true)

          try {
            const { data: promotionData } = await cutileiApi.post (`/businesses/${businessId}/promotions`, {
              serviceId: service.id,
              weekdays: values.promotion_weekdays.filter (day => day.selected)
            }, requestConfig)

            setSubmitting (false)
            handleConfirm ({...service, promotions: [promotionData]})
            handleClose ()
          } catch (error) {
            setSubmitting (false)
            console.log (error)
          }
        }}
      >
        {({
          values,
          isSubmitting,
          setFieldValue,
          handleBlur, 
          handleSubmit
        }) => (
          <Form onSubmit={handleSubmit}>
            <Title>{promotion ? 'Editar' : 'Adicionar'} promoção</Title>
            <CloseButton onClick={handleClose}>
              <FA.FaTimes color='#FF3939' size={18}/>
            </CloseButton>
            <InfoText style={{width: 580, alignSelf: 'center'}}>
              Selecione os dias da semana em que deseja configurar um preço promocional para este serviço. Dias não selecionados permanecerão com o preço padrão.
            </InfoText>
            <Row>
              {weekdays.map ((day, index) => (
                <FormField key={day.id}>
                  <Row style={{marginBottom: 3, alignItems: 'center'}}>
                    <CheckBox
                      id={`promotion_weekdays.${index}.selected`}
                      style={{marginRight: -3}}
                      value={values.promotion_weekdays[index].selected}
                      onValueChange={value => {
                        if (value === true)
                          setFieldValue (`promotion_weekdays.${index}.price`, service.price)
                        setFieldValue (`promotion_weekdays.${index}.selected`, value)
                      }}
                    />
                    <Label htmlFor={`promotion_weekdays.${index}.selected`}>
                      {day.name.substring (0, 3)}
                    </Label>
                  </Row>
                  {values.promotion_weekdays[index].selected && (
                    <MoneyInput
                      id={`promotion_weekdays.${index}.price`}
                      name={`promotion_weekdays.${index}.price`}
                      placeholder='Preço'
                      value={values.promotion_weekdays[index].price}
                      onValueChange={setFieldValue}
                      onBlur={handleBlur (`promotion_weekdays.${index}.price`)}
                    />
                  )}
                </FormField>
              ))}
            </Row>
            {errorMessage && (
              <ErrorContainer>
                <p>{errorMessage}</p>
              </ErrorContainer>
            )}
            <Row style={{margin: 0}}>
              <Button type='submit' disabled={isSubmitting}>
                {isSubmitting ? <ButtonLoading/> : promotion ? 'Salvar alterações' : 'Confirmar'}
              </Button>
              {promotion && (
                <>
                  <DangerButton type='button' onClick={toggleAlertDialog} disabled={deleting}>
                    {deleting ? <ButtonLoading/> : 'Excluir promoção'}
                  </DangerButton>
                  <AlertDialog
                    visible={showAlertDialog}
                    title='Atenção!'
                    message='Deseja realmente excluir esta promoção?'
                    confirmText='Sim'
                    cancelText='Não'
                    onConfirm={deletePromotion}
                    onClose={toggleAlertDialog}
                    dangerous
                  />
                </>
              )}
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default ServicePromotionModal
