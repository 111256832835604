import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import cutileiApi from '../../../services/cutileiApi'
import Phone from '../../../services/Phone'
import AdminNavbar from '../../../components/AdminNavbar'
import Address from '../../../services/Address'
import { FaStar } from 'react-icons/fa'
import { DateTime } from 'luxon'

import {
  Container,
  Info,
  Image,
  Row,
  LinkContainer,
  Title,
  SubTitle,
  InfoText,
  Block,
} from './styles'

function Business ({ history }) {
  const { business } = history.location.state
  const [loading, setLoading] = useState (true)
  const [workingDays, setWorkingDays] = useState ([])
  const [professionals, setProfessionals] = useState ([])

  useEffect (() => {
    getData ()
  }, [])

  const getData = async () => {
    try {
      const { data: workingDays } = await cutileiApi.get (`/businesses/${business.id}/working_days`)
      const { data: professionals } = await cutileiApi.get (`/businesses/${business.id}/professionals`)

      setWorkingDays (workingDays)
      setProfessionals (professionals)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  return loading ? null : (
    <Container>
      <AdminNavbar/>
      <Info>
        <Title>{business.name}</Title>
        <Image src={business.logo}/>
        <Row style={{alignItems: 'center', marginBottom: 5}}>
          <FaStar color='#FFC100' size={22}/>
          &nbsp;
          <SubTitle style={{fontSize: 20}}>{business.average_rating}</SubTitle>
        </Row>
        <Block>
          <Row>
            <InfoText>Razão social: &nbsp;</InfoText>
            <SubTitle>{business.corporate_name}</SubTitle>
          </Row>
          <Row>
            <InfoText>Responsável: &nbsp;</InfoText>
            <SubTitle>{business.owner}</SubTitle>
          </Row>
          <Row>
            <InfoText>CPNJ: &nbsp;</InfoText>
            <SubTitle>{business.cnpj}</SubTitle>
          </Row>
          <Row>
            <InfoText>Contato: &nbsp;</InfoText>
            <SubTitle>{Phone.formatPhone (business.cellphone)}</SubTitle>
          </Row>
        </Block>
        <Block>
          <Row>
            <InfoText>Endereço:</InfoText>
          </Row>
          <Row>
            <SubTitle>{Address.formatAddress (business.addresses[0])}</SubTitle>
          </Row>
        </Block>
        <Block>
          <Row>
            <InfoText>Dias disponíveis:</InfoText>
          </Row>
          {workingDays.length > 0 ? workingDays.map (day => 
            <Row key={day.id}>
              <InfoText>
                {day.name}: &nbsp;
              </InfoText>
              <SubTitle>
                {`${DateTime.fromISO (day.opening).toFormat ('H:mm')} - ${DateTime.fromISO (day.closing).toFormat ('H:mm')}`}
              </SubTitle>
            </Row>
          ) : (
            <InfoText>Este salão ainda não cadastrou os dias disponíveis</InfoText>
          )}
        </Block>
        <Block>
          <Row>
            <LinkContainer style={{marginRight: 10}}>
              <InfoText>Profissionais:&nbsp;&nbsp;</InfoText>
              <SubTitle>{professionals.length}</SubTitle>
            </LinkContainer>
            <LinkContainer>
              <InfoText>Avaliações:&nbsp;&nbsp;</InfoText>
              <SubTitle>{business.ratings_count}</SubTitle>
            </LinkContainer>
          </Row>
        </Block>
        <Block>
          <Row>
            <InfoText>Cadastro: &nbsp;</InfoText>
            <SubTitle>{DateTime.fromISO (business.created_at).toFormat ('dd/MM/yyyy - H:mm:ss')}</SubTitle>
          </Row>
          <Row>
            <InfoText>Última alteração: &nbsp;</InfoText>
            <SubTitle>{DateTime.fromISO (business.updated_at).toFormat ('dd/MM/yyyy - H:mm:ss')}</SubTitle>
          </Row>
        </Block>
      </Info>
    </Container>
  )
}

export default withRouter (Business)
