import * as Yup from 'yup'

const editProductValidator = Yup.object ({
  id: Yup.string (),
  nickname: Yup.string (),
  stock: Yup.number (),
  price: Yup.number ().positive ('Insira o preço'),
  comission_percentage: Yup.number (),
  professional_comission: Yup.number (),
  business_comission: Yup.number ()
})

export default editProductValidator
