import styled from 'styled-components'
import Cleave from 'cleave.js/react'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 20px;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 540px;
  box-shadow: 0px 2px 10px -6px #252525;
  padding: 10px;
  border-radius: 15px;
  margin: 20px;

  @media (max-width: 640px) {
    width: 100%;
  }
`

export const Image = styled.img`
  width: 120px;
  height: 120px;
  box-shadow: 0px 2px 10px -6px #252525;
  border-radius: 20px;
  margin-bottom: 20px;
`

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
`

export const FormField = styled(Container)`
  flex: ${props => props.stretch ? props.stretch : 1};
  justify-content: flex-end;
  padding: 0px; 
  margin-bottom: 20px;
`

export const NumberContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  background-color: #44CF6C;
  border-radius: 15px;
  margin: 10px;
  padding: 8px 10px;

  h3 {
    font-size: 24px;
    font-weight: bold;
    margin: 0px;
  }
`

export const Row = styled(Container)`
  width: 100%;
  padding: 0px;
  flex-direction: row;
  align-items: flex-start;
`

export const InfoText = styled.p`
  font-size: 16px;
  text-align: center;
  margin: 0px;
`

export const Paragraph = styled(InfoText)`
  text-align: justify;
  margin-bottom: 10px;
`

export const Title = styled(InfoText)`
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
`

export const SubTitle = styled(Title)`
  font-size: 16px;
  margin-bottom: 0px;
`

export const MoneyText = styled(SubTitle)`
  color: #44CF6C;
`

export const Label = styled.label`
  text-align: center;
  color: #000000;
  margin: 0px;
`

export const WarningText = styled.p`
  margin: 0px;
  text-align: center;
  font-size: 14px;
  font-weight: 200;
  color: #FF3939;
`

export const ErrorContainer = styled.div`
  padding: 8px;
  margin-bottom: 20px;
  width: 100%;
  border-radius: 15px;
  text-align: center;
  background-color: #FF3939;

  p {
    color: #FFFFFF;
    margin: 0px;
  }
`

export const Button = styled.button`
  width: 200px;
  align-self: center;
  background-color: #44CF6C;
  color: #FFFFFF;
  padding: 8px;
  border: 0px;
  border-radius: 15px;
  text-align: center;
  text-decoration: none;

  &:hover {
    background-color: #00A349;
    color: #FFFFFF;
  }
`

export const BackButton = styled(Button)`
  background-color: #252525;
  margin-top: 10px;

  &:hover {
    background-color: #121212;
    color: #FFFFFF;
  }
`

export const LinkButton = styled(Link)`
  color: #FFFFFF;
  background-color: #44CF6C;
  padding: 8px 20px;
  border-radius: 15px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #00A349;
    color: #FFFFFF;
  }
`

export const LinkShortcut = styled.a`
  color: #FFFFFF;
  background-color: #35A7FF;
  padding: 8px 20px;
  border-radius: 15px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #2875E2;
    color: #FFFFFF;
  }
`

export const LinkText = styled(Link)`
  color: #35A7FF;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Input = styled.input`
  width: 100%;
  height: 38px;
  margin: 0px 5px;
  border: 1px solid #CCCCCC;
  border-radius: 15px;
  text-align: center;
  cursor: ${props => props.disabled ? 'not-allowed' : 'text'};

  &::placeholder {
    color: #BBBBBB
  }

  &:disabled {
    background-color: #FFFFFF;
  }
  
  &:focus {
    border: 1px solid #121212 !important;
    outline: none;
  }
`

export const MaskedInput = styled(Cleave)`
  width: 100%;
  height: 38px;
  margin: 0px 5px;
  border: 1px solid #CCCCCC;
  border-radius: 15px;
  text-align: center;
  cursor: ${props => props.disabled ? 'not-allowed' : 'text'};

  &::placeholder {
    color: #BBBBBB
  }

  &:disabled {
    background-color: #FFFFFF;
  }
  
  &:focus {
    border: 1px solid #121212 !important;
    outline: none;
  }
`

export const Check = styled.input`
  border-radius: 15px;
  margin:auto;
  display: flex;
  justify-content: center;
`
