import { DateTime } from 'luxon'

export default class Dashboard {
  static TIME_FILTERS = [
    {label: '3 meses', value: 2},
    {label: '6 meses', value: 5},
    {label: '12 meses', value: 11},
    {label: 'Este ano', value: DateTime.now ().diff (DateTime.now ().startOf ('year'), 'months').months}
  ]

  static TYPE_FILTERS = [
    {label: 'Todos os rendimentos', value: 'all_earnings'},
    {label: 'Agendamentos Cutilei', value: 'cutilei'},
    {label: 'Comandas do salão', value: 'bill'}
  ]

  static groupEarningsByMonth (data) {
    return data.reduce ((acc, curr) => {
      const month = DateTime.fromISO (curr.date).setLocale ('pt-BR').toFormat ('MMM yyyy')
      if (!acc[month]) {
        acc[month] = []
      }
      acc[month].push (curr)
      return acc
    }, {})
  }

  static getEarningsByPaymentMethod (earnings, paymentMethod) {
    return earnings.filter (e => e.type === 'bill').reduce ((total, bill) => (
      total + bill.payments.reduce ((total, payment) => (
        total + (payment.payment_method.codename === paymentMethod.codename ? payment.value : 0)
      ), 0)
    ), 0)
  }

  static getServicesAverageTicket (earnings, type) {
    const cutileiEarnings = earnings.filter (earning => earning.type === 'cutilei')
    const billEarnings = earnings.filter (earning => earning.type === 'bill')
    const sumItemTotal = (total, item) => total + (
      item.bundle_id ? item.discount_price : (item.custom_price ?? item.price)
    )
    let totalEarnings = 0
    let numberOfServices = 0

    if (['cutilei', 'all_earnings'].includes (type.value)) {
      totalEarnings += cutileiEarnings.reduce ((total, earning) => total + earning.price, 0)
      numberOfServices += cutileiEarnings.length
    }

    if (['bill', 'all_earnings'].includes (type.value)) {
      const scheduleEarningsTotal = billEarnings.reduce ((total, bill) => (
        total + bill.schedules.reduce (sumItemTotal, 0)
      ), 0)

      const serviceEarningsTotal = billEarnings.reduce ((total, bill) => (
        total + bill.services.reduce (sumItemTotal, 0)
      ), 0)

      const totalSchedules = billEarnings.reduce ((total, bill) => total + bill.schedules.length, 0)
      const totalServices = billEarnings.reduce ((total, bill) => total + bill.services.length, 0)

      totalEarnings += scheduleEarningsTotal + serviceEarningsTotal
      numberOfServices += totalSchedules + totalServices
    }
    
    const servicesAverageTicket = totalEarnings / numberOfServices
    return servicesAverageTicket
  }

  static getCustomerAverageTicket (earnings, type) {
    const cutileiEarnings = earnings.filter (earning => earning.type === 'cutilei')
    const billEarnings = earnings.filter (earning => earning.type === 'bill')
    const sumItemTotal = (total, item) => total + (
      item.bundle_id ? item.discount_price : (item.custom_price ?? item.price)
    )
    let totalEarnings = 0
    let numberOfServices = 0

    if (['cutilei', 'all_earnings'].includes (type.value)) {
      totalEarnings += cutileiEarnings.reduce ((total, earning) => total + earning.price, 0)
      numberOfServices += cutileiEarnings.length
    }

    if (['bill', 'all_earnings'].includes (type.value)) {
      const scheduleEarningsTotal = billEarnings.reduce ((total, bill) => (
        total + bill.schedules.reduce (sumItemTotal, 0)
      ), 0)

      const serviceEarningsTotal = billEarnings.reduce ((total, bill) => (
        total + bill.services.reduce (sumItemTotal, 0)
      ), 0)

      const totalSchedules = billEarnings.reduce ((total, bill) => total + bill.schedules.length, 0)
      const totalServices = billEarnings.reduce ((total, bill) => total + bill.services.length, 0)

      totalEarnings += scheduleEarningsTotal + serviceEarningsTotal
      numberOfServices += totalSchedules + totalServices
    }
    
    const servicesAverageTicket = totalEarnings / numberOfServices
    return servicesAverageTicket
  }
}
