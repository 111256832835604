import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import cutileiApi from '../../../services/cutileiApi'
import AuthService from '../../../services/auth'
import ProductForm from '../../../components/Forms/ProductForm'
import ClearableInput from '../../../components/Inputs/ClearableInput'
import Select from '../../../components/Inputs/Select'
import { Formik } from 'formik'
import businessProductValidator from '../../../validators/businessProductValidator'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import * as FA from 'react-icons/fa'

import {
  modalStyle,
  Container,
  Header,
  Content,
  Row,
  Title,
  InfoText,
  Button,
  CloseButton
} from './styles'

function BusinessProductsModal ({
  visible,
  onConfirm: handleConfirm,
  onClose: handleClose
}) {
  const [loading, setLoading] = useState (true)
  const [products, setProducts] = useState ([])
  const [categories, setCategories] = useState ([])
  const [category, setCategory] = useState (null)
  const [name, setName] = useState ('')
  const [orderBy, setOrderBy] = useState ('')
  const token = AuthService.getToken ()
  const businessId = AuthService.getBusinessId ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getData ()
  }, [category, name])

  const getData = async () => {
    try { 
      setLoading (true)

      const { data: categories } = await cutileiApi.get ('/product_categories', requestConfig)

      setCategories (categories.map (category => ({
        label: category.name,
        value: category.id
      })))

      await getProducts ()
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  const getProducts = async () => {
    let filters = `business_id=${businessId}`
    if (name) filters += `&name=${name}`
    if (category) filters += `&category_id=${category.value}`
    if (orderBy) filters += `&order_by=${orderBy}`

    try {
      setLoading (true)

      const { data: products } = await cutileiApi.get (
      `/products?${filters}`, requestConfig
      )

      setProducts (products)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      <Header>
        <Title>
          Adicionar produtos
        </Title>
        <CloseButton onClick={handleClose}>
          <FA.FaTimes color='#FF3939' size={18}/>
        </CloseButton>
      </Header>
      <Row style={{marginBottom: 15, marginInline: 20}}>
        <Select
          name='category'
          placeholder='Categoria...'
          value={category}
          options={categories}
          isClearable={true}
          onChange={(_, option) => setCategory (option)}
          containerStyles={{boxShadow: '0px 1px 10px -7px', borderRadius: 15, height: 38}}
        />
        <ClearableInput
          placeholder='Pesquisar por nome'
          value={name}
          onChange={e => setName (e.target.value)}
          onClear={() => setName ('')}
          inputStyle={{boxShadow: '0px 1px 10px -6px', paddingInline: 12}}
        />
      </Row>
      {loading ? (
        <Loading/>
      ) : (
        <Formik
          validationSchema={businessProductValidator}
          initialValues={{
            'products': products.map (product => ({
              'id': product.id,
              'selected': false,
              'name': product.name,
              'nickname': '',
              'stock': 1,
              'price': 0,
              'comission_percentage': 20
            }))
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const products = values.products.filter (product => product.selected).map (product => (
              {...product, comission_percentage: product.comission_percentage / 100}
            ))

            try {
              await cutileiApi.post (`/businesses/${businessId}/products`, {products}, requestConfig)
              
              setSubmitting (false)
              handleConfirm (products)
              handleClose ()
            } catch (error) {
              setSubmitting (false)
              console.log (error)
            }
          }}
        >
          {(formikProps) => (
            <Container>
              <Content>
                {products.length === 0 ? (
                  <InfoText>
                    Nenhum resultado encontrado
                  </InfoText>
                ) : products.map ((product, index) => 
                  <ProductForm 
                    key={product.id}
                    index={index}
                    formikProps={formikProps}
                    data={product}
                  />
                )}
              </Content>
              <Row>
                <Button type='submit' onClick={formikProps.handleSubmit}>
                  {formikProps.isSubmitting ? <ButtonLoading/> : 'Confirmar'}
                </Button>
              </Row>
            </Container>
          )}
        </Formik>
      )}
    </Modal>
  )
}

export default BusinessProductsModal
